<template>
  <div>
    <Buttonn
      view="link"
      :class="
        $style[edoDocument ? getColorStatusButton(edoDocument?.status) : 'blue']
      "
      :iconLeft="IconDoc"
      @click="handleDrawerOpen"
    >
      <!-- {{ t('act') }} -->
      {{ t('edoTitle') }}
    </Buttonn>
    <Drawer
      v-model="isDrawerOpen"
      :title="t('edoTitle')"
      :closeText="$t('filter.drawer.close')"
    >
      <div v-if="showSelect">
        <Select
          v-if="orgList"
          v-model="orgListSelect"
          :options="orgList"
          :label="t('organization')"
          :canDeselect="false"
          :class="$style.select"
        />
        <Select
          v-if="departmentsList"
          v-model="depListSelect"
          :options="departmentsList"
          :label="t('department')"
          :canDeselect="false"
        />
      </div>
      <div :class="$style.textInner">
        <Texxt
          >{{ t('documentStatus.numberDocument') }}: {{ props.edoId }}</Texxt
        >
        <Texxt>{{ t('edoTitle') }}: {{ profileEdo ? profileEdo : '-' }}</Texxt>
        <Texxt
          >{{ t('column.documentStatus') }}:
          {{ edoDocument ? getDocstatus(edoDocument?.status) : '-' }}</Texxt
        >
      </div>
      <template #buttons>
        <!-- <slot name="extra-button" :formValues="filtersFormValues" /> -->

        <!-- {{ edoData }} -->
        <Buttonn v-if="!edoDocument" type="submit" @click="handleSubmit">
          <!-- {{ $t('filter.drawer.submit') }} -->
          {{ t('send') }}
        </Buttonn>
      </template>
    </Drawer>
  </div>
</template>

<script lang="ts" setup>
import {
  Button as Buttonn,
  Drawer,
  IconDoc,
  Select,
  Text as Texxt,
  toaster,
} from '@smst/ui'
import type { SelectTypes } from '@smst/ui'
import { computed, ref } from 'vue'
import type { ComputedRef, PropType } from 'vue'
import { useMutation } from 'vue-query'

import { apiClient } from '@/api'
import type {
  DepartmentsList,
  EdoDocument,
  Organization,
} from '@/api/__generated__/api.schema'
// import { number } from 'yup'
import { useComponentI18n } from '@/hooks/useComponentI18n'
// import { useId } from '@/hooks/useId'
import { useProfile } from '@/hooks/useProfile'
import { getErrorMessage } from '@/utils/errors'

import { statusDoc } from './edoDocumentStatus'

const props = defineProps({
  edoId: Number,
  edoDocument: Object as PropType<EdoDocument | null>,
})
// const emit = defineEmits(['update:modelValue'])
const { profile } = useProfile()

const profileEdo = profile?.value?.edo

// const filtersFormValues = ref(props.modelValue)
const isDrawerOpen = ref<boolean>(false)
const t = useComponentI18n('accounting')

// eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
const showSelect = profileEdo === 'diadoc' && !props.edoDocument

const edoData = ref()
const departmentsList = ref()
const depListSelect = ref()
const orgListSelect = ref()

const handleDrawerOpen = () => {
  isDrawerOpen.value = true
  if (showSelect && props.edoId) {
    getEdoData(props.edoId)
  }
}

const getDocstatus = (status: number | string) => {
  if (status === '') return t(`documentStatus.docStatus.null`)
  else return t(`documentStatus.docStatus.${status}`)
}

const getColorStatusButton = (status: number | string): string => {
  const doc = statusDoc.find((item) => item.statusId === status)
  return doc ? doc.color : 'blue'
}

const setDepartmentsList = () => {
  const department: DepartmentsList[] = edoData?.value?.departmentsList
  const filtered = department.filter((item) => item.id === orgListSelect.value)

  depListSelect.value = filtered?.at(0)?.list?.at(0)?.id ?? ''
  const list = filtered?.at(0)?.list

  if (list && list.length > 0) {
    departmentsList.value = list.map((item) => {
      return {
        value: item.id,
        label: item.name,
      }
    })
  }
}

// watchEffect(() => {
//   if (orgListSelect.value) setDepartmentsList()
// })

const orgList: ComputedRef<SelectTypes.Option[] | null> = computed(() => {
  const department: Organization[] = edoData?.value?.organizationsList

  if (department?.length > 0) {
    return department.map((item) => {
      return {
        value: item.id,
        label: item.name,
      }
    })
  } else {
    return null
  }
})

const { mutate: getEdoData } = useMutation(
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  apiClient.accounting.documentEdo_GET_BY,
  {
    onSuccess: (data) => {
      // console.log('data')
      edoData.value = data?.data?.edoInitInfo
      orgListSelect.value = edoData.value.defaultOrganization
      setDepartmentsList()
    },
  }
)
const { mutate: sendEdoData } = useMutation(
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  apiClient.accounting.documentEdo_POST,
  {
    onSuccess: () => {
      toaster.success(t('successSendData'))
      isDrawerOpen.value = false
    },
    onError: (error) => {
      toaster.error(getErrorMessage(error))
    },
  }
)
// Сбрасываем форму в ящике при открытии

// const handleFilterClear = () => {
//   emit('update:modelValue', clearedValue)
// }

const handleSubmit = () => {
  // isDrawerOpen.value = false
  if (showSelect && props?.edoId) {
    sendEdoData(props?.edoId, {
      edoDepartment: depListSelect,
      edoOrganisation: orgListSelect,
    })
  } else if (props?.edoId) {
    sendEdoData(props?.edoId, { edoDepartment: '', edoOrganisation: '' })
  }
}
</script>

<style module>
.textInner {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
}

.select {
  margin-bottom: 8px;
}

.blue {
  color: var(--color-bg-button);
}

.red {
  color: var(--color-error);
}

.green {
  color: var(--color-success);
}
</style>
