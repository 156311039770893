<template>
  <Drawer
    :modelValue="isOpen"
    :closeText="t('close')"
    :title="t('title')"
    @update:modelValue="handleClose"
  >
    <Text tag="p">
      {{ t('subtitle') }}
    </Text>

    <div :class="$style.grid">
      <Text color="secondary"> {{ t('messageType') }}: </Text>
      <Text>{{ messageType }}</Text>

      <Text color="secondary"> {{ t('originator') }}: </Text>
      <Text>{{ format(data?.originator) }}</Text>

      <Text color="secondary"> {{ t('rus') }}: </Text>
      <Text>{{ russian }}</Text>

      <hr :class="$style.hr" />

      <template v-if="messages">
        <template v-for="message in messages" :key="message.channel">
          <Text color="secondary">{{ t('channel') }}</Text>
          <Text>{{ tChannels(message.channel) }}</Text>

          <template v-if="message.channel === 'sms'">
            <Text color="secondary"> {{ t('messageCount') }}: </Text>
            <Text>{{ format(messageCount) }}</Text>
          </template>

          <Text color="secondary"> {{ t('message') }}: </Text>
          <Text>{{ format(message.message) }}</Text>

          <Text v-if="message.channel === 'viber'" color="secondary">
            {{ t('buttonText') }}:
          </Text>
          <Text v-if="message.channel === 'viber'">{{
            message?.buttonName
          }}</Text>

          <Text v-if="message.channel === 'viber'" color="secondary">
            {{ t('buttonUrl') }}:
          </Text>
          <Text v-if="message.channel === 'viber'">{{
            message?.buttonUrl
          }}</Text>
          <Text v-if="message.channel === 'viber'" color="secondary"
            >{{ t('buttonImage') }}:</Text
          >
          <div>
            <img
              v-if="message?.fileUrl"
              :src="message.fileUrl"
              :alt="t('channels.image')"
              :class="$style.image"
            />
          </div>

          <hr :class="$style.hr" />
        </template>
      </template>

      <Text color="secondary"> {{ t('batchName') }}: </Text>
      <Text>{{ format(data?.batchName) }}</Text>

      <Text color="secondary"> {{ t('blackList') }}: </Text>
      <Text>{{ format(blackList) }}</Text>

      <Text color="secondary"> {{ t('phonesCount') }}: </Text>
      <Text>{{ format(phonesCount) }}</Text>

      <hr :class="$style.hr" />

      <Text color="secondary"> {{ t('startDate') }}: </Text>
      <Text data-testid="date_confirmation__text">{{
        formated(displayDate).date ?? '-'
      }}</Text>

      <Text color="secondary"> {{ t('startTime') }}: </Text>
      <Text data-testid="time_confirmation__text">{{
        formated(displayDate)?.time ?? '-'
      }}</Text>

      <Text color="secondary"> {{ t('speed') }}: </Text>
      <Text>{{ speed }}</Text>
    </div>

    <template #buttons>
      <Button @click="emit('submit', data)">{{ t('submit') }}</Button>

      <Button view="link" @click="emit('close')">
        {{ t('cancel') }}
      </Button>
    </template>
  </Drawer>
</template>

<script lang="ts" setup>
import { Button, Drawer, Text } from '@smst/ui'
import type { ComputedRef, PropType } from 'vue'
import { computed } from 'vue'
import { useQuery } from 'vue-query'
import { useRoute } from 'vue-router'

import { apiClient } from '@/api'
import type { Group, PhonesTypeTextarea } from '@/api/__generated__/api.schema'
import { useComponentI18n } from '@/hooks/useComponentI18n'
import { useProfile } from '@/hooks/useProfile'
import { getSpeedOptions } from '@/pages/Batches/components/BatchEdit/components/EditForm.utils'

import type { SendFormCommonType, ShowData } from '../../BatchCreate.utils'

import { getMessages, getSmsMessageCount } from './ConfirmationDrawer.utils'

type DisplayAndRequestData = SendFormCommonType | ShowData

type DisplayData = {
  time: string
  date: string
}
const props = defineProps({
  isOpen: {
    type: Boolean,
    required: true,
  },
  groups: {
    type: Array as PropType<Group[]>,
    required: true,
  },
  data: {
    type: Object as PropType<DisplayAndRequestData>,
    required: true,
  },
  displayDate: Object as PropType<DisplayData>,
})

const emit = defineEmits(['close', 'submit'])

const { profile } = useProfile()
const route = useRoute()

const t = useComponentI18n('batchCreate.confirmationDrawer')
const tMessageType = useComponentI18n('batches.messageType')
const tChannels = useComponentI18n('channels')

const format = (value?: string | number) => (value ? value : '-')

const messageType = computed(() =>
  profile.value?.smartDelivery
    ? tMessageType('smartDelivery')
    : tMessageType('sms')
)

const russian = computed(() =>
  props.data?.transliterate ? t('inRussian.no') : t('inRussian.yes')
)

const formated = (val: ComputedRef<DisplayData>) => {
  return val.value
}

const blackList = computed(
  () =>
    props.groups.find((item) => item.groupId === props.data?.blackListId)
      ?.groupName
)

const speedOptions = getSpeedOptions()
const speed = computed(() =>
  speedOptions.value
    ? speedOptions.value.find(({ value }) => value === props.data?.gap)?.label
    : ''
)

const messages = computed(() =>
  'channels' in props.data
    ? // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      getMessages(props.data?.channels)
    : [
        {
          channel: 'sms',
          message: props.data?.message,
          buttonName: '',
          buttonUrl: '',
        },
      ]
)

const groupId = computed(() => route.query.group && Number(route.query.group))

const { data: phonesData } = useQuery(['subscribersCount', props.data], () => {
  if (props.data?.phonesCount) return
  // Выбранные номера в группе
  if (groupId.value && 'selectedElements' in props.data) {
    return apiClient.send.subscribersCountMembers_POST(groupId.value, {
      selectedElements: props.data?.selectedElements,
    })
  }

  // Выбрана группа полностью
  if ('selectedElements' in props.data) {
    return apiClient.send.subscribersCountGroups_POST({
      selectedElements: props.data?.selectedElements,
    })
  }

  // Номера введены через поле
  return apiClient.send.subscribersCount_POST({
    phonesType: props.data?.phonesType as unknown as PhonesTypeTextarea,
    phonesInput: props.data?.phonesInput,
  })
})

const phonesCount = computed(() =>
  props.data?.phonesCount
    ? props.data?.phonesCount
    : phonesData.value?.data?.phones
)

const messageCount = computed(() =>
  getSmsMessageCount(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    phonesCount?.value,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    'channels' in props.data
      ? props.data?.channels.sms.message
      : props.data?.message,
    !props.data?.transliterate
  )
)

const handleClose = () => {
  emit('close')
}
</script>

<style module>
.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--gap-8) var(--gap-12);
  width: 100%;
  margin-top: var(--gap-24);
}

.hr {
  grid-column: 1 / span 2;
  width: 100%;
  height: 1px;
  margin: var(--gap-8) 0;

  background-color: var(--color-stroke-form);
  border: 0;
}

.image {
  max-width: 150px;
}
</style>
