<template>
  <FormControlBase
    v-slot="{ invalid }"
    :name="name"
    :class="(fallthroughClass, $style.focus)"
  >
    <Multiselect
      :modelValue="fieldValue"
      :options="options"
      :groups="groups"
      :label="label"
      :placeholder="placeholder || $t('form.multiselect.placeholder')"
      :locale="locale"
      :invalid="invalid"
      :disabled="disabled"
      v-bind="fallthroughAttrs"
      @update:modelValue="handleChangeField"
      @blur="handleBlur"
    />
  </FormControlBase>
</template>

<script lang="ts">
import { Multiselect } from '@smst/ui'
import type { MultiselectTypes } from '@smst/ui'
import { useField } from 'vee-validate'
import type { ExtractPropTypes, PropType } from 'vue'
import { computed, defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'

import FormControlBase, {
  formControlProps,
} from '@/components/FormControlBase/FormControlBase.vue'

import {
  hasSelectedOptions,
  lastChoiceIsAllOption,
  OPTION_ALL,
} from './FormMultiselect.utils'

const formMultiselectProps = {
  ...formControlProps,
  options: {
    type: [Array, Function] as PropType<
      | MultiselectTypes.Option[]
      | MultiselectTypes.Group[]
      | MultiselectTypes.GetOptions
    >,
    required: true,
  },
  groups: Boolean as PropType<boolean>,
  withOptionAll: Boolean as PropType<boolean>,
  placeholder: String,
} as const

export type FormMultiselectProps = ExtractPropTypes<typeof formMultiselectProps>

export default defineComponent({
  components: { FormControlBase, Multiselect },
  inheritAttrs: false,
  props: formMultiselectProps,
  setup(props, { attrs }) {
    const {
      /** Состояние компонента в форме */
      value: formValue,
      handleChange: setFormFieldValue,
      handleBlur,
    } = useField<MultiselectTypes.Value>(props.name)

    const { t } = useI18n()

    const locale = {
      noOptionsText: t('form.multiselect.noOptionsText'),
      noResultsText: t('form.multiselect.noResultsText'),
      acceptButtonText: t('form.multiselect.acceptButtonText'),
      removeTag: t('form.multiselect.removeTag'),
      multipleLabel: (count: number) => {
        if (count > 1) return t('form.multiselect.selectedText', { count })
        return t('form.defaultSelectOptionLabel')
      },
    }

    const options = computed(() => {
      /**
       * Если используется функция для получения опций, то возвращаем ее
       */
      if (typeof props.options === 'function') {
        return props.options
      }

      if (props.withOptionAll) {
        return [OPTION_ALL.value, ...props.options]
      }

      return props.options
    })

    /** Локальное состояние компонента */
    const fieldValue = computed<MultiselectTypes.Value>(() => {
      if (!props.withOptionAll) {
        return formValue.value
      }

      if (!formValue.value || formValue.value.length === 0) {
        return [OPTION_ALL.value.value]
      }

      return formValue.value
    })

    const { class: fallthroughClass, ...fallthroughAttrs } = attrs

    const handleChangeField = (targetValue: MultiselectTypes.Value) => {
      if (!props.withOptionAll) {
        setFormFieldValue(targetValue)
        return
      }

      if (
        hasSelectedOptions(targetValue) &&
        lastChoiceIsAllOption(targetValue)
      ) {
        setFormFieldValue(undefined)
        return
      }

      setFormFieldValue(
        targetValue?.filter((val) => val !== OPTION_ALL.value.value)
      )
    }

    return {
      locale,
      fallthroughClass,
      fallthroughAttrs,

      fieldValue,
      options,
      handleChangeField,
      handleBlur,
    }
  },
})
</script>

<style module>
.focus :global(.is-open.is-active .multiselect-multiple-label) {
  opacity: 0.3 !important;
}
</style>
