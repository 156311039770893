{
  "meta": {
    "title": {
      "default": "SMS Traffic",
      "Auth": "Вход",
      "Dashboard": "Личный кабинет",
      "BatchCreate": "Отправка сообщений",
      "BatchDetailsArchive": "Архив отложенных рассылок",
      "BatchEdit": "Редактирование рассылки",
      "BatchDetails": "Информация о рассылке",
      "Groups": "Списки для рассылок",
      "GroupCreate": "Создание списка",
      "Drafts": "Черновики",
      "DraftCreate": "Создание черновика",
      "Batches": "Отложенные рассылки",
      "OperatorByPhone": "Определить оператора",
      "TemplatesList": "Список шаблонов",
      "TemplatesCheck": "Проверка шаблонов",
      "TemplatesTest": "Тестирование шаблона",
      "SendMessages": "Отправка сообщений",
      "MessagesLog": "Журнал",
      "RequestedReports": "Запрошенные отчеты",
      "Statistics": "Статистика",
      "AccountingInfo": "Учетная карточка",
      "Accounting": "Счета, счета фактуры, акты",
      "TransactionsHistory": "История платежей",
      "SettingsGeneral": "Настройки",
      "SettingsSecurity": "Настройки безопасности",
      "SettingsSmtp": "SMTP вариант 1",
      "SettingsPassword": "Смена пароля",
      "BatchesArchive": "Архив отложенных рассылок",
      "Originators": "Список имен отправителя",
      "Accounts": "Учетные записи и департаменты",
      "Departments": "Департаменты",
      "EventLogs": "Журнал событий",
      "ForgotPassword": "Сбросить пароль",
      "Registration": "Регистрация",
      "PhoneConfirmation": "Подтверждение номера телефона",
      "PasswordConfirmation": "Подтверждение регистрации",
      "ToOldFront": "В старый ЛК",
      "LoginPrivateArea": "Вход для администратора"
    },
    "description": {
      "default": "Эффективные цифровые коммуникации"
    }
  },
  "error": {
    "general": "Возникли непредвиденные проблемы, повторите попытку позже",
    "network": "Возникли проблемы с сетью, проверьте наличие доступа в интернет и повторите попытку",
    "102": "В доступе отказано",
    "108": "Заполните все обязательные поля формы и повторите попытку",
    "109": "Неправильные логин и пароль",
    "110": "Неправильные логин и пароль",
    "111": "Пароль слишком не безопасный, введите более сложный пароль",
    "113": "Время жизни сессии закончилось, вам необходимо начать сначала",
    "114": "Секретный код неверен, попробуйте ввести еще раз",
    "115": "Превышено максимальное число попыток ввода кода, отправьте запрос на получаение кода еще раз",
    "116": "Срок действия кода истек. Попробуйте сгенерировать код заново",
    "117": "Превышено максимальное количество отправки кодов на ваш номер телефона. Обратитесь в службу поддержки",
    "118": "К сожалению, восстановление пароля недоступно. Обратитесь в службу поддержки",
    "119": "Не передал секретный код для восстановления пароля",
    "123": "Email уже зарегистрирован",
    "126": "Данный номер уже зарегистрирован в нашей системе. Обратитесь в службу поддержки ",
    "127": "Список уже существует",
    "128": "Невозможно скрыть список рассылки",
    "129": "Абонент уже существует",
    "130": "Отчет уже сформирован",
    "131": "Введенный пароль уже был использован ранее, пожалуйста, введите другой пароль",
    "133": "Неверный формат телефона",
    "135": "Старый пароль не совпадает",
    "138": "Пожалуйста заполните Капчу",
    "141": "На аккаунте не настроена двухфакторная аутентификация, просьба обратиться к вашему аккаунт менеджеру",
    "143": "Код был отправлен",
    "404": "Не существует, обратитесь в службу поддержки",
    "502": "Некорректный запрос",
    "600": "Файл не найден",
    "606": "Слишком много тестовых номеров",
    "610": "Наша система обнаружила, что с вашего аккаунта сформирован дубликат рассылки, текущий запрос отменен."
  },
  "validation": {
    "required": "Заполните поле «{name}»",
    "password": "Пароль должен содержать как минимум одну цифру, заглавную и строчную букву. Допустимы латинские буквы, цифры и символы: !{'@'}#$%^&*()_+",
    "passwordConfirmation": "Указанные пароли не совпадают",
    "number": "Поле «{name}» должно быть числом",
    "email": "Не является правильным email адресом",
    "login": "Допустимы латинские строчные буквы, цифры и символ «_»",
    "string": {
      "min": "Минимальное количество символов {number}",
      "max": "Максимальное количество символов {number}"
    },
    "value": {
      "min": "Минимальное значение {number}",
      "max": "Максимальное значение {number}"
    },
    "timeFormatHHmm": "Укажите в формате чч:мм",
    "originatorRegExp": "Допустимы латинские буквы, цифры и символы: _-",
    "phoneNumber": "Правильно заполните номер телефона"
  },
  "gender": {
    "men": "Мужской",
    "women": "Женский",
    "unknown": "Не определен"
  },
  "units": {
    "count": "раз | раз | раза | раз",
    "second": "секунд | секунда | секунды | секунд",
    "minute": "минут | минута | минуты | минут",
    "hour": "часов | час | часа | часов",
    "day": "дней | день | дня | дней"
  },
  "shortUnits": {
    "second": "сек.",
    "minute": "мин."
  },
  "actions": "Действия",
  "login": {
    "title": "Авторизация",
    "note": "Сделайте ваше взаимодействие\nс клиентами эффективнее",
    "form": {
      "login": "Логин",
      "password": "Пароль",
      "submit": "Войти"
    },
    "forgotPassword": "Забыли пароль?",
    "noAccount": "У меня пока нет аккаунта.",
    "register": "Зарегистрироваться",
    "success": "Добро пожаловать, в личный кабинет"
  },
  "confirmation": {
    "title": "Введите код из СМС",
    "note": "СМС-код для подтверждения отправлен\n на номер { phone }.",
    "notCode": "Не пришел код?",
    "again": "Отправить заново",
    "time": "через"
  },
  "forgotPassword": {
    "forgotTitle": "Сбросить пароль",
    "forgotDescription": "Введите аккаунт, на почту которого поступит письмо с данными для восстановления пароля",
    "form": {
      "login": "Логин",
      "captcha": "Каптча",
      "submit": "Восстановить пароль"
    },
    "goToLogin": "Вход",
    "goBackToLogin": "Я вспомнил свои данные",
    "restoreTitle": "Восстановление пароля",
    "restoreDescription": "Ссылка для восстановления пароля для аккаунта «{login}» отправлена на почту "
  },
  "loginPrivateArea": {
    "loginPrivateAreaTitle": "Подтвердите вход в личный кабинет клиента «{login}»",
    "form": {
      "submit": "Перейти в личный кабинет"
    }
  },
  "resetPassword": {
    "title": "Изменение пароля",
    "form": {
      "password": {
        "placeholder": "Введите новый пароль",
        "label": "Пароль"
      },
      "passwordRepeated": {
        "placeholder": "Повторите новый пароль",
        "label": "Повтор пароля"
      },
      "submit": "Подтвердить изменения и войти"
    }
  },
  "registration": {
    "title": "Регистрация",
    "description": "Укажите название организации и почту, по которой хотите зарегистрироваться. Наш менеджер вышлет все нужные инструкции",
    "form": {
      "companyName": "Название организации",
      "email": "Email",
      "captcha": "Каптча",
      "personalData": {
        "label": "Я ознакомился и даю согласие на обработку",
        "link": "персональных данных"
      },
      "submit": "Продолжить"
    },
    "hasAccount": "У меня уже есть аккаунт.",
    "login": "Вход",
    "success": {
      "title": "Спасибо за вашу заявку!",
      "description": "Письмо для подтверждения регистрации отправлено на почту {email}"
    }
  },
  "sidebar": {
    "menu": {
      "home": "Главная",
      "batches": "Рассылки",
      "sendMessages": "Отправка сообщений",
      "sendLists": "Списки для рассылок",
      "drafts": "Черновики",
      "scheduledBatches": "Отложенные рассылки",
      "checkMobileOperator": "Определить оператора",
      "templates": "Шаблоны",
      "templatesList": "Список шаблонов",
      "templatesCheck": "Проверка",
      "templatesTest": "Тестирование",
      "reports": "Отчеты",
      "log": "Журнал",
      "statistics": "Статистика",
      "accounting": "Бухгалтерия",
      "accountDetails": "Учетная карточка",
      "accountsAndInvoices": "Счета, фактуры, акты",
      "transactionsHistory": "История платежей",
      "settings": "Настройки",
      "deliveryAnalysis": "Анализ доставки",
      "reportsOnRequest": "Отчеты по запросу"
    }
  },
  "balanceInfo": {
    "title": "Ваш баланс",
    "creditCurrency": "Сообщений"
  },
  "profileMenu": {
    "openMenu": "Открыть меню пользователя",
    "closeMenu": "Закрыть меню пользователя",
    "senderNames": "Список имен отправителя",
    "accounts": "Учетные записи и департаменты",
    "api": "API и документация",
    "eventsLog": "Журнал событий",
    "logout": {
      "buttonTitle": "Выход",
      "confirmTitle": "Вы уверены, что хотите выйти?",
      "accept": "Выйти",
      "cancel": "Отмена"
    }
  },
  "languageSelector": {
    "ru": {
      "short": "РУ",
      "long": "Русский"
    },
    "en": {
      "short": "EN",
      "long": "English"
    }
  },
  "form": {
    "textarea": {
      "characterLimit": "Осталось {n} символов | Остался {n} символ | Осталось {n} символа | Осталось {n} символов",
      "transliteration": "Выполнить транслитерацию (Ру → Eng)",
      "sms": "{count} SMS"
    },
    "multiselect": {
      "placeholder": "Выберите",
      "noOptionsText": "Список пуст",
      "noResultsText": "Результаты не найдены",
      "acceptButtonText": "Выбрать",
      "removeTag": "Удалить",
      "selectedText": "Выбрано {count}"
    },
    "from": {
      "time": "Время начала",
      "date": "Дата начала"
    },
    "to": {
      "time": "Время окончания",
      "date": "Дата окончания"
    },
    "file": {
      "title": "Перетащите сюда файл или нажмите, чтобы выбрать",
      "delete": "Удалить",
      "accept": "Формат файла: {types}",
      "errors": {
        "wrongFormat": "Неверный формат файла",
        "fileTooLarge": "Файл слишком большой"
      }
    },
    "defaultSelectOptionLabel": "Все"
  },
  "templatesCheck": {
    "title": "Проверка шаблонов",
    "card": "Проверка текста на совпадение с шаблоном",
    "label": "Введите текст СМС-сообщения",
    "hint": "Проверьте, подходит ли потенциальный текст вашего сообщения под один из зарегистрированных шаблонов. Список зарегистрированных шаблонов можно просмотреть в соседнем разделе",
    "search": "Искать",
    "results": "Шаблонов не найдено, повторите поиск | Найден {count} похожий шаблон | Найдено {count} похожих шаблона | Найдено {count} похожих шаблонов"
  },
  "templatesTest": {
    "title": "Тестирование шаблона",
    "card": "Проверка текста на совпадение с шаблоном",
    "hint": "Проверьте, подходит ли потенциальный текст вашего сообщения под шаблон, который требуется подать на регистрацию мобильному оператору. Список ранее зарегистрированных шаблонов можно просмотреть в соседнем разделе",
    "templateLabel": "Введите текст шаблона",
    "messageLabel": "Введите текст сообщения",
    "submit": "Проверить",
    "result": {
      "fail": "Сообщение не подходит",
      "success": "Сообщение подходит"
    }
  },
  "templatesList": {
    "title": "Список шаблонов",
    "downloadButton": "Скачать шаблоны в XLS"
  },
  "templatesTable": {
    "message": "Шаблон",
    "operator": "Оператор",
    "type": "Тип шаблона",
    "originator": "Имя отправителя",
    "account": "Учетная запись",
    "status": "Статус"
  },
  "trafficTypes": {
    "0": "Нет типа",
    "1": "Рекламный",
    "2": "Транзакционный",
    "3": "Сервисный",
    "4": "Международный",
    "5": "Авторизационный",
    "6": "Рекламный шаблонированный"
  },
  "templates": {
    "message_downloaded": "Шаблон успешно скачан",
    "statuses": {
      "to_submit": "В очереди на отправку",
      "created": "Создан",
      "wrong": "Некорректный",
      "conflict": "Дубликат",
      "pending": "Отправляется на одобрение",
      "not_submitted": "Ошибка при отправке на одобрение",
      "submitted": "Отправлен на одобрение",
      "hold": "Требуется уточнение",
      "confirmed": "Одобрен",
      "declined": "Отклонён",
      "error": "Ошибка",
      "test": "Тест",
      "deleted": "Удалено"
    }
  },
  "filter": {
    "addButton": "добавить",
    "add": "Добавить фильтр",
    "clear": "Сбросить все",
    "empty": "Выберите параметры, которые вам нужны",
    "drawer": {
      "title": "Фильтры",
      "submit": "Искать",
      "close": "Закрыть фильтры",
      "apply": "Применить",
      "reset": "Сбросить"
    },
    "items": {
      "more": "ещё"
    },
    "fields": {
      "title": "Фильтры по полям",
      "dragHandlerTitle": "Перетащить"
    }
  },
  "pagination": {
    "nextButton": "Далее",
    "prevButton": "Назад",
    "jump": "Перейти на:",
    "pageSize": "Показать:",
    "totalItems": "0 результатов | {count} результат | {count} результата | {count} результатов",
    "collapse": "Свернуть меню",
    "expand": "Развернуть меню"
  },
  "noResult": {
    "noResultsFound": {
      "title": "Нет результатов",
      "note": "Проверьте поля ввода <br /> или попробуйте изменить фильтры"
    },
    "noData": {
      "title": "Нет данных для <br /> отображения"
    }
  },
  "channels": {
    "sms": "SMS",
    "viber": "Viber",
    "vk": "VK",
    "ok": "OK",
    "whatsapp": "WhatsApp",
    "push": "Push",
    "flashcall": "Flashcall",
    "email": "Email"
  },
  "messages": {
    "mt": "Исходящие (МТ)",
    "mo": "Входящие (МО)"
  },
  "all": "Все",
  "reloadPage": "Обновить страницу",
  "groups": {
    "title": "Списки для рассылок",
    "create": "Создать список",
    "dropdownLabel": "Действия с выбранными",
    "dropdownItems": {
      "send": "Отправить",
      "openGroup": "Открыть список",
      "delete": "Удалить"
    },
    "multiplyActions": {
      "delete": {
        "title": "Удалить выбранные группы?",
        "confirm": "Удалить",
        "cancel": "Отменить"
      }
    },
    "table": {
      "groupName": "Имя списка",
      "totalPhones": "Телефоны",
      "congratulate": "Поздравлять с ДР",
      "creationDate": "Дата создания",
      "congratulateVariants": {
        "yes": "Да",
        "no": "Нет"
      }
    },
    "delete": {
      "title": "Вы уверены, что хотите удалить список «{ name }»",
      "acceptButton": "Удалить",
      "cancelButton": "Отменить",
      "success": "Список успешно удалён"
    },
    "search": {
      "label": "Название списка",
      "submit": "Искать"
    }
  },
  "drafts": {
    "title": "Черновики",
    "table": {
      "name": "Название черновика",
      "channel": "Канал",
      "text": "Сообщение",
      "addDate": "Дата создания"
    },
    "create": "Создать черновик",
    "search": {
      "label": "Название черновика",
      "submit": "Искать"
    },
    "delete": "Удалить выбранные",
    "tabs": {
      "all": "Все"
    }
  },
  "draft": {
    "name": "Название",
    "channel": "Канал",
    "addDate": "Дата создания",
    "text": "Сообщение",
    "closeHint": "Закрыть просмотр черновика",
    "buttons": {
      "delete": "Удалить",
      "send": "Отправить этот черновик",
      "edit": "Редактировать"
    },
    "deleteSingle": {
      "title": "Удалить черновик «{name}»?",
      "buttons": {
        "delete": "Удалить",
        "cancel": "Отменить"
      }
    },
    "deleteMultiple": {
      "all": "Вы уверены, что хотите удалить все черновики?",
      "exclude": "Вы уверены, что хотите удалить все черновики исключая {count} черновик? | Вы уверены, что хотите удалить все черновики исключая {count} черновик? | Вы уверены, что хотите удалить все черновики исключая {count} черновика? | Вы уверены, что хотите удалить все черновики исключая {count} черновиков?",
      "include": "Вы уверены, что хотите удалить {count} черновик? | Вы уверены, что хотите удалить {count} черновик? | Вы уверены, что хотите удалить {count} черновика? | Вы уверены, что хотите удалить {count} черновиков?",
      "buttons": {
        "delete": "Удалить",
        "cancel": "Отменить"
      }
    },
    "create": {
      "title": "Создание черновика",
      "closeHint": "Закрыть создание черновика",
      "buttons": {
        "create": "Создать черновик",
        "cancel": "Отменить"
      }
    },
    "form": {
      "name": "Название черновика",
      "text": "Текст сообщения",
      "channel": "Канал"
    },
    "edit": {
      "title": "Редактирование черновика",
      "closeHint": "Закрыть редактирование черновика",
      "buttons": {
        "save": "Сохранить изменения",
        "cancel": "Отменить"
      }
    }
  },
  "operatorByPhone": {
    "title": "Определить оператора по номеру телефона",
    "form": {
      "inputPhonePlaceholder": "Номер телефона",
      "inputPhoneError": "Данный номер телефона не существует. Введите коректный номер телефона в международном формате. Например, номер российских сетей может выглядеть так: +79031234567",
      "submitButton": "Определить"
    },
    "phoneInfo": {
      "phone": "Телефон",
      "operator": "Оператор",
      "countryName": "Страна",
      "regionName": "Регион"
    },
    "unidentifiedPhone": "Не удалось определить оператора"
  },
  "accountsAndDepartments": {
    "title": "Учетные записи и департаменты",
    "createAccountLink": "Создать учетную запись",
    "noAccounts": "Нет учетных записей",
    "tabs": {
      "accounts": "Список учетных записей",
      "departments": "Список департаментов"
    },
    "description": {
      "accounts": "Учетные записи, имеющие права, относящиеся ко всем отделам без исключения",
      "departments": "Список всех подразделений, которые могут делать рассылку и совершать другие действия"
    },
    "toasts": {
      "accounts": {
        "successDelete": "Учетная запись «{ name }» удалена",
        "successUnblock": "Учетная запись «{ name }» восстановлена"
      },
      "departments": {
        "successDelete": "Департамент «{ name }» удален",
        "successUnblock": "Департамент «{ name }» восстановлен"
      }
    },
    "modal": {
      "accounts": {
        "title": "Вы действительно хотите удалить учетную запись «{ name }»?"
      },
      "departments": {
        "title": "Вы действительно хотите удалить департамент «{ name }»?"
      },
      "acceptButton": "Удалить",
      "cancelButton": "Отменить"
    }
  },
  "accountsTable": {
    "columns": {
      "name": "Имя учетной записи",
      "login": "Логин",
      "twoFactorAuth": "2FA",
      "status": "Статус",
      "addDate": "Дата создания",
      "editDate": "Дата изменения"
    },
    "status": {
      "active": "Активна",
      "deleted": "Удалена",
      "blocked": "Заблокирована"
    },
    "actions": {
      "openEventLog": "Просмотреть журнал событий",
      "edit": "Редактировать",
      "unblock": "Восстановить",
      "delete": "Удалить"
    }
  },
  "departmentsTable": {
    "columns": {
      "name": "Название департамента",
      "status": "Статус",
      "addDate": "Дата создания",
      "twoFactorAuth": "2FA",
      "accounts": "Связанные учетные записи"
    },
    "status": {
      "active": "Активен",
      "deleted": "Удален",
      "blocked": "Заблокирован"
    },
    "actions": {
      "edit": "Редактировать",
      "unblock": "Восстановить",
      "delete": "Удалить"
    }
  },
  "group": {
    "goToGroups": "Списки для рассылок",
    "actions": {
      "title": "Действия с выбранными",
      "send": "Отправить",
      "move": "Переместить в",
      "copy": "Скопировать в",
      "delete": "Удалить",
      "selectList": "Выберите список"
    },
    "rowActions": {
      "send": "Отправить",
      "delete": "Удалить",
      "edit": "Редактировать"
    },
    "tabs": {
      "edit": "Редактирование",
      "settings": "Настройки"
    },
    "table": {
      "phone": "Телефон",
      "memberName": "Имя",
      "gender": "Пол",
      "comment": "Комментарий",
      "addDate": "Дата создания",
      "birthday": "День рождения"
    },
    "createGroup": {
      "title": "Создание списка",
      "success": "Список успешно создан"
    },
    "settings": {
      "success": "Данные успешно изменены"
    },
    "groupForm": {
      "groupName": "Название списка",
      "additional": "Дополнительно",
      "viewForAll": "Список виден всем департаментам",
      "viewOptional": "Список не виден основному департаменту",
      "congratulate": {
        "title": "Поздравление",
        "switch": "Поздравлять абонентов с ДР",
        "birthdayMessage": {
          "label": "Текст поздравления",
          "placeholder": "Введите текст поздравления",
          "hint": "В теле сообщения поля [[name]] будут заменены на имена членов списка",
          "initialValue": "С днем рождения, [[name]]"
        },
        "originator": {
          "label": "Имя отправителя",
          "placeholder": "Введите имя отправителя",
          "validation": "Допустимы латинские буквы, цифры и символы: _-"
        },
        "startTime": {
          "label": "Время отправки поздравления",
          "placeholder": "Выберите время отправки поздравления"
        },
        "rus": {
          "label": "По-русски",
          "hint": "Eсли опция не отмечена, а текст написан по-русски, то сообщение автоматически транслитерируется, при этом количество символов в сообщении может возрасти!"
        }
      },
      "save": "Сохранить"
    },
    "modalDelete": {
      "single": {
        "title": "Вы уверены, что хотите удалить «{phone}»?",
        "success": "Номер «{phone}» успешно удалён"
      },
      "multiple": {
        "title": "Вы уверены, что хотите удалить выбранные номера?",
        "success": "Номера успешно удалены"
      },
      "submit": "Удалить",
      "cancel": "Отмена"
    },
    "membersForm": {
      "title": "Телефоны с именами пользователей",
      "placeholder": "79099090090; Алексей; 1987-05-23;М",
      "hint": "<strong>В формате:</strong> <br>телефон; имя(если известно); день рождения (ГГГГ-ММ-ДД); пол (М или Ж)<br> <br><strong> Например:</strong> <br>79099090090; Алексей; 1987-05-23;М",
      "tip": "Одна запись в строку",
      "submit": "Добавить в список",
      "success": "В список ничего не добавлено | В список добавлен {count} номер | В список добавлено {count} номера | В список добавлено {count} номеров"
    },
    "deleteListForm": {
      "title": "Удалить номера из других списков",
      "placeholder": "Выберите список",
      "submit": "Удалить из списка",
      "success": "Из списков ничего не удалено | Из списков удален {count} номер | Из списков удалено {count} номера | Из списков удалено {count} номеров"
    },
    "loadFromFileForm": {
      "title": "Загрузить список",
      "file": {
        "title": "Перетащите сюда файл или нажмите, чтобы выбрать",
        "deleteFile": "Удалить файл",
        "errors": {
          "wrongFormat": "Неверный формат файла",
          "fileTooLarge": "Файл слишком большой"
        },
        "acceptFiles": "Формат файла: {types}; Кодировка: {charsets};",
        "additional": "Каждая запись на новой строке.",
        "charset": {
          "label": "Кодировка"
        }
      },
      "submit": "Обработать список",
      "success": "Обработка файла поставлена в очередь"
    },
    "deleteMembersForm": {
      "title": "Удалить номера",
      "placeholder": "Список телефонов в столбик",
      "tip": "Одна запись в строку",
      "submit": "Удалить из списка",
      "success": "Из списка ничего не удалено | Из списка удален {count} номер | Из списка удалено {count} номера | Из списка удалено {count} номеров"
    },
    "copy": {
      "title": "Копировать телефоны в другой список",
      "submit": "Копировать",
      "close": "Отмена",
      "confirmAll": "Вы уверены, что хотите скопировать все номера в список «{list}»?",
      "confirmExclude": "Вы уверены, что хотите скопировать все номера в список «{list}» исключая {count} номер? | Вы уверены, что хотите скопировать все номера в список «{list}» исключая {count} номер? | Вы уверены, что хотите скопировать все номера в список «{list}» исключая {count} номерa? | Вы уверены, что хотите скопировать все номера в список «{list}» исключая {count} номеров?",
      "confirmInclude": "Вы уверены, что хотите скопировать {count} номер в список «{list}»? | Вы уверены, что хотите скопировать {count} номер в список «{list}»? | Вы уверены, что хотите скопировать {count} номера в список «{list}»? | Вы уверены, что хотите скопировать {count} номеров в список «{list}»?",
      "informationAll": "Все номера будут скопированы в список «{list}»",
      "informationExclude": "Все номера будут скопированы в список «{list}», кроме:",
      "informationInclude": "Будет скопирован {count} номер в список «{list}» | Будет скопирован {count} номер в список «{list}» | Будут скопированы {count} номера в список «{list}» | Будет скопировано {count} номеров в список «{list}»",
      "success": "Успешно скопировано"
    },
    "move": {
      "title": "Перенести телефоны в другой список",
      "submit": "Перенести",
      "close": "Отмена",
      "confirmAll": "Вы уверены, что хотите перенести все номера в список «{list}»?",
      "confirmExclude": "Вы уверены, что хотите перенести все номера в список «{list}» исключая {count} номер? | Вы уверены, что хотите перенести все номера в список «{list}» исключая {count} номер? | Вы уверены, что хотите перенести все номера в список «{list}» исключая {count} номерa? | Вы уверены, что хотите перенести все номера в список «{list}» исключая {count} номеров?",
      "confirmInclude": "Вы уверены, что хотите перенести {count} номер в список «{list}»? | Вы уверены, что хотите перенести {count} номер в список «{list}»? | Вы уверены, что хотите перенести {count} номера в список «{list}»? | Вы уверены, что хотите перенести {count} номеров в список «{list}»?",
      "informationAll": "Все номера будут перенесены в список «{list}»",
      "informationExclude": "Все номера будут пернесены в список «{list}», кроме:",
      "informationInclude": "Будет перенесен {count} номер в список «{list}» | Будет перенесен {count} номер в список «{list}» | Будут перенесены {count} номера в список «{list}» | Будут перенесены {count} номеров в список «{list}»",
      "success": "Успешно перенесены"
    },
    "transferForm": {
      "placeholder": "Введите название списка",
      "label": "Название списка",
      "noOptionsText": "Список пуст. Уточните запрос",
      "noResultsText": "Ничего не найдено"
    },
    "listMembers": {
      "phones": "Телефоны",
      "exclude": "Исключения"
    },
    "editMember": {
      "title": "Редактирование телефона {phone}",
      "close": "Закрыть окно редактирования телефона",
      "cancel": "Отменить",
      "save": "Сохранить изменения",
      "success": "Данные успешно сохранены",
      "form": {
        "phone": "Телефон",
        "name": "Имя",
        "gender": "Пол",
        "birthday": "День рождения",
        "comment": "Комментарий"
      }
    },
    "filters": {
      "phone": {
        "label": "Телефон",
        "placeholder": "Искать по телефону"
      },
      "memberName": {
        "label": "Имя",
        "placeholder": "Искать по имени"
      },
      "gender": {
        "label": "Пол",
        "placeholder": "Выберите пол"
      },
      "birthday": {
        "label": "День рождения"
      }
    }
  },
  "messagesLog": {
    "title": "Журнал",
    "spent": "Потрачено: { totalCosts } {currency}",
    "totalMessages": "{count} сообщений | {count} сообщение | {count} сообщения | {count} сообщений",
    "fields": {
      "id": "ID сообщения",
      "sendDate": "Дата \n отправки",
      "phone": "Телефон",
      "message": "Сообщение",
      "deliveryDate": "Дата \nизменения",
      "submissionDate": "Дата \nпоступления",
      "status": "Статус",
      "cost": "Цена",
      "originator": "Имя \nотправителя",
      "ip": "IP",
      "channel": "Канал",
      "departmentName": "Департамент",
      "batchName": " Имя \nрассылки",
      "error": "Ошибка",
      "countryName": "Страна",
      "readDate": "Дата \nпрочтения",
      "operatorName": "Имя \nоператора",
      "trafficType": "Тип трафика"
    },
    "filters": {
      "phones": "Номера телефонов",
      "originator": "Имя отправителя",
      "type": "Тип сообщения",
      "status": "Статус",
      "channel": "Канал",
      "department": "Департаменты",
      "mainLogin": "[Основной логин]",
      "batch": "Название рассылки",
      "batchPlaceholder": "Введите название рассылки"
    },
    "form": {
      "errorPhonesValidate": "Номера телефонов должны содержать только числа",
      "endDateLargerStartDate": "Дата начала не может быть больше даты конца",
      "searchByDateWithoutPhones": "По заданным параметрам интервал поиска не должен превышать 1 месяц. Если задать номер телефона, то можно указать интервал до 3 месяцев",
      "isTooLongDateToRequest": "Для выгрузки сообщений более чем за 3 месяца нужно воспользоваться выгрузкой в формате csv/zip"
    },
    "status": {
      "delivered": "Доставленные",
      "notDelivered": "Не доставленные",
      "read": "Прочитанные",
      "clicked": "Клик",
      "checked": "Проверено"
    },
    "messageStatus": {
      "delivered": "Доставлено",
      "expired": "Просрочено",
      "deleted": "Удалено",
      "undelivered": "Не доставлено",
      "buffered": "Доставляется",
      "unknown": "Неизвестный статус",
      "rejected": "Отклонено",
      "read": "Прочитано",
      "timeout": "Таймаут",
      "clicked": "Клик",
      "checked": "Проверено",
      "noData": "Нет данных"
    },
    "messageError": {
      "1": "Абонент недоступен или отключен",
      "2": "У абонента включен запрет на прием сообщений или абонента заблокировал оператор (возможно, в связи с отрицательным балансом)",
      "3": "Номер телефона не существует или не обслуживается",
      "4": "Память телефона абонента переполнена",
      "5": "Аппаратная ошибка телефона абонента",
      "6": "Сервис коротких сообщений не предоставляется",
      "7": "Аппарат абонента не поддерживает прием коротких сообщений",
      "8": "Аппарат абонента занят операцией, препятствующей получению короткого сообщения",
      "9": "Абонент находится в роуминге",
      "10": "Время ожидания ответа от SMSC абонента истекло",
      "11": "Внутренняя ошибка маршрутизации",
      "12": "Внутренняя ошибка системы",
      "13": "Ошибка коммутатора (внутренняя ошибка передачи данных)",
      "14": "Блокировка оператором или незарегистрированный пользователь",
      "15": "Очередь сообщений для абонента со стороны оператора переполнена",
      "16": "Некорректное имя отправителя",
      "17": "Сообщение не маршрутизируемо"
    },
    "tabs": {
      "messagesLog": "Журнал рассылок",
      "reports": "Запрошенные отчеты"
    },
    "messageStatusHelp": {
      "close": "Закрыть",
      "title": "Справка по статусам сообщений",
      "subtitle": "Всё статусы, кроме «<strong>Доставляется</strong>» и «<strong>Нет данных</strong>» являются окончательными.",
      "delivered": {
        "title": "Доставлено",
        "content": "Сообщение доставлено на телефон абонента. Только данный статус означает 100% доставку сообщения на телефон абонента, но не означает, что сообщение было прочитано. Статус может не являться финальным, если канал отправки поддерживает статус «<strong>Клик</strong>» или «<strong>Прочитано</strong>»."
      },
      "clicked": {
        "title": "Клик",
        "content": "Может быть присвоен сообщению в том случае, если на аккаунте подключена услуга и канал отправки поддерживает данный статус. Статус присваивается СМС сообщению после перехода абонентом по модернизированной платформой СМС Трафик ссылке, имеющейся в направленном абоненту сообщении."
      },
      "undelivered": {
        "title": "Не доставлено",
        "content": "Не удалось доставить сообщение на телефон абонента, дальнейшие попытки доставки предприниматься не будут.<br/> Возможные причины недоставки: <ol> <li>Несуществующий номер.</li> <li>Абонент заблокирован за неуплату.</li> <li>У абонента установлен «запрет вызова».</li></ol>"
      },
      "expired": {
        "title": "Просрочено",
        "content": "Сообщение не было доставлено. Предпринималось несколько попыток доставить сообщение на телефон абонента, но они не удались в течение срока жизни сообщения.<br/> Возможные причины: <ol> <li>Телефон был выключен или находился вне зоны действия сети в течении срока жизни сообщения.</li> <li>Память для сообщений в телефоне была переполнена, и абонент не освободил память в течение всего срока жизни сообщения.</li> <li>Многократные сбои в оборудовании сотовой сети при попытках передать сообщение абоненту.</li> </ol>"
      },
      "unknown": {
        "title": "Неизвестный статус, Ошибка",
        "content": "От SMS-центра не был получен статус доставки сообщения.<br/> Возможные причины: <ol><li>Сбой на оборудовании сотовой сети.</li> <li>На данном направлении не предоставляется информация о статусах.</li></ol>"
      },
      "rejected": {
        "title": "Отклонено",
        "content": "Сообщение не было доставлено. Сообщение было отвергнуто SMS-центром. Основные причины: <ol> <li>Неправильный номер абонента.</li> <li>Неправильный формат имени отправителя.</li> <li>Неправильный формат сообщения.</li> <li>Отвергнуто спам-фильтром из-за того, что зафиксировано слишком много попыток отправить одно и то же сообщение на один и тот же номер (более 4 за час или более 8 за 12 часов).</li></ol>"
      },
      "deleted": {
        "title": "Удалено",
        "content": "Сообщение было удалено из SMS-центра.<br/> Возможные причины: <ol> <li>Сообщение не может быть маршрутизируемо ввиду наличия ограничений на стороне оператора.</li> <li>В процессе отправки сообщения произошла ошибка, препятствующая дальнейшим попыткам доставки сообщения до целевого абонента.</li> <li>В случае подключенной услуги по сокращению ссылок, статус может быть присвоен тем сегментам, что фактически не были отправлены в сеть оператора ввиду уменьшения фактической длины SMS сообщения.</li> </ol>"
      },
      "read": {
        "title": "Прочитано",
        "content": "Статус означает, что абонент открыл сообщение. </br> Статус «<strong>Прочитано</strong>» поддерживают все каналы, кроме SMS."
      },
      "buffered": {
        "title": "Доставляется",
        "content": "Делаются попытки доставить сообщение. Этот статус временный и через некоторое время обязательно изменится.<br /> Возможные причины: <ol> <li>Телефон выключен или находится вне зоны действия сети.</li> <li>Память для сообщений в телефоне переполнена.</li> <li>Еще не получен актуальный статус сообщения от SMS-центра.</li> <ol>"
      },
      "noData": {
        "title": "Нет данных",
        "content": "Ожидает отправки. Этот статус временный и через некоторое время обязательно изменится. Если у сообщения есть дата отправки, это значит, что еще не получено никаких данных от SMS-центра о статусе сообщения. Если у сообщения нет даты отправки, это значит, что оно ещё доставляется. Обычно это происходит при отложенной отправке, когда время отправки данного сообщения еще не подошло."
      },
      "checked": {
        "title": "Проверено",
        "content": "Означает, что абонент передал тем или иным образом информацию из текста полученного им сообщения компании, отправившей сообщение. Например, написал в поле для ввода кода на сайте компании присланные в тексте сообщения цифры."
      }
    },
    "reportGeneration": {
      "openButton": "Заказать отчет в Csv/zip",
      "checkbox": "Минимум 1 поле должно быть выбрано",
      "back": "Фильтры",
      "close": "Закрыть",
      "dragHandlerTitle": "Перетащить",
      "title": "Формирование отчета",
      "subtitle": "Формирование отчета может занять некоторое время. Укажите адрес, на который будет выслано уведомление о готовности.",
      "description": "Отчет будет доступен в разделе «Журнал» в течение 7 дней",
      "explanation": "На этот адрес будет выслано уведомление о готовности",
      "fieldsTitle": "В отчете будут поля:",
      "submit": "Сформировать",
      "cancel": "Отменить",
      "form": {
        "email": "Email",
        "fileEncoding": "Кодировка файла"
      },
      "success": {
        "title": "Ваш запрос отправлен",
        "description": "Мы пришлем уведомление о готовности на email. Отчет будет доступен в разделе «Журнал» в течение 7 дней",
        "cancel": "Закрыть",
        "accept": "Отчеты"
      }
    }
  },
  "requestedReports": {
    "fields": {
      "genDate": "Дата генерации",
      "from": "Начальная дата",
      "to": "Конечная дата",
      "departments": "Департаменты",
      "phones": "Телефоны",
      "originator": "Имя отправителя",
      "type": "Тип сообщения",
      "channel": "Канал",
      "deliveryStatus": "Статус",
      "batches": "Имя рассылки",
      "format": "Формат",
      "status": "Инфо"
    },
    "reportInfo": {
      "table": {
        "name": "Имя",
        "date": "Дата",
        "event": "Событие",
        "header": "Cписок действий",
        "noData": "Этот отчет еще никто не скачал"
      },
      "eventStatus": {
        "download": "Скачано",
        "create": "Создано"
      }
    },
    "reportStatus": {
      "new": "В очереди",
      "completed": "Скачать",
      "processing": "Формируется",
      "need_to_send_report": "Ожидается отправка",
      "with_error": "Ошибка"
    }
  },
  "ReportsOnRequest": {
    "pageTitle": "Отчеты по запросу",
    "emails": "Адресаты",
    "subject": "Тема",
    "sentDate": "Отправлено",
    "files": "Файлы",
    "report": "Отчеты"
  },
  "DeliveryAnalysis": {
    "pageTitle": "Анализ доставки",
    "createAnalysis": "Создать отчет",
    "back": "Назад",
    "create": "Создать",
    "noPhones": "Телефонов в отчете нет",
    "successMessage": "Отчет создан, вам будет выслано уведомление о готовности",
    "departments": "Департаменты",
    "failureInterval": "Количество активных дней",
    "dataDescriptions": "Укажите период не более 2 месяцев",
    "failedDescriptions": "Будут показаны номера телефонов, участвовавшие в рассылках в течение указанного периода. При этом, если в указанный период на номер телефона предпринимались попытки отправки менее, чем указанное количество дней, то такие номера в выборке участвовать не будут. Это сделано для того, чтобы исключить единичные или случайные попадания номеров в негативную статистику",
    "total": "Всего",
    "download": "Скачать отчет",
    "fields": {
      "reportId": "id",
      "from": "Начальная дата",
      "to": "Конечная дата",
      "creationDate": "Дата создания",
      "departments": "Департамент",
      "failureInterval": "Интервал",
      "deliveryStatus": "Статус",
      "phone": "Телефон",
      "failureDays": "Интервал",
      "failureMessages": "Кол-во недоставок"
    },
    "reportStatus": {
      "0": "Формируется",
      "1": "Выполнено"
    },
    "error": {
      "134": "Телефонов, удовлетворяющих данному условию, не найдено."
    }
  },
  "statistics": {
    "title": "Статистика",
    "tabs": {
      "statistics": "Статистика рассылок",
      "monthly": "Аналитика по месяцу"
    },
    "groupBy": {
      "date": "Дата",
      "originator": "Имя отправителя",
      "channel": "Канал",
      "messageType": "Тип сообщения",
      "month": "Месяц",
      "countryId": "Страна",
      "operatorId": "Оператор",
      "departmentId": "Департамент",
      "batchId": "Рассылка",
      "trafficType": "Тип трафика"
    },
    "filters": {
      "date": "Дата",
      "groupByTitle": "Порядок группировки",
      "messageType": "Тип сообщения",
      "channel": "Канал",
      "departments": "Департаменты",
      "batch": "Название рассылки"
    },
    "filtersError": {
      "emptyGroup": "Отметьте хотя бы 1 чекбокс",
      "rangeMoreThanOneYear": "Диапазон между «от» и «до» должен быть меньше 365 дней",
      "rangeMoreThanTwoMonth": "Диапазон между «от» и «до» должен быть меньше 2 месяцев",
      "endDateLargerStartDate": "Дата начала не может быть больше даты конца"
    },
    "column": {
      "delivered": "Доставлено, шт",
      "deliveredPercent": "Доставлено, %",
      "read": "Прочитано, шт",
      "readPercent": "Прочитано, %",
      "clicked": "Клик, шт",
      "clickedPercent": "Клик, %",
      "total": "Всего сообщ.",
      "sumCosts": "Потрачено, {currency}"
    },
    "summary": "Всего",
    "downloadButton": "Скачать статистику в XLS"
  },
  "createAccount": {
    "title": "Создание новой учетной записи",
    "link": "Учетные записи и департаменты",
    "successCreate": "Учетная запись создана"
  },
  "accountForm": {
    "name": {
      "title": "Имя учетной записи",
      "description": "Это имя будет отображаться в журналах и документах",
      "label": "Имя учетной записи",
      "placeholder": "Введите имя учетной записи"
    },
    "PassStrength": {
      "formTitle": "Политика пароля",
      "formPlaceholder": "Выберите политику пароля",
      "default": {
        "label": "Стандартная",
        "shortDescription": "Минимум 8 символов, минимум 1 цифра и латинские (a-z) буквы обоих регистров",
        "description": "Пароль должен содержать как минимум одну цифру, заглавную и строчную букву. Допустимы латинские буквы, цифры и символы: !{'@'}#$%^&*()_+"
      },
      "weak": {
        "label": "Ослабленная",
        "shortDescription": "Минимум 8 символов",
        "description": "Минимум 8 символов"
      },
      "complex": {
        "label": "Усиленная",
        "shortDescription": "Минимум 8 символов, минимум 1 цифра и латинские (a-z) буквы обоих регистров, минимум 1 спец. символ.",
        "description": "Пароль должен содержать как минимум одну цифру, заглавную и строчную букву, а также один спец-символ. Допустимы латинские буквы, цифры и символы: !{'@'}#$%^&*()_+"
      }
    },
    "login": {
      "title": "Логин",
      "description": "Под этим логином пользователь сможет заходить в личный кабинет",
      "label": "Логин",
      "placeholder": "Введите логин"
    },
    "defaultOriginator": {
      "title": "Имя отправителя по умолчанию",
      "description": [
        "Имя, которое будет указано в рассылках",
        "оно должно быть уникальным"
      ],
      "label": "Имя отправителя по умолчанию",
      "placeholder": "Введите имя отправителя по умолчанию"
    },
    "twoFactorAuthentication": {
      "title": "Двухфакторная аутентификация",
      "description": "Введите номер телефона владельца данной учетной записи в международном формате, без \"+\", например, {phone}",
      "label": "Номер телефона",
      "placeholder": "Введите номер телефона"
    },
    "password": {
      "title": "Пароль",
      "description": "Пароль",
      "label": "Пароль",
      "placeholder": "Введите пароль"
    },
    "oldPassword": {
      "title": "Старый пароль",
      "description": "Заполните поле «Старый пароль»",
      "label": "Старый пароль",
      "placeholder": "Старый пароль",
      "newOldplaceholder": "Введите новый пароль",
      "newRepeatOldplaceholder": "Повторите новый пароль"
    },
    "passwordRules": {
      "tooBig": "Пароль не можут быть больше 25 символов",
      "tooSmall": "Пароль должен содержать не менее 8 символов",
      "theSame": "Пароль должен совпадать",
      "empty": "Пароль должен быть заполнен",
      "default": "Пароль",
      "weak": "Введите пароль",
      "complex": "Введите пароль"
    },
    "passwordConfirmation": {
      "description": "Повторите пароль",
      "label": "Повтор пароля",
      "placeholder": "Повторите пароль"
    },
    "roles": {
      "title": "Права учетной записи"
    },
    "timezone": {
      "title": "Часовой пояс учетной записи",
      "country": {
        "label": "Страна",
        "placeholder": "Выберите страну"
      },
      "timezone": {
        "label": "",
        "placeholder": "Выберите часовой пояс",
        "noOptionsText": "Для отображения списка часовых поясов выберите страну"
      }
    },
    "buttonSubmit": "Сохранить",
    "validation": {
      "emptyField": "this field cannot be empty"
    }
  },
  "rolesHints": {
    "7": "У учетной записи не будет возможности просматривать текст сообщения в журнале",
    "9": "Учетная запись не сможет отправлять сообщения, но сможет создавать рассылки, требующие для отправки последующего одобрения модератора",
    "8": "Учетная запись сможет просматривать тексты сообщений, но все цифры в текстах будут заменены на символ *"
  },
  "editAccount": {
    "title": "Редактирование учетной записи",
    "link": {
      "account": "Учетные записи и департаменты",
      "depUser": "Учетные записи департамента"
    },
    "tabs": {
      "settings": "Настройки учетной записи",
      "privacy": "Приватность",
      "security": "Безопасность",
      "changePassword": "Смена пароля"
    },
    "sections": {
      "privacy": {
        "form": {
          "viewMessages": {
            "title": "Просмотр сообщений",
            "description": {
              "empty": {
                "subUser": "Пользователь может просматривать сообщения без ограничений",
                "depUser": "Пользователь может просматривать сообщения только в рамках своего департамента"
              },
              "filled": "Пользователь может просматривать сообщения учетных записей из списка",
              "disabled": "У пользователя нет доступа к просмотру сообщений. Список не используется"
            }
          },
          "approveBatches": {
            "title": "Подтверждение рассылок на модерации",
            "description": {
              "empty": "У пользователя нет доступа к подтверждению рассылок на модерацию",
              "filled": "Пользователь может подтверждать рассылки на модерации учетных записей из списка",
              "disabled": "У пользователя нет доступа к редактированию рассылок. Список не используется"
            }
          },
          "editBatches": {
            "title": "Редактирование рассылок",
            "description": {
              "empty": "Список пустой, пользователь может редактировать только собственные рассылки",
              "filled": "Пользователь может редактировать рассылки учетных записей из списка",
              "disabled": "У пользователя нет доступа к редактированию рассылок. Список не используется"
            }
          },
          "viewGroups": {
            "title": "Просмотр списков для рассылок",
            "description": {
              "empty": {
                "subUser": "Пользователь может просматривать только свои и общедоступные списки для рассылок",
                "depUser": "Пользователь может просматривать только списки для рассылок своего департамента и общедоступные списки для рассылок"
              },
              "filled": "Пользователь может просматривать списки для рассылок учетных записей из списка"
            }
          }
        },
        "multiselect": {
          "groups": {
            "mainAccount": "Основной логин",
            "accounts": "Учетные записи",
            "departments": "Департаменты"
          }
        }
      },
      "changePassword": {
        "title": "Пароль",
        "form": {
          "password": {
            "description": "Новый пароль",
            "label": "Пароль",
            "placeholder": "Введите пароль"
          },
          "passwordConfirmation": {
            "description": "Повторите пароль",
            "label": "Повтор пароля",
            "placeholder": "Повторите пароль"
          }
        }
      },
      "security": {
        "title": "Безопасность",
        "form": {
          "nTypes": {
            "description": "Количество неверных наборов пароля:"
          },
          "inactivityPeriod": {
            "description": "Блокировать учетную запись при неиспользовании ее через:"
          },
          "authTimeout": {
            "description": "Разрыв сессии учетной записи по неактивности через:"
          },
          "passwordPeriod": {
            "description": "Срок жизни пароля:"
          },
          "allowedGates": {
            "description": "Ограничение доступа:"
          },
          "maxSessions": {
            "description": "Максимальное количество открытых сессий:"
          }
        },
        "select": {
          "defaultValue": "Не ограничено",
          "allowedGatesValues": {
            "lkOnly": "Только ЛК",
            "apiOnly": "Только API"
          }
        }
      }
    },
    "buttonSubmit": "Сохранить",
    "successEdit": "Изменения сохранены"
  },
  "twoFactorAuth": {
    "notEnabled": "Не подключен"
  },
  "editDepartment": {
    "title": "Редактирование",
    "link": "Учетные записи и департаменты",
    "tabs": {
      "settings": "Настройки департамента",
      "accounts": "Список учетных записей"
    },
    "sections": {
      "settings": {
        "form": {
          "name": {
            "title": "Имя департамента",
            "description": "Это имя будет отображаться в журнале событий и с ним можно взаимодействовать",
            "label": "Имя департамента",
            "placeholder": "Введите имя департамента"
          },
          "login": {
            "title": "Логин",
            "description": "Под этим логином пользователь сможет заходить в личный кабинет",
            "label": "Логин",
            "placeholder": "Введите логин"
          }
        }
      },
      "accounts": {
        "title": "Учетные записи, относящиеся к"
      }
    },
    "buttonSubmit": "Сохранить",
    "successEdit": "Изменения сохранены"
  },
  "batches": {
    "title": "Отложенные рассылки",
    "createBatchLink": "Создать рассылку",
    "tabs": {
      "scheduled": "Отложенные рассылки",
      "archived": "Архив отложенных рассылок"
    },
    "actions": {
      "start": "Запустить",
      "pause": "Остановить",
      "cancel": "Отменить",
      "approve": "Подтвердить"
    },
    "tableActionsLabel": "Действия с выбранными",
    "successStateChange": "Нет рассылок подходящих для изменения статуса | Статус {count} рассылки был успешно изменен | Статус {count} рассылок был успешно изменен | Статус {count} рассылок был успешно изменен",
    "parentsBatches": {
      "all": "Все рассылки"
    },
    "table": {
      "columns": {
        "interval": "Период рассылки",
        "progress": "Поставлено номеров на отправку",
        "name": "Название рассылки",
        "originator": "Имя отправителя",
        "message": "Сообщение",
        "status": "Статус",
        "batchesGroups": "Списки использованные в рассылке",
        "sendDate": "Дата отправки",
        "type": "Тип сообщения",
        "ip": "IP-адрес"
      }
    },
    "filters": {
      "date": "Дата",
      "status": "Статус"
    },
    "status": {
      "new": "Ожидает рассылки",
      "processing": "Рассылается",
      "canceled": "Отменено",
      "paused": "Приостановлено",
      "finished": "Завершено",
      "expired": "Время ожидания истекло",
      "premoderate": "На модерации"
    },
    "messageType": {
      "smartDelivery": "Smart Delivery",
      "sms": "SMS"
    },
    "detailedStatus": {
      "totalPhones": "Абонентов",
      "percent": "Отправлено",
      "success": "Отправленные ({n} шт.)",
      "failed": "Не отправленные ({n} шт.)"
    },
    "details": {
      "title": "Информация о рассылке",
      "closeHint": "Закрыть просмотр рассылки",
      "closeButton": "Закрыть",
      "editButton": "Редактировать",
      "labels": {
        "status": "Статус",
        "name": "Название",
        "type": "Тип сообщения",
        "originator": "Отправитель",
        "creationDate": "Дата создания",
        "startDate": "Дата начала",
        "breakTime": "Время остановки",
        "localTime": "Локальное время",
        "gap": "Задержка",
        "endDate": "Время окончания",
        "totalPhones": "Всего номеров",
        "sentPhones": "Завершено",
        "blacklist": "Черный список",
        "message": "Сообщение",
        "buttonName": "Надпись кнопки",
        "buttonUrl": "Ссылка",
        "image": "Картинка"
      },
      "undefinedValue": "Не задано",
      "localTime": {
        "true": "Учитывается",
        "false": "Не учитывается"
      }
    },
    "edit": {
      "validation": {
        "timeFormat": "Формат должен быть чч:мм:сс",
        "sameStopAndStartTime": "Время возобновления не может быть равно времени приостановки",
        "timeInputLabel": "Время",
        "startDateLessThanCurrentDate": "Дата начала не может быть меньше текущей даты",
        "startDateMoreThan30Days": "Дата начала не может превышать 30 дней от текущей даты"
      },
      "title": "Редактирование рассылки",
      "form": {
        "name": {
          "label": "Название рассылки",
          "placeholder": "Введите название рассылки"
        },
        "originator": {
          "label": "Отправитель",
          "placeholder": "Введите имя отправителя"
        },
        "period": {
          "title": "Период",
          "startDate": "Дата начала",
          "startTime": "Время начала"
        },
        "isLocalTime": "с учетом локального времени",
        "suspend": {
          "switch": "Приостанавливать рассылку",
          "endTime": "Приостанавливать в",
          "startTimeNextDay": "Возобновить на следующий день в"
        },
        "speed": "Скорость отправки",
        "speedUnits": "сообщ./мин",
        "gap": "Пауза между сообщениями",
        "timeout": "Время жизни сообщения",
        "rus": "по-русски",
        "channels": {
          "message": {
            "title": "Сообщение",
            "label": "Текст сообщения",
            "placeholder": "Введите сообщение"
          },
          "buttonName": {
            "label": "Надпись кнопки",
            "placeholder": "Введите надпись кнопки"
          },
          "buttonUrl": {
            "label": "Ссылка",
            "placeholder": "Введите ссылку"
          },
          "image": "Картинка",
          "noImage": "Файл не выбран"
        }
      },
      "submitButton": "Сохранить",
      "cancelButton": "Отменить",
      "successEdit": "Изменения сохранены"
    }
  },
  "batchCreate": {
    "title": "Отправка сообщений",
    "selectDraft": {
      "title": "Черновик сообщения",
      "empty": "Черновик не выбран",
      "button": "Выбрать черновик"
    },
    "selectDrawer": {
      "title": "Поиск по черновикам",
      "submit": "Выбрать черновик",
      "clear": "Очистить",
      "cancel": "Отменить",
      "closeText": "Закрыть поиск по черновикам"
    },
    "form": {
      "name": {
        "title": "Название рассылки",
        "placeholder": "Введите название рассылки",
        "description": "Если рассылка небольшая и будет длиться не более пяти минут с момента отправки данных на этой странице, то сообщения будут сохранены только в журнале. Название для такой рассылки не требуется."
      },
      "timeout": "Время жизни сообщения",
      "timeSeconds": "Время в секундах",
      "linkHttp": "Ссылка должна содержать http:// или https:// префикс",
      "linkRegex": "Ссылка должна содержать префикс .com .net .ru или другие ",
      "phonesSource": {
        "title": "Получатели",
        "select": {
          "label": "Телефоны",
          "options": {
            "commaSeparated": "Список телефонов, разделенных запятой",
            "lineSeparated": "Список телефонов в столбик, один телефон на строку",
            "lineSeparatedWithMessages": "Список телефон-сообщение, по одной паре на строку",
            "lineSeparatedFile": "Список из файла",
            "lineSeparatedFileWithMessages": "Файл со списком телефон-сообщение",
            "lineSeparatedWithParamsFile": "Файл со списком параметров сообщений"
          }
        },
        "errorTable": {
          "rowNumber": "#",
          "errorNumber": "ошибка",
          "row": "строка",
          "added": "добавлено",
          "errors": "ошибок",
          "allRows": "строк",
          "size": "размер",
          "downloadAllErrors": "скачать все {count} ошибок в файл | скачать {count} ошибку в файл  | скачать все {count} ошибки в файл | скачать все {count} ошибок в файл",
          "tableErrorHead": "найдены ошибки в файле",
          "errorCodes": {
            "100": "Некорректная строка",
            "101": "Некорректные заголовки столбцов",
            "200": "Пустое сообщение",
            "300": "Номер телефона слишком короткий",
            "301": "Номер телефона слишком длинный",
            "302": "Некорректная длина номера телефона",
            "303": "Некорректный телефон",
            "400": "Некорректное число параметров"
          }
        },
        "blocks": {
          "commaSeparated": {
            "label": "Ваш список",
            "placeholder": "Введите список телефонов, разделенных запятой"
          },
          "lineSeparated": {
            "label": "Ваш список",
            "placeholder": "Введите список телефонов в столбик, один телефон на строку"
          },
          "lineSeparatedWithMessages": {
            "label": "Ваш список",
            "placeholder": "Введите список телефон-сообщение (через пробел), по одной паре на строку"
          },
          "selectedGroups": {
            "label": "Выбранные списки для рассылок"
          },
          "selectedPhones": {
            "label": "Выбранные списки для рассылок",
            "excluded": "Исключено"
          },
          "lineSeparatedFile": {
            "label": "",
            "charset": {
              "label": "Кодировка"
            },
            "file": {
              "description": "Каждая запись на новой строке",
              "loading": "Идёт загрузка файла"
            }
          },
          "lineSeparatedWithMessagesFile": {
            "label": "",
            "charset": {
              "label": "Кодировка"
            },
            "file": {
              "description": "Каждая запись на новой строке",
              "loading": "Идёт загрузка файла"
            }
          },
          "lineSeparatedWithParamsFile": {
            "label": "",
            "charset": {
              "label": "Кодировка"
            },
            "file": {
              "description": "Каждая запись на новой строке",
              "loading": "Идёт загрузка файла"
            }
          }
        },
        "validation": {
          "textarea": "Список телефонов",
          "file": {
            "required": "Файл обязателен",
            "loading": "Дождитесь загрузки файла"
          }
        },
        "blackList": {
          "label": "Черный список",
          "description": "На указанные номера рассылка производиться не будет",
          "defaultOptionLabel": "Не выбран",
          "noSelected": "Выберете список"
        },
        "ignorePhoneFormat": "Не приостанавливать рассылку, если среди номеров встретится некорректный"
      },
      "message": {
        "title": "Сообщение",
        "isSameMessages": "Одинаковые сообщения по всем каналам",
        "description": "Выберите маршрут рассылки и отправителя",
        "route": {
          "label": "Маршрут",
          "description": "Если сообщение не доставлено, можем отправить через другой сервис"
        },
        "originator": {
          "label": "Отправитель",
          "description": "Максимум 11 символов, используйте латинские буквы и цифры",
          "validation": "Допустимы латинские буквы, цифры, пробелы и символы: !.&*?,\"':_-"
        },
        "channels": {
          "common": {
            "textarea": {
              "label": "Текст сообщения",
              "placeholder": "Введите текст сообщения"
            }
          },
          "sms": {
            "rus": "по-русски"
          },
          "viber": {
            "image": "Viber картинка",
            "button": "Viber кнопка",
            "description": "Надпись на кнопке и ссылка",
            "buttonName": {
              "label": "Надпись на кнопке",
              "placeholder": "Введите надпись кнопки"
            },
            "buttonUrl": {
              "label": "Ссылка",
              "placeholder": "Введите ссылку для перехода при нажатии"
            }
          },
          "whatsapp": {
            "image": "Whatsapp картинка"
          }
        },
        "file": {
          "title": "Перетащите сюда файл или нажмите, чтобы выбрать",
          "acceptFiles": "Файл в формате: {types};",
          "additional": "Размер не больше {size} кб",
          "delete": "Удалить",
          "errors": {
            "WrongFormat": "Неверный формат файла",
            "FileTooLarge": "Файл слишком большой"
          }
        }
      },
      "period": {
        "title": "Период",
        "switch": "Отправлять сразу",
        "description": "Если не все сообщения успеют уйти к указанному времени, рассылка будет автоматически остановлена",
        "startDate": "Дата начала",
        "startTime": "Время начала",
        "timeout": "Время жизни сообщения",
        "stopTime": "Приостановить рассылку",
        "endTime": "Приостановить рассылку в",
        "startTimeNextDay": "Возобновить на следующий день в",
        "localtime": "Корректировать время с учетом локального времени абонента"
      },
      "sendingSpeed": {
        "title": "Скорость рассылки",
        "switch": "Отправлять равномерно",
        "speed": "Скорость отправки",
        "gap": "Пауза между смс",
        "gapTime": "Равномерно рассылать до"
      },
      "continueButton": "Продолжить",
      "testButton": "Протестировать рассылку"
    },
    "hint": {
      "phonesSource": {
        "commaSeparated": "Через запятую, в международном формате, например:<br/>{phone1}, {phone2}<br/><br/><b>Подходит для всех маршрутов</b>",
        "commaSeparatedSelfRegistered": {
          "text": "По умолчанию для тесторования доступен только номер, с которого производилась регистрация. <br/><br/> Чтобы добавить номер телефона в список для тестирования SMS рaccылки, отправьте на номер <b>9395</b> сообщение с текстом «<b>optin {login}</b>».<br/><br/> Сообщение на номер 9395 бесплатное.<br/><br/>",
          "buttonInfo": "Посмотреть зачем это нужно и прочесть полную инструкцию",
          "drawer": {
            "title": "Зачем нужно подтверждать номер?",
            "close": "Закрыть",
            "text": "Подтверждение каждого номера телефона в демонстрационной версии необходима для выполнения требований законов о связи и закона о рекламе. В полной версии вам не будет требоваться подтверждать каждый номер для добавления в список рассылки. <br/><br/> <b>Инструкция для добавления номера</b><br/> <ol> <li>Отправьте на номер <b>9395</b> сообщение <b>optin&nbsp;{login}</b> </li> <li>В случае успешного подтверждения вам придет сообщение от отправителя SMS Traffic с подтверждением</li> </ol>"
          }
        },
        "lineSeparated": "В столбик, один телефон на строчку, в международном формате, например:<br/>{phone1}<br/>{phone2}<br/><br/><b>Подходит для всех маршрутов</b>",
        "lineSeparatedWithMessages": "В столбик, в формате \"телефон, пробел, текст сообщения\", по одному сообщению на строку, например:<br/>{phone1} текст сообщения 1<br/>{phone2} текст сообщения 2<br/><br/><b>Подходит только для  маршрута СМС</b>",
        "selectedGroups": "Выбранные списки для рассылок",
        "selectedPhones": "Выбранные списки для рассылок",
        "lineSeparatedFile": "В столбик, один телефон на строчку, в международном формате, например:<br/>{phone1}<br/>{phone2}<br/><br/><b>Подходит для всех маршрутов</b>",
        "lineSeparatedWithMessagesFile": "В столбик, в формате \"телефон, пробел, текст сообщения\", по одному сообщению на строку, например:<br/>{phone1} текст сообщения 1<br/>{phone2} текст сообщения 2<br/><br/><b>Подходит только для  маршрута СМС</b>",
        "lineSeparatedWithParamsFile": "В столбик, в формате <br/><b>имя параметра1;имя параметра2;phone;имя параметра3;...</b>...<br/>параметр1;параметр2;номер телефона;параметр3;...<br/><br/>По одному набору параметров для одного номера телефона на строку, например, для сообщения с параметрами:<br/>\"Здравствуйте, [[name]]! Напоминаем, что у Вас действует скидка [[percent]]%\"<br/><br/> Файл с параметрами должен иметь вид: <br/>phone;percent;name<br/>{phone1};10;Андрей<br/>{phone2};15;Мария"
      },
      "message": "Для каждого канала есть свои ограничения и особенности при отправке сообщений. Также, если выбран вариант загрузки получателей рассылки в виде файла с параметрами, то можно использовать параметры в тексте для персонализации сообщения.<br/><br/><b>Например:</b><br/>Здравствуйте, [[name]]! <br/>Напоминаем, что у Вас действует скидка [[percent]]%"
    },
    "successMessage": {
      "count": "Сообщения для { count } абонентов | Сообщения для { count } абонента | Сообщения для { count } абонентов | Сообщения для { count } абонентов",
      "message": "поставлено в очередь на отправку. Приблизительное время окончания рассылки"
    },
    "confirmationDrawer": {
      "close": "Закрыть",
      "title": "Информация о рассылке",
      "subtitle": "Проверьте введенные вами данные",
      "messageType": "Тип рассылки",
      "rus": "По-русски",
      "originator": "Отправитель",
      "channel": "Канал",
      "messageCount": "Кол-во сообщений",
      "message": "Текст сообщения",
      "batchName": "Список рассылки",
      "blackList": "Черный список",
      "phonesCount": "Кол-во телефонов",
      "startDate": "Дата отправки",
      "startTime": "Время отправки",
      "buttonText": "Надпись на кнопке",
      "buttonUrl": "Ссылка на кнопке",
      "buttonImage": "Картинка",
      "speed": "Скорость",
      "submit": "Отправить",
      "cancel": "Отменить",
      "inRussian": {
        "yes": "Да",
        "no": "Нет"
      }
    },
    "shortenUrl": {
      "title": "Включено сокращение ссылок",
      "subtitle": "Сообщение будет выглядеть так:"
    },
    "testDrawer": {
      "title": "Тестовая рассылка",
      "close": "Закрыть",
      "testPhones": "Номера телефонов",
      "hint": "Укажите номера через запятую, в международном формате",
      "resultTitle": "Результаты отправки",
      "totalPhones": "Сообщений отправлено",
      "submit": "Отправить",
      "cancel": "Отменить"
    },
    "preliminaryEvaluation": {
      "credits": "сообщениe | сообщение | сообщения | сообщений",
      "init": {
        "title": "Предварительная оценка",
        "subTitle": "Это может занять продолжительное время",
        "button": "Рассчитать"
      },
      "loading": {
        "title": "Рассчитываем стоимость",
        "subTitle": "Это может занять продолжительное время",
        "button": "Отменить"
      },
      "success": {
        "subTitle": "Предварительная стоимость рассылки",
        "button": "Рассчитать снова"
      }
    }
  },
  "dateTimeForm": {
    "date": "Дата",
    "time": "Время",
    "hours": "Час",
    "minutes": "Минута",
    "year": "Год",
    "month": "Месяц",
    "day": "День"
  },
  "settings": {
    "title": "Настройки",
    "tabs": {
      "general": "Общие настройки",
      "security": "Настройки безопасности",
      "smtp": "SMTP вариант 1",
      "password": "Смена пароля"
    },
    "general": {
      "successSaved": "Изменения сохранены",
      "emailAddresses": "Адреса для уведомлений",
      "emailNote": "Список адресов, на которые будут отправляться уведомления о необходимости изменения договоров и прочих сопутствующих документов. Адреса вводятся через пробел.",
      "emailFinance": "Список адресов, на которые будут отправляться уведомления об изменениях тарифов, о состоянии баланса аккаунта, превышении лимитов. Адреса вводятся через пробел.",
      "emailTech": "Список адресов для получения уведомлений о технических работах на платформе, о технических изменениях и работах на стороне операторов. Адреса вводятся через пробел.",
      "technicalEmail": "Технический адрес электронной почты",
      "financialEmail": "Финансовый адрес электронной почты",
      "administrativeEmail": "Административный адрес электронной почты",
      "defaultOriginator": "Имя отправителя по умолчанию",
      "defaultOriginatorPlaceholder": "Это имя будет по умолчанию указано во всех рассылках, которые вы отправляете через наш сервис, если вы не задали имя отправителя при отправке.",
      "alerts": "Оповещения",
      "balanceAlertMinValueLabel": "Оповещение о заканчивающихся средствах",
      "balanceAlertMinValueNote": "Если до исчерпания баланса останется менее указанного количества, на финансовый адрес электронной почты будет отправлено уведомление",
      "balanceRemindDaysLabel": "Оповещение о балансе",
      "balanceRemindDaysNote": "Укажите периодичность в днях, с которой будут приходить уведомления о балансе на финансовый адрес электронной почты",
      "sendMessages": "Отправка сообщений",
      "rusByDefaultDisabledLabel": "Не устанавливать флаг «По-русски»",
      "rusByDefaultDisabledSubtitle": "При совершении рассылок, в форме отправки по умолчанию включена опция «по-русски»",
      "rusByDefaultDisabledNote": "Вы можете отключить автоматическую установку этого флага",
      "blackList": "Черный список",
      "blackListNote": "На номера из выбранного списка отправка сообщений осуществляться не будет. При использовании протоколов HTTP, SOAP черный список применяется автоматически.",
      "smtp2Label": "Разрешить отправку по протоколам HTTP, SOAP, SMTP v2",
      "smtp2Note": "Использовать универсальные протоколы для отправки сообщений",
      "saveSettingsButton": "Сохранить изменения",
      "validation": {
        "email": "Не являются правильными email адресами: {emails}"
      },
      "timezone": {
        "title": "Часовой пояс учетной записи",
        "subtitle": {
          "text": "Часовой пояс других учетных записей можно изменить в настройках раздела",
          "link": "Учетные записи и департаменты"
        },
        "country": {
          "label": "Страна",
          "placeholder": "Выберите страну"
        },
        "timezone": {
          "label": "Часовой пояс",
          "placeholder": "Выберите часовой пояс",
          "noOptionsText": "Для отображения списка часовых поясов выберите страну"
        }
      },
      "moNumbers": {
        "title": "Входящие номера",
        "table": {
          "number": "Телефон",
          "monthPrice": "Аренда в месяц",
          "prefix": "Префикс",
          "email": "Email",
          "script": "Обработчик",
          "editButton": "Редактировать номер"
        },
        "editForm": {
          "closeEditForm": "Закрыть форму редактирования",
          "title": "Редактирование входящего номера",
          "number": "Номер",
          "prefix": "Префикс",
          "submitButton": "Сохранить",
          "group": "Список рассылки для дополнения",
          "groupZeroValue": "Без списка",
          "department": "Входящие сообщения передавать аккаунту:",
          "departmentZeroValue": "Не передавать",
          "script": {
            "label": "Обработчик сообщения",
            "options": {
              "http": "HTTP Скрипт",
              "smpp": "SMPP",
              "email": "Email"
            }
          },
          "scriptUrl": "HTTP Скрипт",
          "httpMethod": {
            "label": "Метод отправки",
            "options": {
              "get": "GET",
              "post": "POST"
            }
          },
          "email": "Email"
        }
      }
    },
    "security": {
      "form": {
        "title": "Адреса для уведомлений",
        "label": "Электронная почта для отправки отчётов о рассылках департаментов",
        "note": "На данные адреса будут отправляться уведомления о работах департаментов. Адреса вводятся через запятую.",
        "submitButton": "Сохранить изменения",
        "success": "Изменения сохранены"
      },
      "title": "Сессии",
      "closeDrawer": "Закрыть",
      "deleteSelectedSessions": "Удалить выбранные сессии",
      "deleteSessionButton": "Удалить",
      "cancelDeleteSessionButton": "Отменить",
      "deleteSessionsTitle": "Удаление сессий",
      "deleteQuestion": {
        "all": "Вы действительно хотите удалить все сессии?",
        "include": "Вы действительно хотите удалить эти сессии?",
        "exclude": "Вы действительно хотите удалить все сессии, кроме:"
      },
      "table": {
        "createdAt": "Дата создания",
        "ip": "IP",
        "login": "Имя учетной записи"
      }
    },
    "smtp": {
      "smtp1": {
        "title": "SMTP вариант 1",
        "allowSendingEmail": "Разрешить отправку SMS сообщений по протоколу SMTP v.1",
        "transliteration": {
          "label": "Транслитерация смс",
          "additionalText": "Русский шрифт будет отображаться некорректно на телефонах которые его не поддерживают."
        },
        "hint": "Если разрешена отправка через e-mail, необходимо установить защиту от несанкционированного использования вашего аккаунта. Возможна установка защиты по заголовкам SMTP или вводом ограниченного списка телефонов, на которые можно отправлять сообщения."
      },
      "protectByHeaders": {
        "title": "Защита по заголовкам SMTP сервера",
        "protectedHeaders": "Защита включена для: ",
        "smtpAllowedFrom": "Защита включена по домену в заголовке для: ",
        "badHeadersAlertLabel": "Оповещать о попытках отправить сообщение с некорректным заголовком. Оповещение будет отправлено на технические адреса для уведомлений, указанные в разделе «Общие настройки»",
        "suggest": {
          "firstPart": "Для установки защиты по заголовкам SMTP сервера необходимо отправить тестовое email сообщение на",
          "email": "tech.support{'@'}smstraffic.ru",
          "secondPart": "через тот самый SMTP сервер, через который будут отправляться рабочие сообщения. В теме письма не забудьте указать ваш логин и изложить вашу просьбу."
        }
      },
      "viaEmail": {
        "title": "Отправка по списку телефонов",
        "label": "Разрешенные номера",
        "additionalText": "Номера телефонов, на которые можно отправлять сообщения по протоколу SMTP v.1",
        "allNumbers": "Все номера",
        "fromList": "Номера из списка",
        "numbersLabel": "Ваш список"
      },
      "saveButton": "Сохранить изменения",
      "successSaved": "Изменения сохранены"
    },
    "password": {
      "title": "Пароль",
      "expiredPasswordTitle": "Смена пароля",
      "expiredPasswordSubTitle": "Для продолжения пользования личным кабинетом необходимо сменить пароль",
      "form": {
        "oldPassword": "Старый пароль",
        "password": "Новый пароль",
        "passwordConfirmation": "Повтор пароля",
        "changeButton": "Сохранить изменения",
        "success": "Изменения сохранены"
      }
    }
  },
  "eventLogs": {
    "title": "Журнал событий",
    "column": {
      "login": "Логин",
      "eventDesc": "Событие",
      "ip": "IP",
      "recordDate": "Дата события"
    },
    "filters": {
      "date": "Дата",
      "user": "Учетная запись",
      "mainAccount": "Основная учетная запись",
      "users": "Учетные записи",
      "departmentUsers": "Пользователи департаментов"
    },
    "download": "Скачать события в XLSX"
  },
  "originators": {
    "title": "Список имен отправителя",
    "column": {
      "originator": "Имя отправителя",
      "operator": "Оператор",
      "status": "Статус",
      "addDate": "Дата создания",
      "approveDate": "Дата подтверждения",
      "deleteDate": "Дата удаления",
      "delete": "Удалить"
    },
    "status": {
      "New": "Новое",
      "Failed": "Отказано",
      "Deleted": "Удалено",
      "Approved": "Подтверждено",
      "ToDelete": "Удаляется оператором",
      "WaitConfirm": "Ждет подтверждения",
      "WaitDeleted": "Ждет удаления"
    },
    "createOriginator": {
      "close": "Закрыть",
      "title": "Добавить имя",
      "additionMethod": {
        "label": "Способ добавления",
        "options": {
          "form": "Форма",
          "file": "Файл"
        }
      },
      "controls": {
        "originator": "Имя",
        "companyName": "Наименование юр. лица",
        "inn": "ИНН",
        "operatorId": "Оператор",
        "operatorPlaceholder": "Выберите оператора",
        "file": {
          "name": "Файл",
          "title": "Перетащите сюда файл или нажмите, чтобы выбрать",
          "csv": "CSV: Имя отправителя; Наименование юр. лица; ИНН",
          "xlsx": "XLSX: Имя отправителя {'|'} Наименование юр. лица {'|'} ИНН"
        }
      },
      "submit": "Добавить",
      "success": "Успешно добавлено"
    },
    "filters": {
      "originator": "Имя отправителя",
      "operatorId": "Оператор",
      "status": "Статус"
    },
    "deleteConfirmation": {
      "title": "Вы действительно хотите удалить имя отправителя «{ originatorName }» у оператора «{ operatorName }»?",
      "acceptButton": "Удалить",
      "cancelButton": "Отменить",
      "success": "Имя успешно удалено"
    }
  },
  "techDocs": {
    "title": "API и документация",
    "fileNames": {
      "api": "API СМС рассылок",
      "sd_api": "API Smart Delivery рассылок",
      "api_ritorica": "API СМС рассылок",
      "sd_api_ritorica": "API Smart Delivery рассылок",
      "lk_documentation": "Руководство по работе с личным кабинетом",
      "sms_bulk": "Способы отправки СМС-сообщений",
      "sms_employee": "Описание сервиса SMS-сотрудник",
      "voice_api": "Протокол автоматических голосовых рассылок",
      "voice_documentation": "Инструкция по голосовым рассылкам",
      "code_sample": "Примеры кода",
      "imsi_api": "API сервиса IMSI (Версия 1.2)",
      "api_by": "API СМС рассылок",
      "sd_api_by": "API Smart Delivery рассылок",
      "lk_documentation_by": "Руководство по работе с личным кабинетом",
      "sms_bulk_by": "Способы отправки СМС-сообщений",
      "sms_employee_by": "Описание сервиса SMS-сотрудник",
      "voice_api_by": "Протокол автоматических голосовых рассылок",
      "voice_documentation_by": "Инструкция по голосовым рассылкам",
      "code_sample_by": "Примеры кода",
      "imsi_api_by": "API сервиса IMSI (Версия 1.2)",
      "api_kz": "API СМС рассылок",
      "sd_api_kz": "API Smart Delivery рассылок",
      "lk_documentation_kz": "Руководство по работе с личным кабинетом",
      "sms_bulk_kz": "Способы отправки СМС-сообщений",
      "sms_employee_kz": "Описание сервиса SMS-сотрудник",
      "voice_api_kz": "Протокол автоматических голосовых рассылок",
      "voice_documentation_kz": "Инструкция по голосовым рассылкам",
      "code_sample_kz": "Примеры кода",
      "imsi_api_kz": "API сервиса IMSI (Версия 1.2)",
      "api_uz": "API СМС рассылок",
      "sd_api_uz": "API Smart Delivery рассылок",
      "lk_documentation_uz": "Руководство по работе с личным кабинетом",
      "sms_bulk_uz": "Способы отправки СМС-сообщений",
      "sms_employee_uz": "Описание сервиса SMS-сотрудник",
      "voice_api_uz": "Протокол автоматических голосовых рассылок",
      "voice_documentation_uz": "Инструкция по голосовым рассылкам",
      "code_sample_uz": "Примеры кода",
      "imsi_api_uz": "API сервиса IMSI (Версия 1.2)"
    },
    "columns": {
      "documentType": "Название файла",
      "pdf": "pdf",
      "doc": "word",
      "zip": "zip"
    },
    "download": "Скачать",
    "size": {
      "mb": "Mb",
      "kb": "Kb"
    }
  },
  "accountingInfo": {
    "title": "Учетная карточка",
    "organization": {
      "title": "Организация",
      "name": "Название",
      "director": "Руководитель"
    },
    "contacts": {
      "title": "Контактная информация",
      "phone": "Телефон",
      "addressJure": "Юридический адрес",
      "fax": "Факс",
      "addressFact": "Фактический адрес",
      "addressPost": "Почтовый адрес"
    },
    "requisites": {
      "title": "Реквизиты",
      "bankName": "Банк",
      "rSchet": "Расчетный счет",
      "korSchet": "Корреспондентский счет",
      "inn": "ИНН",
      "bik": "БИК",
      "kpp": "КПП"
    },
    "accountingPeriod": {
      "title": "Счета-фактуры выставляются { period }",
      "mn": "ежемесячно",
      "kv": "ежеквартально",
      "h": "раз в полугодие",
      "y": "раз в год",
      "no": "после оплаты"
    }
  },
  "accounting": {
    "title": "Счета, счета фактуры, акты",
    "printButton": "Печать выбранных",
    "successSendData": "Данные отправлены в ЭДО",
    "edoTitle": "ЭДО",
    "send": "Отправить",
    "column": {
      "enterDate": "Дата",
      "recordId": "Номер",
      "sumSNds": "Сумма",
      "viewTypes": "Документы",
      "documentStatus": "Статус"
    },
    "documentStatus": {
      "paid": "Оплачено",
      "not_paid": "Не оплачено",
      "sentToEdm": "Отправлен в ЭДО",
      "numberDocument": "Номер документа",
      "organization": "Организация",
      "department": "Департамент",
      "docStatus": {
        "-1": "Сформирован, не загружен",
        "0": "Загружен в CЭД",
        "2": "Загружен в CЭД",
        "3": "Отправлен",
        "4": "Получен клиентом",
        "6": "Ошибка при отправке",
        "9": "Получен клиентом",
        "7": "Подписан клиентом",
        "19": "Отозван",
        "20": "Удален",
        "22": "Аннулирован",
        "23": "Ожидает подписания клиентом",
        "27": "Ожидает аннулирования",
        "28": "Ошибка запроса статуса",
        "29": "Неизвестный статус",
        "null": "Не сформирован"
      }
    },
    "viewTypes": {
      "schet": "Счёт",
      "act": "Акт",
      "factura": "Фактура",
      "all": "Все"
    },
    "filters": {
      "date": "Дата",
      "documentStatus": "Статус"
    }
  },
  "transactionsHistory": {
    "title": "История платежей",
    "columns": {
      "transactionDate": "Дата",
      "transactionType": "Тип транзакции",
      "deltaRur": "Сумма",
      "deltaUsd": "Изменение счета",
      "deltaCredits": "Количество сообщений",
      "notes": "Комментарий"
    },
    "transactionTypes": {
      "deposit": "Депозит",
      "payment": "Платеж",
      "testing": "Тестирование",
      "testing over": "Окончание тестирования",
      "refund": "Возврат",
      "conversion": "Конверсия внутри аккаунта",
      "transfer": "Перевод с/на другой аккаунт",
      "fine": "Вычет",
      "tariff change": "Изменение тарифа",
      "correction": "Исправление ошибки начисления",
      "refund_advance": "Возврат аванса",
      "netting": "Взаимозачет",
      "abon_plata": "Абон. плата",
      "other": "Другое",
      "cyberplat payment": "Платеж Киберплат"
    },
    "closeText": "Закрыть",
    "closeButton": "Понятно",
    "transactionsInfo": {
      "title": "Информация о платеже от { date }"
    },
    "filters": {
      "date": "Дата",
      "transactionType": "Тип транзакции"
    }
  },
  "issueBill": {
    "title": "Выписать счет",
    "description": "Минимум { amount } { currency } (Включая НДС)",
    "amount": {
      "placeholder": "Введите сумму",
      "label": "Сумма"
    },
    "submit": "Выписать",
    "validate": {
      "amount": "Введите, пожалуйста, сумму от { amount } { currency }"
    },
    "success": "Счет на сумму { amount } { currency } выписан успешно"
  },
  "dashboard": {
    "greeting": "Здравствуйте",
    "createBatch": {
      "title": "Создавайте рассылку",
      "description": "Отправляйте рассылки любым доступным вам каналом",
      "link": "Создать рассылку"
    },
    "createGroup": {
      "title": "Создавайте список",
      "description": "Создайте свой список для рассылки, чтобы ускорить процесс отправки сообщений",
      "link": "Создать список"
    },
    "selfRegisteredUserScreen": {
      "greeting": {
        "title": "Добро пожаловать!",
        "description": [
          "В демонстрационной версии личного кабинета вы можете ознакомиться с основными возможностями в ограниченном режиме и отправить 30 тестовых SMS на подтверждённые вами номера телефонов.",
          "Нам пришлось ограничить возможности работы со списками рассылок, гибкой системой ролей, API, Smart Delivery и ограничить список номеров для отправки с целью выполнения требований законов о связи и закона о рекламе."
        ]
      },
      "accessing": {
        "title": "Доступ к полной версии",
        "description": "В полной версии вам будет доступен весь функционал личного кабинета и не будет требоваться подтверждать каждый номер для добавления в список.",
        "button": "Получить доступ"
      },
      "drawer": {
        "content": "Для получения доступа к полной версии личного кабинета необходимо прислать нам на почту <a href='mailto:{ email }'>{ email }</a> следующие данные: <ol> <li>Ваш логин в нашей системе</li> <li>Реквизиты вашего юридического лица</li> <li>Необходимое имя отправителя, от лица которого вы планируете рассылать сообщения</li> <li>Подтверждающие документы на имя отправителя (например, это свидетельство регистрации торгового знака или свидетельство о регистрации доменного имени)</li></ol>",
        "close": "Закрыть"
      }
    },
    "contacts": {
      "title": "Контакты",
      "manager": "Ваш менеджер по сопровождению",
      "supportEmail": "tech.support",
      "supportText": "Тех.поддержка",
      "phone": "Телефон",
      "email": "Email"
    },
    "sessions": {
      "title": "Сессии",
      "link": "Все сессии"
    },
    "historyNumbers": {
      "title": "История в цифрах",
      "thisMonth": "Всего { type } с { from } по { to }",
      "lastMonth": "Всего { type } с { from } по { to }",
      "documents": "Счета за { month } на сумму { sum } { currency }, статус",
      "sms": "SMS",
      "messages": "Сообщений"
    },
    "batches": {
      "title": "Рассылки на подтверждение",
      "link": "Все рассылки"
    }
  },
  "tasksSidebar": {
    "open": "Открыть уведомления",
    "close": "Закрыть",
    "title": "Уведомления",
    "empty": "У вас нет уведомлений",
    "delete": "Удалить",
    "deleteSuccess": "Уведомление успешно удалено",
    "taskType": {
      "group_load": "Загрузка файла в список для рассылок"
    },
    "status": {
      "new": "В очереди",
      "processed": "В процессе",
      "paused": "Приостановлено",
      "canceled": "Отменено",
      "finished": "Выполнено",
      "error": "Ошибка"
    },
    "taskInfo": {
      "groupName": "Список",
      "totalRows": "Всего строк в списке",
      "affectedRows": "Обработано строк",
      "insertedRows": "Вставлено строк",
      "error": "Ошибка",
      "empty": "Нет подробной информации",
      "createdAt": "Дата создания",
      "createdAtTime": "Время создания"
    }
  },
  "phoneConfirmation": {
    "title": "Подтверждение номера телефона",
    "note": "Укажите ваш телефонный номер и мы отправим вам код подтверждения для продолжения регистрации",
    "button": "Продолжить"
  },
  "passwordConfirmation": {
    "title": "Подтверждение регистрации",
    "password": "Пароль",
    "passwordRepeated": "Повтор пароля",
    "button": "Завершить регистрацию"
  },
  "carousel": {
    "slide-1": "Эффективное использование смс-рассылок",
    "slide-2": "Push-уведомления",
    "slide-3": "Рассылка Вконтакте и Одноклассники",
    "slide-4": "Голосовые рассылки, поддерживаемые всеми мобильными устройствами",
    "slide-5": "WhatsApp Business API с возможностью обратной связи от клиентов",
    "slide-6": "Email рассылки для бизнеса",
    "slide-7": "Звонки в Viber Out для бизнеса"
  },
  "extra": {
    "goToOldFront": "В старый ЛК",
    "ReasenGoToTextArea": "Причина перехода",
    "theReasenGoToOldFront": "Пожалуйста, укажите причину перехода на старую версию личного кабинета, это поможет нам сделать сервис лучше."
  }
}
