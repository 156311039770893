/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface ApiResult {
  /**
   * Коды ошибок:
   *   * `0` - Success response <br>
   *   * `102` - Доступ запрещен <br>
   *   * `108` - Отсутствует значение в обязательном поле. Имя поля находится в поле description <br>
   *   * `109` - Wrong login or password with captcha(Ответ на случай ввода неправильного логина или пароля, после получения которого следующий запрос обязательно должен содержать код captcha) <br>
   *   * `110` - Wrong login or password <br>
   *   * `111` - Переданный пароль слишком не безопасный <br>
   *   * `113` - Сессия проэкспайрена. Нужно вернуть пользователя на шаг назад <br>
   *   * `114` - Переданный секретный код не прошел валидацию <br>
   *   * `115` - Превышено максимальное число попыток ввода кода, нужно порекомендовать пользователю отправить новый <br>
   *   * `116` - Время жизни кода закончилось, нужно порекомендовать пользователю отправить новый <br>
   *   * `117` - Превышено максимальное количество отправки кодов на номер телефона пользователя <br>
   *   * `118` - У пользователя отключена возможность восстановления пароля. Надо отправить его к менеджеру/саппорт <br>
   *   * `119` - Не передал секретный код для восстановления пароля <br>
   *   * `120` - Пароли в форме изменения пароля не совпадают <br>
   *   * `123` - Email переданный в запросе уже зарегистрирован у нас на платформе <br>
   *   * `125` - Секретный код для подтверждения регистрации не найден <br>
   *   * `126` - Данная ошибка возникает, когда пользователь находится на этапе регистрации который ему не разрешен. Например, он пытается привязать телефон, но номер уже привязан. В этом случае отправляет клиента на регистрацию по новой. <br>
   *   * `127` - Ошибка относится к спискам для рассылок, возникает, когда клиент пытается создать список с именем которое уже имеется в списках. Например, установлена опция "Список виден всем департаментам" и "Список не виден основному департаменту" <br>
   *   * `128` - Ошибка возникает, когда клиент пытается скрыть список для рассылок, но это противоречит логике. <br>
   *   * `129` - Ошибка возникает, когда клиент пытается создать абонента в списке для рассылок, но он уже создан ранее. <br>
   *   * `130` - Ошибка возникает, когда клиент пытается создать задание на создание отчета для выгрузки сообщений, но такой отчет уже был сформирован. <br>
   *   * `131` - Ошибка возникает, когда клиент устанавливает новый пароль, который совпадает с одним из последних пяти паролей. <br>
   *   * `132` - Ошибка возникает, когда происходит попытка задать неподдерживаемый статус объекту или отредактировать объект в неподдерживаемом статусе. <br>
   *   * `133` - Передан номер телефона в неподдерживаемом формате. <br>
   *   * `134` - Под заданный фильтр не попало ни одно сообщение. <br>
   *   * `135` - Старый пароль не совпадает с введенным. <br>
   *   * `138` - Некорректная captcha. <br>
   *   * `139` - Пустой файл. <br>
   *   * `140` - Ошибка возникает при попытке отредактировать поля батча в статусе отличном от new/paused/premoderate. <br>
   *   * `141` - Ошибка возникает при попытке залогиниться без включенной 2fa. <br>
   *   * `404` - Объект или сущность не найдена <br>
   *   * `500` - Внутренняя ошибка <br>
   *   * `501` - accessToken просрочен, нужно выполнить метод renew <br>
   *   * `502` - Некорректный запрос <br>
   *   * `503` - Неподдерживаемый формат файла <br>
   *   * `6xx` - ошибки при отправке сообщений <br>
   *   * `600` - Файл не найден <br>
   *   * `601` - Не заполнено поле phonesInput <br>
   *   * `602` - Неподдерживаемый тип phonesType <br>
   *   * `603` - Не указано имя файла <br>
   *   * `604` - Некорректный список каналов <br>
   *   * `605` - Некорректный smart delivery маршрут <br>
   *   * `606` - Указано слишком большое количество тестовых абонентов. <br>
   *   * `607` - Предварительный расчет стоимости не поддерживается для выбранных каналов. <br>
   *   * `608` - Поле channels запрещено. <br>
   *   * `609` - Поле channels обязательно. <br>
   *   * `610` - Аналогичный батч уже был создан, проверка за последние 30 минут
   *
   */
  code:
    | 0
    | 102
    | 108
    | 109
    | 110
    | 111
    | 113
    | 114
    | 115
    | 116
    | 117
    | 118
    | 119
    | 120
    | 123
    | 125
    | 126
    | 127
    | 128
    | 129
    | 130
    | 131
    | 132
    | 133
    | 134
    | 135
    | 138
    | 139
    | 140
    | 141
    | 404
    | 500
    | 501
    | 502
    | 503
    | 600
    | 601
    | 602
    | 603
    | 604
    | 605
    | 606
    | 607
    | 608
    | 609
    | 610

  /**
   * Текстовое описание ошибки. Полезно записать в лог. Принимает пустую строку, если запрос был успешно обработан.
   *
   */
  description: string

  /**
   * Unix timestamp указывает на время формирования ответа от сервера.
   *
   */
  timestamp: number
}

export type SuccessResult = ApiResult & { success?: boolean }

export type AffectedRowsResult = SuccessResult & { affectedRows?: number }

export type TrafficType = 0 | 1 | 2 | 3 | 4 | 5 | 6

/**
 * Объект описывающий страну
 */
export interface Country {
  /** Идентификатор страны. */
  countryId: number

  /** Короткое название оператора. */
  country: string

  /** Полноное наименование оператора. */
  countryName: string
}

/**
 * Объект описывающий регион абонента
 */
export interface Region {
  /** Идентификатор региона. */
  regionId: number

  /** Название региона. */
  region: string
}

/**
 * Объект описывающий оператора
 */
export interface Operator {
  /** Идентификатор оператора. */
  operatorId: number

  /** Имя оператора. */
  operator: string

  /** Объект описывающий страну */
  country: Country

  /** Объект описывающий регион абонента */
  region?: Region
}

/**
 * Номер телефона для двух-факторной аутентификации.
 * @example 79001112233
 */
export type TwoFactorAuthPhone = number

/**
 * Информация о текущей странице
 */
export interface Page {
  /**
   * Текущая страница.
   * @example 2
   */
  currentPage: number

  /**
   * Всего страниц.
   * @example 34
   */
  totalPages: number

  /**
   * Количество элементов на странице.
   * @example 30
   */
  itemsOnPage: number

  /**
   * Общее количество элементов на всех страницах.
   * @example 999
   */
  totalItems: number
}

/**
 * Политика сложности пароля:
 * `default` - Стандартная. Минимум 8 символов, латинские буквы обоих регистров и цифры. ^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*()_+])[a-zA-Z0-9!@#$%^&*()_+]{8,25}$
 * `weak` - Ослабленная. Минимум 8 символов. ^[a-zA-Z0-9!@#$%^&*()_+]{8,25}$
 * `complex` - Усиленная. Минимум 8 символов. ^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*()_+])[a-zA-Z0-9!@#$%^&*()_+]{8,25}$
 */
export enum PassStrengthPolitics {
  Default = 'default',
  Weak = 'weak',
  Complex = 'complex',
}

export interface Login {
  /** Логин клиента в системе СМС Трафик */
  login: string

  /** Пароль от учетной записи */
  password: string

  /**
   * Код пришедший от google recaptcha для валидации на бэке.
   * Параметр опционален, его нужно подставлять только после первой неудачной попытки входа и есть был получен ответ с кодом 109
   *
   * @pattern ^[A-Za-z0-9_-]+$
   */
  captchaCode?: string
}

export interface LoginPrivate {
  /** Логин клиента в системе СМС Трафик */
  login: string

  /** Уникальный ключ для поиска токена для доступа в личный кабинет клиента */
  tokenId: string
}

export type LoginResult = ApiResult & {
  accessToken: string
  refreshToken: string
  expiredIn: string
}

export type TwoFactorResult = ApiResult & {
  sessionId: string
  phone: string
  secreteCode?: string
}

export interface SecreteCode {
  /** Идентификатор сессии который вернулся в TwoFactorResult */
  sessionId: string

  /** Секретный код который был отправлен клиенту на номер телефона */
  secreteCode: string
}

export type RestorePasswordResult = ApiResult & { secreteCode?: string }

export type RegistrationResult = ApiResult & { confirmationCode?: string }

export type ConfirmationCodeResult = ApiResult & {
  phoneConfirmationCode?: string
}

export interface ProfileResult {
  /** @example 123 */
  clientId: number

  /** @example 8877 */
  departmentId: number

  /** @example 0 */
  departmentUserId: number

  /**
   * Логин основной (головной) учетной записи
   * @example ron
   */
  clientLogin: string

  /**
   * Полный логин авторизованного клиента
   * @example ron:account13018
   */
  fullLogin: string

  /**
   * Тип оплаты учетной записи:
   *  * `money` - Предоплатный
   *  * `credits` - Постоплатный
   *
   */
  accounting: 'money' | 'credits'

  /**
   * Баланс в валюте или кредитах (зависит от accounting)
   * @example 78100.52
   */
  balance: number

  /** Информация о валюте учетной записи */
  currencyInfo: {
    currency: Currencies
    currencyRate: number
    minimumSchetAmount: number
  }

  /**
   * Опция "учетные записи" у клиента
   * @example true
   */
  hasDepartments: boolean

  /**
   * Опция "SmartDelivery" (работа с НЕ смс каналами: viber, whatsapp и т.д.)
   * @example true
   */
  smartDelivery: boolean

  /**
   * Опция означает, что клиент зарегистрировался самостоятельно и еще не заключил договор.
   * @example false
   */
  selfRegistered: boolean

  /**
   * Опция означает, что у клиента настроена возможность работы с IMSI.
   * @example false
   */
  hasImsi: boolean

  /**
   * Имя отправителя для пользователя по умолчанию.
   * @example SMS Traffic
   */
  defaultOriginator: string

  /**
   * Опция означает, что клиент видит список имен отправителей в личном кабинете.
   * @example true
   */
  showOriginators: boolean

  /**
   * Опция означает, что у клиента подключен сервис "сокращатель ссылок".
   * @example true
   */
  shortenUrl: boolean

  /**
   * Не устанавливать флаг "по-русски"
   * @example false
   */
  rusByDefaultDisabled: boolean

  /** ID черного списка по умолчанию */
  defaultBlackListId?: number

  /** Разрешить ли клиенту просматривать тип трафика */
  showTrafficTypeIn: { messages: boolean; stats: boolean }

  /** Показывать отчеты по запросу */
  showReportsOnRequest: boolean

  /**
   * Политика сложности пароля:
   *  * `default` - Стандартная. Минимум 8 символов, латинские буквы обоих регистров и цифры. ^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*()_+])[a-zA-Z0-9!@#$%^&*()_+]{8,25}$
   *  * `weak` - Ослабленная. Минимум 8 символов. ^[a-zA-Z0-9!@#$%^&*()_+]{8,25}$
   *  * `complex` - Усиленная. Минимум 8 символов. ^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*()_+])[a-zA-Z0-9!@#$%^&*()_+]{8,25}$
   *
   */
  passStrengthPolitics: PassStrengthPolitics
  timezone: TimezoneResult
  roles?: number[]

  /** Контакты саппорт менеджера учетной записи */
  contacts: { email: string; fio: { ru: string; en: string }; phone: string }

  /**
   * Нужно ли принудительно поменять пароль пользователю
   * @example true
   */
  passwordExpired: boolean

  /** Список провайдеров электронного документооборота */
  edo: EdoType
}

export interface TimezoneResult {
  /**
   * Идентификатор таймзоны, который содержит в себе связку с регионом и страной.
   * @example 280
   */
  id: number

  /**
   * Название таймзоны.
   * @example Europe/Moscow
   */
  name: string

  /**
   * Сдвиг от UTC без учета летнего времени.
   * @example +03:00
   */
  utcOffset: string
  region: { ru: string; en: string }
  country: CountryResult
}

export interface GeneralSettings {
  /**
   * Технические email адреса (через запятую)
   * @example t1.user@domain.com, t2.user@domain.com
   */
  technicalEmail?: string

  /**
   * Финансовые email адреса (через запятую)
   * @example f1.user@domain.com, f2.user@domain.com
   */
  financialEmail?: string

  /**
   * Административные email адреса (через запятую)
   * @example a1.user@domain.com, a2.user@domain.com
   */
  administrativeEmail?: string

  /**
   * Имя отправителя по умолчанию
   * @pattern ^[A-Za-z0-9!\.!&*?,\"\':_\s-]+$
   * @example Originator
   */
  defaultOriginator?: string

  /**
   * Количество кредитов для алерта (Оповещать, если кредитов осталось менее)
   * @example 300
   */
  balanceAlertMinValue?: number

  /**
   * Оповещать о балансе каждые X дней.
   * @example 5
   */
  balanceRemindDays?: number

  /**
   * Транслитерировать CMC
   * @example true
   */
  transliteration?: boolean

  /**
   * Не устанавливать флаг "по-русски"
   * @example false
   */
  rusByDefaultDisabled?: boolean

  /**
   * Разрешенный способ отправки: E-mail (SMTP, вариант 1)
   * @example true
   */
  smtp1?: boolean

  /**
   * Разрешенный способ отправки: HTTP, SOAP, SMTP и E-mail (SMTP, вариант 2)
   * @example true
   */
  smtp2?: boolean

  /**
   * ID черного списка
   * @example 8
   */
  blackListId?: number

  /**
   * Период оповещений о попытках отправить сообщение с некорректным заголовком:
   *  * `never` - Никогда
   *  * `immediately` - Сразу
   *  * `daily` - Ежедневно
   *
   * @example immediately
   */
  smtpBadHeadersAlertPeriod?: 'never' | 'immediately' | 'daily'

  /**
   * Опция номера телефонов, на которые можно отправлять сообщения через e-mail:
   *  * `anyphone` - Любые номера
   *  * `list` - Только номера из списка
   *
   * @example anyphone
   */
  allowedPhones?: 'anyphone' | 'list'

  /**
   * Список номеров телефонов, на которые можно отправлять сообщения через e-mail (через запятую)
   * @example 79998887766, 79998887755
   */
  allowedPhonesList?: string

  /**
   * Идентификатор часового пояса
   * @example 280
   */
  timezoneId?: number
}

export interface CorpusersSettings {
  /**
   * Действия со списком номеров:
   *  * `add` - Добавить
   *  * `delete` - Удалить
   *
   * @example add
   */
  action: 'add' | 'delete'

  /**
   * Список номеров телефонов
   * @example ["79055717111","79152511173"]
   */
  phones: string[]
}

export interface MoNumberResponse {
  /**
   * Идентификатор входящего номера
   * @example 3544
   */
  id: number

  /**
   * Входящий номер
   * @example 79037976106
   */
  number: string

  /**
   * Аренда в месяц
   * @example 200
   */
  monthPrice: number

  /**
   * Префикс текста входящего сообщения
   * @example ^Не удалось
   */
  prefix: string

  /**
   * Email
   * @example
   */
  email: string

  /**
   * URL скрипта
   * @example http://internal.domain.com/test_url.php?message={{message}}
   */
  script: string

  /** Группа для входящих номеров (0 - группа не указана) */
  groupId: number

  /** Входящие сообщения передавать на аккаунт (0 - не передавать сообщения на аккаунт) */
  departmentId: number

  /** Метод для http(s) скрипта */
  method: 'get' | 'post' | null
}

export interface MoNumberOptions {
  /**
   * Протокол приема входящих сообщений:
   *  * `smpp` - SMPP
   *  * `http` - HTTP Скрипт
   *  * `email` - E-mail
   *
   * @example http
   */
  type?: 'smpp' | 'http' | 'email'

  /**
   * Email (обязательно для типа type = email)
   * @example my@domain.com
   */
  email?: string

  /** Опции для HTTP приемщика */
  http?: { method: 'get' | 'post'; script?: string }

  /** Группа для входящих номеров (0 - для удаления группы) */
  groupId?: number

  /** Входящие сообщения передавать на аккаунт (0 - не передавать сообщения на аккаунт) */
  departmentId?: number
}

export interface GeneralSettingsResult {
  /**
   * Технические email адреса (через запятую)
   * @example t1.user@domain.com, t2.user@domain.com
   */
  technicalEmail: string

  /**
   * Финансовые email адреса (через запятую)
   * @example f1.user@domain.com, f2.user@domain.com
   */
  financialEmail: string

  /**
   * Административные email адреса (через запятую)
   * @example a1.user@domain.com, a2.user@domain.com
   */
  administrativeEmail: string

  /**
   * Имя отправителя по умолчанию
   * @example Originator
   */
  defaultOriginator: string

  /**
   * Количество кредитов для алерта (Оповещать, если кредитов осталось менее)
   * @example 300
   */
  balanceAlertMinValue: number

  /**
   * Оповещать о балансе каждые X дней.
   * @example 5
   */
  balanceRemindDays: number

  /**
   * Транслитерировать CMC
   * @example true
   */
  transliteration?: boolean

  /**
   * Не устанавливать флаг "по-русски"
   * @example false
   */
  rusByDefaultDisabled: boolean

  /**
   * Разрешенный способ отправки: E-mail (SMTP, вариант 1)
   * @example true
   */
  smtp1: boolean

  /**
   * Разрешенный способ отправки: HTTP, SOAP, SMTP и E-mail (SMTP, вариант 2)
   * @example true
   */
  smtp2: boolean
  blackList?: { blackListId: number; blackListName: string }
  smtpHeaders?: { received: string; received2: string }

  /** @example ["pepsico.com","ololo.ru","alala.com"] */
  smtpAllowedFrom?: string[]

  /**
   * Период оповещений о попытках отправить сообщение с некорректным заголовком:
   *  * `never` - Никогда
   *  * `immediately` - Сразу
   *  * `daily` - Ежедневно
   *
   * @example immediately
   */
  smtpBadHeadersAlertPeriod?: 'never' | 'immediately' | 'daily'
  timezone: TimezoneResult

  /**
   * Номера телефонов, на которые можно отправлять сообщения через e-mail (все или список)
   * @example list
   */
  allowedPhones: 'anyphone' | 'list'

  /**
   * Список номеров телефонов, на которые можно отправлять сообщения через e-mail (через запятую)
   * @example 79998887766, 79998887755
   */
  allowedPhonesList: string

  /** Список входящих номеров */
  moNumbers: MoNumberResponse[]
}

export interface SecuritySettings {
  /**
   * E-mail для отправки отчётов о рассылках департаментов
   * @example email@domain.com
   */
  controllerEmail?: string | null
}

export type SecuritySettingsResult = SecuritySettings

export interface SessionResult {
  /**
   * Логин учетной записи
   * @example testuser:dep2
   */
  login: string

  /**
   * Ресурс сессии:
   *  * `3` - Личный кабинет
   *
   * @example 3
   */
  source: 3

  /**
   * Дата создания сессии (timestamp)
   * @example 1654006738
   */
  createdAt: number

  /**
   * Идентификатор сессии
   * @example 668000c50f14fff2
   */
  accessTokenHash: string

  /**
   * IP с которого была открыта сессия
   * @example 127.0.0.1
   */
  ip: string

  /**
   * Указатель текущей сессии (false опускается)
   * @example true
   */
  currentSession?: boolean
}

/**
 * Статус батча:
 * `new` - Ожидает рассылки
 * `processing` - Рассылается
 * `canceled` - Отменено
 * `paused` - Приостановлено
 * `finished` - Завершено
 * `expired` - Время ожидания истекло
 * `premoderate` - На модерации
 */
export enum BatchStatusField {
  New = 'new',
  Processing = 'processing',
  Canceled = 'canceled',
  Paused = 'paused',
  Finished = 'finished',
  Expired = 'expired',
  Premoderate = 'premoderate',
}

export interface BatchEditForm {
  /**
   * Название рассылки
   * @example Edited batch name
   */
  name?: string

  /**
   * Дата начала рассылки
   * @pattern ^\d{4}-\d{2}-\d{2} \d{2}-\d{2}-\d{2}
   * @example 2022-04-05 12:00:00
   */
  startDate?: string

  /**
   * Время окончания рассылки (пустая строка, если значение нужно удалить)
   * @pattern \d{2}-\d{2}-\d{2}
   * @example 19:00:00
   */
  endTime?: string

  /**
   * Время начала рассылки на следующий день (пустая строка, если значение нужно удалить)
   * @pattern \d{2}-\d{2}-\d{2}
   * @example 10:00:00
   */
  startTimeNextDay?: string

  /**
   * Интервал (пауза между отправками сообщений батча)
   * @example 1.0513
   */
  gap?: number

  /**
   * Имя отправителя
   * @pattern ^[A-Za-z0-9!\.!&*?,\"\':_\s-]+$
   * @example SMS Traffic
   */
  originator?: string

  /**
   * Положительное число время жизни сообщения в минутах (0 = дефолтное ограничение, 24 часа)
   * @example 600
   */
  timeout?: number

  /**
   * Текст рассылки
   * @example Edited message text
   */
  message?: string

  /**
   * Чекбокс "по-русски":
   *  * `false` - Не отмечен (латиница)
   *  * `true` - Отмечен (юникод)
   *
   * @example true
   */
  rus?: boolean

  /** Список каналов и их опций отправки для текущего батча (ключи - имена каналов) */
  channels?: BatchChannelTemplate
}

/**
* Список каналов и их опций отправки для текущего батча (ключи - имена каналов)
* @example {
  "viber": {
    "message": "😱 Viber scary message",
    "buttonUrl": "https://www.smstraffic.ru",
    "buttonName": "Click me",
    "timeout": 3600
  },
  "whatsapp": {
    "message": "😱 Whatsapp message"
  },
  "sms": {
    "message": "Just simple sms"
  },
  "push": {
    "message": "Push me and then just touch me"
  }
}

*/
export type BatchChannelTemplate = Record<
  string,
  { message?: string; buttonUrl?: string; buttonName?: string }
>

export interface BatchResult {
  /** @example 18629198 */
  batchId: number

  /** @example 13019 */
  departmentId: number

  /**
   * Название рассылки
   * @example C-00069891
   */
  name: string

  /**
   * Статус батча:
   *  * `new` - Ожидает рассылки
   *  * `processing` - Рассылается
   *  * `canceled` - Отменено
   *  * `paused` - Приостановлено
   *  * `finished` - Завершено
   *  * `expired` - Время ожидания истекло
   *  * `premoderate` - На модерации
   *
   */
  status: BatchStatusField

  /**
   * Имя отправителя (Если null - при рассылке используется имя отправителя по умолчанию)
   * @example SMS Traffic
   */
  originator: string | null

  /**
   * Текст рассылки (null, если isSmartDelivery = true)
   * @example Automatically created batch
   */
  message?: string | null

  /**
   * Количество абонентов в рассылке, которым уже было отправлено сообщение
   * @example 16890
   */
  sentPhones: number

  /**
   * Общее количество абонентов в рассылке
   * @example 68393
   */
  totalPhones: number

  /**
   * Чекбокс "по-русски":
   *  * `false` - Не отмечен (латиница)
   *  * `true` - Отмечен (юникод)
   *
   * @example true
   */
  rus: boolean

  /**
   * Интервал (пауза между отправками сообщений батча)
   * @example 1.4524
   */
  gap: number

  /**
   * IP
   * @example 10.0.2.10
   */
  ip: string

  /**
   * Положительное число время жизни сообщения в минутах (0 = дефолтное ограничение, 24 часа)
   * @example 600
   */
  timeout: number

  /**
   * Учитывать локальное время абонентов
   * @example false
   */
  isLocalTime: boolean

  /**
   * Является ли рассылка SmartDelivery (= не только смс канал/отсутствие смс канала вообще)
   * @example false
   */
  isSmartDelivery: boolean

  /**
   * Время окончания рассылки
   * @pattern \d{2}-\d{2}-\d{2}
   * @example 19:00:00
   */
  endTime: string | null

  /**
   * Время начала рассылки на следующий день
   * @pattern \d{2}-\d{2}-\d{2}
   * @example 10:00:00
   */
  startTimeNextDay: string | null

  /**
   * Чёрный список (название)
   * @example ЧЕРНЫЙ СПИСОК (общий)
   */
  blacklist: string | null

  /**
   * Дата создания рассылки
   * @pattern ^\d{4}-\d{2}-\d{2} \d{2}-\d{2}-\d{2}
   * @example 2022-04-05 11:32:46
   */
  creationDate: string

  /**
   * Дата изменения рассылки
   * @pattern ^\d{4}-\d{2}-\d{2} \d{2}-\d{2}-\d{2}
   * @example 2022-04-05 11:35:46
   */
  updateDate: string

  /**
   * Дата начала рассылки
   * @pattern ^\d{4}-\d{2}-\d{2} \d{2}-\d{2}-\d{2}
   * @example 2022-04-05 12:00:00
   */
  startDate: string

  /**
   * Примерное время окончания рассылки (если статус new, processing, paused, premoderate)
   * @pattern ^\d{4}-\d{2}-\d{2} \d{2}-\d{2}-\d{2}
   * @example 2022-04-05 12:00:00
   */
  estimatedEndDate: string | null

  /**
   * ID родительского батча (0 - если нет) [это уникальный идентификатор группы рассылок]
   * @example 341
   */
  parentBatchId: number

  /**
   * Имя родительского батча (null, если parentBatchId == 0)
   * @example Имя parent батча
   */
  parentBatchName: string | null

  /** Группы, используемые для рассылки. */
  batchesGroups?: { groupId: number; groupName: string }[]

  /** Список доступных кнопок для батча для отображения на фронте */
  buttons?: ('edit' | 'approve')[]

  /** Есть ли отчеты с номерами для скачивания */
  phonesReports?: { sent?: number; unsent?: number }
}

export type BatchExtResult = BatchResult & {
  channelList?: string
  channels?: BatchChannelsResult
}

export interface ParentBatchResult {
  /** @example 18629198 */
  parentBatchId: number

  /** @example Parent name or batch without parent name */
  parentBatchName: string | null
  batchIds: number[]

  /** Кнопки для парент батча (отображаются только для метода list) */
  buttons?: ('start' | 'pause')[]
}

/**
* Список каналов и их опций отправки для текущего батча (ключи - имена каналов)
* @example {
  "viber": {
    "message": "😱 Viber scary message",
    "buttonUrl": "https://www.smstraffic.ru",
    "buttonName": "Click me",
    "fileId": 5478,
    "fileUrl": "https://dv6zfl8o6j0x0.cloudfront.net/7071479460473621899.png",
    "timeout": 3600
  },
  "whatsapp": {
    "message": "😱 Whatsapp message",
    "fileId": 5479,
    "fileUrl": "https://dv6zfl8o6j0x0.cloudfront.net/7071499876722298289.png"
  },
  "sms": {
    "message": "Just simple sms"
  },
  "push": {
    "message": "Push me and then just touch me"
  }
}

*/
export type BatchChannelsResult = Record<
  string,
  {
    message?: string
    buttonUrl?: string
    buttonName?: string
    fileId?: number
    fileUrl?: string
  }
>

export interface BatchFileResponse {
  /**
   * Название файла (может быть без расширения; возможные расширения: jpg, jpeg, png, gif, bmp, svg, webp).
   *
   * @example state13-1
   */
  filename: string

  /**
   * mime-type файла (может быть null).
   * @example image/jpeg
   */
  mimeType: string | null

  /**
   * Картинка в base64.
   * @format byte
   * @example AAABAAEAEBAAAAEAGAB...
   */
  base64: string
}

/**
 * Ключ - значение поля, по которому была произведена группировка статистики.
 */
export type StatisticsResult = Record<
  string,
  {
    total: number
    sumCosts: number
    delivered: number
    deliveredPercent: number
    read: number
    readPercent: number
    clicked: number
    clickedPercent: number
    checked: number
    checkedPercent: number
    name: string
  }
>

/**
 * Шаблон для определения типа трафика сообщения. Для канала sms
 */
export interface Template {
  /** Идентификатор записи в базе. */
  recordId: number

  /** Идентификатор клиента на платформе СМС Трафик. */
  clientId: number

  /** Имя отправителя к которому привязан шаблон. */
  originator: string

  /** Шаблон сообщения. */
  template: string

  /** Провайдер к которому относится шаблон. */
  provider: string

  /** Объект описывающий оператора */
  operator?: Operator
  trafficType?: TrafficType
}

export type TemplatesResult = ApiResult & { list?: Template[]; pageInfo?: Page }

/**
 * Объект описывающий сущность сервиса в шаблонах для месенджеров
 */
export interface MessengerService {
  /**
   * Идентификатор записи.
   *
   */
  id: number

  /**
   * Имя организации.
   *
   */
  organization: string

  /**
   * Идентификатор сервиса.
   *
   */
  serviceId: string

  /**
   * Имя учетной записи которая привязана к севрису.
   *
   */
  login: string
}

/**
 * Шаблон для определения типа трафика сообщения. Для канала sms
 */
export interface MessengerTemplate {
  /**
   * Идентификатор записи в базе.
   *
   */
  status: number

  /**
   * Шаблон сообщения.
   *
   */
  template: string

  /**
   * Объект описывающий сущность сервиса в шаблонах для месенджеров
   *
   */
  service: MessengerService
}

export type FiltersResult = ApiResult & {
  smsOriginators?: string[]
  smsOperators?: Operator[]
  smsTrafficTypes?: TrafficType[]
  viberServices?: MessengerService[]
  vkServices?: MessengerService[]
  whatsappServices?: MessengerService[]
  ottStatuses?: string[]
  allowedChannels?: string[]
}

export type MessengerTemplatesResult = ApiResult & {
  list?: MessengerTemplate[]
  pageInfo?: Page
}

export interface AccountShortResult {
  /**
   * Идентификатор учетной записи.
   * @example 13018
   */
  id: number

  /**
   * Логин субучетной записи.
   * @example account13018
   */
  login: string

  /**
   * Полный логин субучетной записи (логинКлиента:логинСубучетки).
   * @example ron:account13018
   */
  fullLogin: string

  /**
   * Название учетной записи.
   * @example Тестовое название
   */
  name: string

  /** Статус учетной записи. */
  status: 'active' | 'deleted' | 'blocked'

  /**
   * Дата добавления учетной записи.
   * @pattern ^\d{4}-\d{2}-\d{2} \d{2}-\d{2}-\d{2}
   * @example 2016-07-19 15:17:09
   */
  addDate: string

  /**
   * Дата последнего изменения учетной записи.
   * @pattern ^\d{4}-\d{2}-\d{2} \d{2}-\d{2}-\d{2}
   * @example 2022-02-18 17:11:21
   */
  editDate: string
  twoFactorAuth: { enabled: boolean; phone: number | null }
}

export type AccountFullResult = AccountShortResult & {
  defaultOriginator?: string
  roles?: number[]
  timezone?: TimezoneResult
  passStrengthPolitics?: PassStrengthPolitics
}

export interface CreateAccount {
  /**
   * Логин. Валидация: 1-60 символов; паттерн /^[a-z0-9_]+$/i.
   * @example account13019
   */
  login: string

  /**
   * Имя. Валидация: 1-255 символов.
   * @example Тест 13019
   */
  name: string

  /** Пароль. */
  password: string

  /**
   * Имя отправителя по умолчанию. Валидация: 2-15 символов.
   * @pattern ^[A-Za-z0-9!\.!&*?,\"\':_\s-]+$
   * @example SMS Traffic
   */
  defaultOriginator?: string

  /**
   * Роли учетной записи.
   * @example [1,2,3,5]
   */
  roles?: number[]

  /**
   * Идентификатор часового пояса
   * @example 280
   */
  timezoneId?: number

  /** Номер телефона для двух-факторной аутентификации. */
  twoFactorAuthPhone?: TwoFactorAuthPhone

  /**
   * Политика сложности пароля:
   *  * `default` - Стандартная. Минимум 8 символов, латинские буквы обоих регистров и цифры. ^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*()_+])[a-zA-Z0-9!@#$%^&*()_+]{8,25}$
   *  * `weak` - Ослабленная. Минимум 8 символов. ^[a-zA-Z0-9!@#$%^&*()_+]{8,25}$
   *  * `complex` - Усиленная. Минимум 8 символов. ^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*()_+])[a-zA-Z0-9!@#$%^&*()_+]{8,25}$
   *
   */
  passStrengthPolitics?: PassStrengthPolitics
}

export interface EditAccount {
  /**
   * Логин учетной записи.
   * @example account13019
   */
  login?: string

  /**
   * Название учетной записи.
   * @example Тест 13019 new
   */
  name?: string

  /** Статус учетной записи. */
  status?: 'active'

  /**
   * Имя отправителя по умолчанию. Валидация: 2-15 символов.
   * @pattern ^[A-Za-z0-9!\.!&*?,\"\':_\s-]+$
   * @example SMS Traffic
   */
  defaultOriginator?: string

  /**
   * Роли учетной записи.
   * @example [1,2,3,5]
   */
  roles?: number[]

  /**
   * Идентификатор часового пояса
   * @example 280
   */
  timezoneId?: number

  /** Номер телефона для двух-факторной аутентификации. */
  twoFactorAuthPhone?: TwoFactorAuthPhone
}

export interface AccountPrivacy {
  /**
   * Список субучетных записей и департаментов, чьи сообщения разрешено просматривать (опциональное поле).
   * @example [1019,123]
   */
  viewMessages?: number[]

  /**
   * Список субучетных записей и департаментов, чьи рассылки можно подтверждать (опциональное поле).
   * @example [23]
   */
  approveBatches?: number[]

  /**
   * Список субучетных записей и департаментов, чьи рассылки можно редактировать (опциональное поле).
   * @example [899,900,901,922]
   */
  editBatches?: number[]

  /**
   * Список субучетных записей и департаментов, чьи рассылки списки для рассылок можно просматривать (опциональное поле).
   * @example [4013]
   */
  viewGroups?: number[]
}

export interface AccountPrivacyEntity {
  /**
   * Идентификатор учетной записи или департамента.
   * @example 13019
   */
  departmentId: number

  /**
   * Логин учетной записи или департамента.
   * @example client1:account_1s
   */
  fullLogin: string

  /**
   * Флаг департамента (true если департамент, false если субучетная запись).
   * @example false
   */
  isDepartment: boolean
}

export interface AccountPrivacyResult {
  /** Список субучетных записей и департаментов, чьи сообщения разрешено просматривать (опциональное поле). */
  viewMessages: AccountPrivacyEntity[]

  /** Список субучетных записей и департаментов, чьи рассылки можно подтверждать (опциональное поле). */
  approveBatches: AccountPrivacyEntity[]

  /** Список субучетных записей и департаментов, чьи рассылки можно редактировать (опциональное поле). */
  editBatches: AccountPrivacyEntity[]

  /** Список субучетных записей и департаментов, чьи рассылки списки для рассылок можно просматривать (опциональное поле). */
  viewGroups: AccountPrivacyEntity[]
}

/**
 * null, если основная учетная запись, т.к. у неё нет ограничений.
 */
export type AccountPrivacyResultNullable = AccountPrivacyResult | null

export interface AccountSecurity {
  /**
   * Количество дней неактивности, по исчтечению которых учетка блокируется
   * @example 0
   */
  inactivityPeriod?: number

  /**
   * Разрыв сесси по неактивности (в минутах)
   * @example 15
   */
  authTimeout?: number

  /**
   * Разрыв сессии (в минутах; принудительный, не зависит от активности)
   * @example 60
   */
  authTimeoutGlobal?: number

  /**
   * Период актульности пароля (в днях)
   * @example 180
   */
  passwordPeriod?: number

  /**
   * Разрешенные протоколы отправки (битовая маска)
   * @example 255
   */
  allowedGates?: number

  /**
   * Максимальное количество открытых сессий (0 - не ограничено)
   * @example 10
   */
  maxSessions?: number
}

export type AccountSecurityResult = AccountSecurity

export interface DepartmentResult {
  /**
   * Идентификатор департамента.
   * @example 13020
   */
  id: number

  /**
   * Логин департамента.
   * @example department13010
   */
  login: string

  /**
   * Полный логин департамента(логинКлиента:логинДепартамента).
   * @example ron:department13010
   */
  fullLogin: string

  /**
   * Название департамента.
   * @example Тестовый департамент new
   */
  name: string

  /** Статус департамента. */
  status: 'active' | 'deleted' | 'blocked'

  /**
   * Дата добавления департамента.
   * @pattern ^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}
   * @example 2016-07-19 15:17:09
   */
  addDate: string

  /**
   * Дата последнего изменения департамента.
   * @pattern ^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}
   * @example 2022-02-18 17:11:21
   */
  editDate: string
  twoFactorAuth: { enabled: boolean; phone: number | null }
  timezone: TimezoneResult

  /** Список пользователй департамента. */
  users: DepUserShortResult[]
}

export interface EditDepartment {
  /**
   * Логин департамента.
   * @example department13020
   */
  login?: string

  /**
   * Название департамента.
   * @example Тестовый департамент new
   */
  name?: string

  /** Статус департамента. */
  status?: 'active'

  /**
   * Идентификатор часового пояса
   * @example 280
   */
  timezoneId?: number

  /** Номер телефона для двух-факторной аутентификации. */
  twoFactorAuthPhone?: TwoFactorAuthPhone
}

export interface DepUserShortResult {
  /**
   * Идентификатор пользователя департамента.
   * @example 7989
   */
  id: number

  /**
   * Идентификатор департамента.
   * @example 13018
   */
  departmentId: number

  /**
   * Логин пользователя департамента.
   * @example user01
   */
  login: string

  /**
   * Полный логин пользователя департамента (логинКлиента:логинДепартамента:логинЮзера).
   * @example client007:department003:user01
   */
  fullLogin: string

  /**
   * Название учетной записи пользователя департамента.
   * @example Тестовый user
   */
  name: string

  /** Статус учетной записи пользователя департамента. */
  status: 'active' | 'deleted' | 'blocked'

  /**
   * Дата добавления учетной записи пользователя департамента.
   * @pattern ^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}
   * @example 2016-07-19 15:17:09
   */
  addDate: string

  /**
   * Дата последнего изменения учетной записи пользователя департамента.
   * @pattern ^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}
   * @example 2022-02-18 17:11:21
   */
  editDate: string
}

export type DepUserFullResult = DepUserShortResult & {
  defaultOriginator?: string
  roles?: number[]
  passStrengthPolitics?: PassStrengthPolitics
}

export interface EditDepartmentUser {
  /**
   * Логин пользователя департамента.
   * @example user01
   */
  login?: string

  /**
   * Название учетной записи пользователя департамента.
   * @example Тестовый user
   */
  name?: string

  /** Статус учетной записи пользователя департамента. */
  status?: 'active'

  /**
   * Имя отправителя по умолчанию. Валидация: 2-15 символов.
   * @pattern ^[A-Za-z0-9!\.!&*?,\"\':_\s-]+$
   * @example SMS Traffic
   */
  defaultOriginator?: string

  /**
   * Роли учетной записи пользователя департамента.
   * @example [1,2,3,5]
   */
  roles?: number[]
}

export interface RoleResult {
  /**
   * Идентификатор роли.
   * @example 1
   */
  id: number

  /**
   * Наименование роли.
   * @example users&rights
   */
  name: string
  description: { ru: string; en: string }
}

export interface Draft {
  /** Идентификатор черновика в базе */
  draftId: number

  /** Идентификатор клиента за которым закреплен этот черновик */
  clientId: number

  /** Канал отправки */
  channel: 'sms' | 'viber' | 'whatsapp' | 'vk'

  /** Имя черновика */
  name: string

  /** Текст черновика */
  text: string

  /**
   * Дата создания черновика
   * @pattern ^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}
   * @example 2022-02-18 17:11:21
   */
  addDate: string

  /**
   * Дата редактирования
   * @pattern ^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}
   * @example 2022-02-18 17:11:21
   */
  editDate: string
}

export type DraftResult = ApiResult & { draft?: Draft }

export type DraftsResult = ApiResult & { list?: Draft[]; pageInfo?: Page }

/**
 * Список  для рассылок
 */
export interface Group {
  /** Идентификатор списка */
  groupId: number

  /** Имя списка для рассылок */
  groupName: string

  /** Общее количество телефонов в списке */
  totalPhones: number

  /**
   * Дата создания списка
   * @pattern ^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}
   * @example 2016-07-19 15:17:09
   */
  creationDate: string

  /** Список виден всем департаментам */
  viewForAll?: boolean

  /** Список не виден основному департаменту */
  viewOptional?: boolean

  /** Используется ли список для поздравлений с ДР */
  congratulate: boolean
  congratulateData?: CongratulateDataResult
}

export type GroupResultExt = GroupResult & { routes?: RoutesResult }

export type GroupResult = ApiResult & { group?: Group }

export type GroupsResult = ApiResult & { list?: Group[]; pageInfo?: Page }

/**
 * Поздравление с днем рождения
 */
export interface CongratulateDataInternal {
  /**
   * Время старта поздравлений
   * @pattern ^\d{2}:\d{2
   * @example 15:00
   */
  startTime: string

  /**
   * Имя отправителя от которого будет отправлено поздравление
   * @pattern ^[A-Za-z0-9!\.!&*?,\"\':_\s-]+$
   * @example SMS Traffic
   */
  originator: string

  /** Транслитерировать */
  transliterate: boolean

  /**
   * routeGroupId
   * @example t5ErZR7oGc8AQsd0LnFLnt3gHEOlNTrTLKmF
   */
  routeGroupId?: string
}

export type CongratulateData = CongratulateDataInternal & {
  channels?: Record<
    string,
    {
      message: string
      buttonName?: string
      buttonUrl?: string
      fileUrl?: string
    }
  >
}

export type CongratulateDataResult = CongratulateDataInternal & {
  channels?: Record<
    string,
    {
      message: string
      buttonName?: string
      buttonUrl?: string
      fileUrl?: string
    }
  >
}

/**
 * Член списка для рассылок
 */
export interface GroupMember {
  /**
   * Идентификатор клиента.
   * @example 100062
   */
  clientId: number

  /**
   * Идентификатор списка для рассылок.
   * @example 100002
   */
  groupId: number

  /**
   * Номер телефона абонента.
   * @example 79001112233
   */
  phone: number

  /**
   * Дата добавления записи.
   * @pattern ^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}
   * @example 2016-07-19 15:17:09
   */
  addDate: string

  /**
   * Имя абонента.
   * @example Иван Говнов
   */
  memberName?: string

  /**
   * День рождения абонента.
   * @pattern ^\d{4}-\d{2}-\d{2}
   * @example 2016-07-19
   */
  birthday?: string
  gender?: Gender

  /** Дополнительная информация. */
  comment?: string
}

export type GroupMemberResult = ApiResult & { groupMember?: GroupMember }

export type GroupMembersResult = ApiResult & {
  list?: GroupMember[]
  pageInfo?: Page
}

/**
 * Одно сообщение. Наличие поле напрямую зависит от переданного запроса.
 */
export interface Message {
  /** идентификатор сообщения. */
  id?: string

  /**
   * номер абонента.
   * @example 79112223344
   */
  phone?: string

  /** текст сообщения. */
  message?: string

  /**
   * дата и время приема сообщения платформой СМС траффик.
   * @pattern ^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}
   * @example 2016-07-19 15:17:09
   */
  submissionDate?: string

  /**
   * дата и время отправки сообщения оператору.
   * @pattern ^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}
   * @example 2016-07-19 15:17:09
   */
  sendDate?: string

  /**
   * дата и время доставки сообщения абоненту.
   * @pattern ^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}
   * @example 2016-07-19 15:17:09
   */
  deliveryDate?: string

  /**
   * дата и время когда абонент прочитал сообщение(заполнено когда канал не СМС).
   * @pattern ^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}
   * @example 2016-07-19 15:17:09
   */
  readDate?: string

  /** канал отправки сообщения. */
  channel?: Channel

  /** Статус доставки сообщения. */
  status?: string

  /** стоимость сообщения по тарифу. Для постоплатных тарифов данное поле будет пустым. */
  cost?: string

  /** имя отправителя, с которым сообщение отправлялось в канал. */
  originator?: string

  /** IP адрес, с которого было отправлено сообщение. */
  ip?: string

  /** в случае недоставки здесь указывается код причины недоставки. */
  error?: string

  /**
   * имя оператора, за которым закреплён номер телефона получателя.
   * Заполняется только для каналов, у которых получателем является номер
   *
   */
  operatorName?: string

  /**
   * название страны, за которой закреплён номер телефона получателя.
   * Заполняется только для каналов, у которых получателем является номер
   *
   * @example Russian Federation, Kazakhstan
   */
  countryName?: string

  /** имя рассылки, в которой учавствует данное сообщение. */
  batchName?: string

  /**
   * имя департамента, отправившего сообщение.
   * Или пустая строка, если сообщение отправлено с основной учётки.
   *
   */
  departmentName?: string
}

export type MessagesResult = ApiResult & {
  list?: Message[]
  pageInfo?: Page
  costsInfo?: CostsInfo
}

/**
 * Отчет
 */
export interface Report {
  /** идентификатор отчета */
  reportId?: number

  /**
   * дата генерации отчета. Пусто если еще не сформирован
   * @pattern ^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}
   * @example 2016-07-19 15:17:09
   */
  genDate?: string

  /**
   * дата начала периода с которого запрашивали отчет.
   * @pattern ^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}
   * @example 2016-07-19 15:17:09
   */
  from?: string

  /**
   * дата окончания периода по который запрашивали отчет.
   * @pattern ^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}
   * @example 2016-07-19 15:17:09
   */
  to?: string

  /** список департаментов по которым запросили отчет. Если пусто, значит all. */
  departments?: number[]

  /** список департаментов по которым запросили отчет. Если пусто, значит all. */
  departmentsList?: DepartmentShortInfo[]

  /** список телефонов по которым запросили отчет. */
  phones?: number[]

  /** имя отправителя по которому запросили отчет. */
  originator?: string
  type?: MessageType

  /** канал отправки сообщения. */
  channel?: Channel

  /** статус сообщений. Если пусто, значит all. */
  deliveryStatus?: 'delivered' | 'not_delivered' | 'read'

  /** список батчей по которым заказывали отчет. */
  batches?: number[]

  /** список батчей по которым заказывали отчет. */
  batchesList?: { batchName: string; batchIds: number[] }[]

  /** формат файла. */
  format?: string

  /** Словарь статусов готовности отчета */
  status?: StatusReport
}

/**
 * Словарь статусов готовности отчета
 */
export enum StatusReport {
  New = 'new',
  Completed = 'completed',
  Processing = 'processing',
  WithError = 'with_error',
  NeedToSendReport = 'need_to_send_report',
}

export type ReportResult = ApiResult & { report?: Report }

export type ReportsResult = ApiResult & { list?: Report[]; pageInfo?: Page }

export type BatchesFilterResult = ApiResult & {
  list?: { batchName: string; batchIds: number[] }[]
}

export type MessagesFiltersResult = ApiResult & {
  departments?: DepartmentShortInfo[]
}

export type LogsReportsEventsResult = ApiResult & {
  list?: LogsReportsEvent[]
  pageInfo?: Page
}

/**
 * информация по затратам клиента
 */
export interface CostsInfo {
  /** потрачено валюты клиента. Данное поле заполняется в случае если клиента Предоплатный. */
  totalCosts?: number

  /** потрачено кредитов клиента. Данное поле заполняется в случае если клиента Постоплатный. */
  totalCreditsCosts?: number
}

/**
 * событие над отчетом. Скачивание, создание, etc
 */
export interface LogsReportsEvent {
  /** идентификатор отчета */
  reportId?: number

  /**
   * дата создания эвента
   * @pattern ^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}
   * @example 2016-07-19 15:17:09
   */
  createdAt?: string

  /** логин клиента который выполнил действие */
  clientLogin?: string

  /** тип события */
  event?: 'download' | 'create'
}

export type OriginatorsFiltersResult = ApiResult & { operators?: Operator[] }

export type OriginatorsResult = ApiResult & {
  list?: Originator[]
  pageInfo?: Page
}

export type OriginatorResult = ApiResult & { originator?: Originator }

export interface Originator {
  /**
   * Идентификатор имени отправителя
   * @example 12
   */
  originatorId: number

  /**
   * Имя отправителя
   * @example Test
   */
  originator: string

  /** Объект описывающий оператора */
  operator: Operator

  /** Возможные статусы у имен отправителя. */
  status: OriginatorStatus

  /**
   * Дата добавления имени к нам на платформу.
   * @pattern ^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}
   * @example 2016-07-19 15:17:09
   */
  addDate: string

  /**
   * Дата согласования имени у оператора, проставляется менеджером.
   * @pattern ^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}
   * @example 2016-07-20 15:17:09
   */
  approveDate: string

  /**
   * Дата удаления имени на платформе.
   * @pattern ^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}
   * @example 2016-07-21 15:17:09
   */
  deleteDate: string
}

/**
 * Возможные статусы у имен отправителя.
 */
export enum OriginatorStatus {
  New = 'New',
  Failed = 'Failed',
  Deleted = 'Deleted',
  Approved = 'Approved',
  ToDelete = 'ToDelete',
  WaitConfirm = 'WaitConfirm',
  WaitDeleted = 'WaitDeleted',
}

export type EventLogsResult = ApiResult & { list?: EventLog[]; pageInfo?: Page }

export interface EventLog {
  /**
   * Идентификатор события.
   * @example 12
   */
  eventId: number

  /**
   * Логин от которого записано событие.
   * @example Test
   */
  login: string

  /**
   * Текст события.
   * @example Детали учетной записи "Новая учетная запись" изменены
   */
  eventDesc: string

  /**
   * IP адрес с которого было записано событие.
   * @example 127.0.0.1
   */
  ip: string

  /**
   * Дата добавления события к нам на платформу.
   * @pattern ^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}
   * @example 2016-07-19 15:17:09
   */
  recordDate: string
}

export type AccountingInfoResult = ApiResult & {
  accountingInfo?: AccountingInfo
}

export type TransactionsResult = ApiResult & {
  list?: ClientTransaction[]
  pageInfo?: Page
}

export type TransactionResult = ApiResult & {
  clientTransaction?: ClientTransaction
}

export type DocumentsResult = ApiResult & {
  list?: ClientDocument[]
  pageInfo?: Page
}

export type DocumentResult = ApiResult & { clientTransaction?: ClientDocument }

/**
 * Объект с Счетами, счетами фактуры, актами
 */
export interface ClientDocument {
  /**
   * Идентификатор документа.
   * @example 228
   */
  recordId: number

  /**
   * Бухгалтерский номер документа.
   * @example 5012/23
   */
  number: string

  /**
   * Дата документа.
   * @pattern ^\d{4}-\d{2}-\d{2}
   * @example 2016-07-01
   */
  enterDate: string

  /**
   * Сумма документа.
   * @example 500
   */
  sumSNds: string

  /** Словарь со статусами первичных документов */
  documentStatus: DocumentStatus

  /** @example ["schet"] */
  viewTypes?: DocumentType[]

  /** Список валют */
  currency: Currencies

  /** Объект с документом из электронного документооборота */
  edoDocument: EdoDocument
}

/**
 * Объект с документом из электронного документооборота
 */
export interface EdoDocument {
  /** Ссылка на документ на сайте вендора */
  link: string

  /** Статус документа в системе вендора. Если указано -1, значит документ уже отправлен */
  status: number
}

/**
 * Объект с начислением клиента
 */
export interface ClientTransaction {
  /**
   * Идентификатор начисления.
   * @example 228
   */
  transactionId: number

  /**
   * Дата начисления.
   * @pattern ^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}
   * @example 2016-07-19 15:17:09
   */
  transactionDate: string

  /**
   * Сумма начисления.
   * @example 200
   */
  deltaRur: string

  /**
   * Изменение.
   * @example 50
   */
  deltaUsd: string

  /**
   * Количество сообщений.
   * @example 500
   */
  deltaCredits: string

  /**
   * Комментарий к начислению.
   * @example Где бабки, Лебовски?
   */
  notes: string

  /** Словарь с типами начислений */
  transactionType: TransactionType

  /** Список валют */
  currency: Currencies
}

/**
 * Объект с реквизитами клиента
 */
export interface AccountingInfo {
  /**
   * Наименование организации.
   * @example Рога и копыта
   */
  firmName: string

  /**
   * ФИО директора организации.
   * @example Иван Васильевич Овнов
   */
  director: string

  /**
   * Должность директора организации.
   * @example Ответственный за воздух
   */
  directorPosition: string

  /**
   * Номер телефона организации.
   * @example 8(800)555-35-35
   */
  phone: string

  /**
   * Факс организации.
   * @example 8(800)555-35-35
   */
  fax: string

  /**
   * Юридический адрес организации.
   * @example ул. Пушкина дом колотушкина
   */
  addressJure: string

  /**
   * Фактический адрес организации.
   * @example ул. Пушкина дом колотушкина
   */
  addressFact: string

  /**
   * Почтовый адрес организации.
   * @example ул. Пушкина дом колотушкина
   */
  addressPost: string

  /**
   * Название банка в реквизитах клиента.
   * @example Банк саентологии
   */
  bankName: string

  /**
   * Расчетный счет в банке.
   * @example 407028106382900350
   */
  rSchet: string

  /**
   * Корпоротивный счет в банке.
   * @example 30101810400000000000
   */
  korSchet: string

  /**
   * ИНН.
   * @example 12345678
   */
  inn: string

  /**
   * БИК.
   * @example 44525225
   */
  bik: string

  /**
   * КПП.
   * @example 12345
   */
  kpp: string

  /** Словарь переодичности выставления счетов-фактур клиента */
  accountingPeriod: AccountingPeriod
}

/**
 * Словарь с типами начислений
 */
export enum TransactionType {
  Deposit = 'deposit',
  Testing = 'testing',
  TestingOver = 'testing over',
  Refund = 'refund',
  Conversion = 'conversion',
  Transfer = 'transfer',
  Fine = 'fine',
  TariffChange = 'tariff change',
  Correction = 'correction',
  RefundAdvance = 'refund_advance',
  Netting = 'netting',
  AbonPlata = 'abon_plata',
}

/**
 * Словарь переодичности выставления счетов-фактур клиента
 */
export enum AccountingPeriod {
  Mn = 'mn',
  Kv = 'kv',
  No = 'no',
  H = 'h',
  Y = 'y',
}

/**
 * Словарь со статусами первичных документов
 */
export enum DocumentStatus {
  Paid = 'paid',
  NotPaid = 'not_paid',
}

/**
 * Словарь c типами документов
 */
export enum DocumentType {
  Schet = 'schet',
  Act = 'act',
  Factura = 'factura',
}

export type InitEdoResult = ApiResult & { edoInitInfo: EdoInitInfo }

export type CreateEdoResult = ApiResult & { edoCreateInfo: EdoCreateInfo }

export interface EdoInitInfo {
  departmentsList: DepartmentsList[]

  /** последняя выбранная организация */
  defaultOrganization: string

  /** Список организаций */
  organizationsList: Organization[]
}

export interface EdoCreateInfo {
  /** ссылка на созданный документ на платформе edo */
  link: string
}

export interface Organization {
  /** идентификатор организации */
  id?: string

  /** наименование организации */
  name?: string
}

export interface Department {
  /** идентификатор департамента */
  id?: string

  /** наименование департамента */
  name?: string
}

export interface DepartmentsList {
  /** идентификатор организации */
  id?: string

  /** наименование департамента */
  list?: Department[]
}

/**
 * Объек описывающий технический документ клиента
 */
export interface TechDocument {
  documentType: TechDocumentType
  files: {
    language: Language
    fileName: string
    extension: 'pdf' | 'zip' | 'doc'
    size: number
  }[]
}

export enum TechDocumentType {
  Api = 'api',
  ApiBy = 'api_by',
  ApiRitorica = 'api_ritorica',
  SdApi = 'sd_api',
  SdApiBy = 'sd_api_by',
  ImsiApi = 'imsi_api',
  LkDocumentation = 'lk_documentation',
  SmsEmployee = 'sms_employee',
  VoiceDocumentation = 'voice_documentation',
  VoiceApi = 'voice_api',
  CodeSample = 'code_sample',
}

export enum Providers {
  Ru = 'ru',
  By = 'by',
  Ritorica = 'ritorica',
}

export type TasksResult = ApiResult & { list?: Task[]; pageInfo?: Page }

export type TaskResult = ApiResult & { task?: Task }

/**
 * Объект в котором хранится текущая фоновая задача, которая выполняется на стороне платформы
 */
export interface Task {
  /** Идентификатор задачи */
  taskId: number

  /** Словарь типов фоновых задач */
  taskType: TaskType

  /** Словарь статусов фоновых задач */
  status: TaskStatus

  /**
   * Прогресс выполнения задачи в процентах от 0 до 100
   * @example 0
   */
  progress: number

  /**
   * Дата создания задачи.
   * @pattern ^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}
   * @example 2016-07-19 15:17:09
   */
  createdAt: string

  /**
   * Дата выполнения задачи.
   * @pattern ^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}
   * @example 2016-07-19 15:17:09
   */
  finishDate: string

  /** Описание задачи для загрузки файла в список для рассылки. */
  taskInfo?: TaskInfo
}

/**
 * Описание задачи для загрузки файла в список для рассылки.
 */
export interface TaskInfo {
  /**
   * Всего строк в файле.
   * @example 500
   */
  totalRows?: number

  /**
   * Обработано строк в файле.
   * @example 200
   */
  affectedRows?: number

  /**
   * Добавлено строк в список.
   * @example 199
   */
  insertedRows?: number

  /** Ошибка при обработке файла, если имеется. */
  error?: string

  /** Список  для рассылок */
  group?: Group
}

/**
 * Словарь типов фоновых задач
 */
export enum TaskType {
  GroupLoad = 'group_load',
}

/**
 * Словарь статусов фоновых задач
 */
export enum TaskStatus {
  New = 'new',
  Processed = 'processed',
  Paused = 'paused',
  Canceled = 'canceled',
  Finished = 'finished',
  Error = 'error',
}

/**
 * Объект, описывающий информацию о загруженном файле.
 */
export interface FileUploadResult {
  /** @example phoneparams_1656423658376.txt */
  fileName: string

  /** @example https://dv6zfl8o6j0x0.cloudfront.net/7160537924486873954.png */
  fileUrl?: string

  /**
   * Размер файла в килобайтах
   * @example 0.09
   */
  sizeKb: number
}

/**
 * Объект, описывающий информацию о загруженном файле с абонентами.
 */
export type FileSubscribersUploadResult = FileUploadResult & {
  successRows: number
  errorRows: number
  rowsProcessed: number
  fileNameErrors?: string
  errors: { rowNumber: string; errorNumber: number; row: string }[]
}

/**
 * Объект, описывающий ирмацию об отправленных сообщениях
 */
export interface SendInfoResult {
  /** @example 9812 */
  batchId: number | null

  /** @example 12 */
  totalPhones: number

  /**
   * Примерное время окончания рассылки (если статус new, processing, paused, premoderate)
   * @pattern ^\d{4}-\d{2}-\d{2} \d{2}-\d{2}-\d{2}
   * @example 2022-04-05 12:00:00
   */
  estimatedEndDate: string | null
}

/**
 * Объект, описывающий ирмацию об отправленных сообщениях
 */
export interface EstimatedCostResult {
  /** @example 2 */
  totalPhones: number

  /**
   * Примерная стоимость рассылки
   * @example 4.68
   */
  estimatedCost: number
}

/**
 * Объект, описывающий общие параметры для отправки сообщений
 */
export interface BaseSendForm {
  /** @example SMS Traffic */
  originator: string

  /**
   * Интервал (пауза между отправками сообщений. минимум 0.05, максимум 600)
   * @example 1.0513
   */
  gap?: number

  /**
   * Равномерно отправлять до (высчитывает gap автоматически, чтобы максимально приблизить дату окончания рассылки
   * у указанной; если указан параметр endTime, то время должно быть эквивалентно endTime;
   * если указан параметр startTimeNextDay, то параметр использовать запрещено)
   *
   * @pattern \d{2}-\d{2}-\d{2}
   * @example 11:00:00
   */
  gapTime?: string

  /**
   * Положительное число время жизни сообщения в минутах (0 = дефолтное ограничение, 24 часа; максимум 600)
   * @example 0
   */
  timeout: number

  /**
   * Дата начала рассылки (если в прошлом, то отправлять немедленно)
   * @pattern ^\d{4}-\d{2}-\d{2} \d{2}-\d{2}-\d{2}
   * @example 2022-06-28 12:00:00
   */
  startDate: string

  /**
   * Время окончания рассылки
   * @pattern \d{2}-\d{2}-\d{2}
   * @example 19:00:00
   */
  endTime?: string

  /**
   * Время начала рассылки на следующий день
   * @pattern \d{2}-\d{2}-\d{2}
   * @example 10:00:00
   */
  startTimeNextDay?: string
  batchName?: string

  /** 0 - не использовать черный список */
  blackListId?: number

  /** С учетом локального времени */
  localtime: boolean

  /** Транслитерировать */
  transliterate: boolean

  /**
   * Тестовая отправка на номера из testPhones
   * @example false
   */
  test?: boolean

  /** @example [79998887766,79998887755] */
  testPhones?: number[]

  /** Вернуть предварительный расчет стоимости (ТОЛЬКО ДЛЯ СМС ОТПРАВОК) */
  calculateCost?: boolean
}

export type BaseSmsForm = BaseSendForm & { message?: string }

export interface DefaultSendInput {
  /**
   * Указатель типа рассылки:
   *  * `commaSeparated` - Список телефонов, разделенных запятой.
   *  * `lineSeparated` - Список телефонов в столбик, один телефон на строку.
   *  * `lineSeparatedFile` - Список из файла.
   *  * `lineSeparatedWithMessages` - Список телефон-сообщение (через пробел), по одной паре на строку.
   *  * `lineSeparatedWithMessagesFile` - Файл со списком телефон-сообщение (через пробел), по одной паре на строку.
   *  * `lineSeparatedWithParamsFile` - Файл со списком параметров сообщений (первая строка заголовки, далее данные; колонки разделены через ";").
   *
   */
  phonesType: PhonesType

  /**
   * Текст сообщения
   * @example Hi
   */
  message?: string

  /**
   * Номера, номера с сообщениями и т.п.
   * @example 79998887766 test asd  asd qwd qwd\n 7999885566 asdasdas\n 73513750001 test
   */
  phonesInput?: string

  /**
   * Имя загруженного файла
   * @example phoneparams_1656422761175.txt
   */
  fileName?: string
}

export type BaseSmartForm = BaseSendForm & {
  routeGroupId: string
  channels: Record<
    string,
    {
      message: string
      buttonName?: string
      buttonUrl?: string
      fileName?: string
      timeout?: number
    }
  >
}

export type SimpleSendSmsForm = BaseSmsForm & DefaultSendInput

export type GroupsSendSmsForm = {
  selectedElements: SelectedElements
} & BaseSmsForm

export type SimpleSendSmartForm = BaseSmartForm & DefaultSendInput

export type GroupsSendSmartForm = {
  selectedElements: SelectedElements
} & BaseSmartForm

export interface SendFiltersForm {
  /**
   * Список тестовых номеров для тестового саморега
   * @example [78000010101,78000010202]
   */
  testPhones: number[]
  routes: RoutesResult
  sendRoutes?: {
    gid?: string
    channels?: {
      name?:
        | 'sms'
        | 'viber'
        | 'whatsapp'
        | 'ok'
        | 'vk'
        | 'email'
        | 'push'
        | 'flashcall'
      timeout?: number
    }[]
    isDefault?: boolean
  }[]
}

/**
* @example {
  "0": [
      "sms"
  ],
  "VKS85lMYVn9tpfxWPbV2vB1ejSDDM18DvqoN": [
      "email"
  ],
  "8NOB4WFATRTtp349QXQEWZk4M4DsW5SioZkO": [
      "sms"
  ],
  "t5ErZR7oGc8AQsd0LnFLnt3gHEOlNTrTLKmF": [
      "whatsapp",
      "sms"
  ],
  "xt8bMzNNXc1orxYADvuxBqOFINSxNNCpY2CE": [
      "viber"
  ],
  "vC6CyvfzLUdfhIrH3Y5vPh6Rd1wdZW7n8AQ2": [
      "whatsapp"
  ]
}

*/
export type RoutesResult = Record<
  string,
  ('sms' | 'viber' | 'whatsapp' | 'ok' | 'vk' | 'email' | 'push')[]
>

export type HomeNumbersResult = ApiResult & {
  totalMessageThisMonth?: number
  totalMessageLastMonth?: number
  lastUnpaidDocuments?: ClientDocument[]
}

/**
 * Объект отчета анализа доставки.
 */
export interface AnalysisReport {
  /**
   * Идентификатор отчета.
   * @example 1
   */
  reportId: number

  /**
   * Дата начала отчета.
   * @pattern ^\d{4}-\d{2}-\d{2}
   * @example 2022-05-28
   */
  startDate: string

  /**
   * Дата окончания отчета.
   * @pattern ^\d{4}-\d{2}-\d{2}
   * @example 2022-06-28
   */
  endDate: string
  department: DepartmentShortInfo

  /**
   * Дней с отрицательной статистикой.
   * @example 3
   */
  failureInterval: number

  /**
   * Признак готовности отчета. 1 - готов, 0 - не готов.
   * @example 1
   */
  isComplete: number

  /**
   * Дата создания отчета.
   * @pattern ^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}
   * @example 2022-06-28 15:23:30
   */
  creationDate: string
}

/**
 * Объект номера телефона из статистики.
 */
export interface AnalysisReportData {
  /**
   * Номер телефона.
   * @example 88005553535
   */
  phone: number

  /**
   * Дней с отрицательной статистикой.
   * @example 3
   */
  failureDays: number

  /**
   * Недоставленных сообщений.
   * @example 5
   */
  failureMessages: number
}

export type AnalysisReportResult = ApiResult & { report?: AnalysisReport }

export type AnalysisReportsResult = ApiResult & {
  list?: AnalysisReport[]
  pageInfo?: Page
}

export type AnalysisReportDataResult = ApiResult & {
  list?: AnalysisReportData[]
  pageInfo?: Page
}

export type JsonReportOnRequestResult = ApiResult & {
  list: {
    id: number
    emails: string
    subject: string
    text: string
    sentDate: string
    files: { fileId: number; name: string }[]
  }[]
  pageInfo: Page
}

/**
 * Объект для передачи информации о выбранных элементах на странице с пагинацией
 */
export interface SelectedElements {
  /** Если переносим/копируем/удаляем все элементы из списка, то проставляем true */
  all: boolean

  /**
   * Список элементов которые переносим/копируем/удаляем, заполняется есть не выбран параметр all и есть список
   * выбранных элементов для переноса
   *
   */
  include: number[]

  /**
   * Список элементов которые НЕ переносим/копируем/удаляем, в него попадают все элементы с которых
   * было снято выделение после выбора чекбокса с all
   *
   */
  exclude: number[]
}

export enum Gender {
  Men = 'men',
  Women = 'women',
  Unknown = 'unknown',
}

export enum Direction {
  Asc = 'asc',
  Desc = 'desc',
}

export enum MessageType {
  MT = 'MT',
  MO = 'MO',
}

/**
 * канал отправки сообщения.
 */
export enum Channel {
  Sms = 'sms',
  Push = 'push',
  Viber = 'viber',
  Whatsapp = 'whatsapp',
  Vk = 'vk',
  Ok = 'ok',
}

export interface CountryResult {
  /** @example 342 */
  countryId: number

  /** @example RU */
  countryCode: string

  /**
   * Телефонный код страны
   * @example 7
   */
  countryPhoneCode: number
  name: { ru: string; en: string }
}

export interface OperatorShortResult {
  /**
   * Идентификатор оператора.
   * @example Tele2
   */
  id: number

  /**
   * Имя оператора.
   * @example 2005
   */
  name: string

  /**
   * Идентификатор страны.
   * @example 342
   */
  countryId: number
  country: CountryResult
}

export type OperatorResult = ApiResult & {
  operator?: Operator
  portedFrom?: Operator
}

export enum Language {
  Ru = 'ru',
  En = 'en',
}

/**
 * Указатель типа рассылки:
 * `commaSeparated` - Список телефонов, разделенных запятой.
 * `lineSeparated` - Список телефонов в столбик, один телефон на строку.
 * `lineSeparatedFile` - Список из файла.
 * `lineSeparatedWithMessages` - Список телефон-сообщение (через пробел), по одной паре на строку.
 * `lineSeparatedWithMessagesFile` - Файл со списком телефон-сообщение (через пробел), по одной паре на строку.
 * `lineSeparatedWithParamsFile` - Файл со списком параметров сообщений (первая строка заголовки, далее данные; колонки разделены через ";").
 */
export enum PhonesType {
  CommaSeparated = 'commaSeparated',
  LineSeparated = 'lineSeparated',
  LineSeparatedFile = 'lineSeparatedFile',
  LineSeparatedWithMessages = 'lineSeparatedWithMessages',
  LineSeparatedWithMessagesFile = 'lineSeparatedWithMessagesFile',
  LineSeparatedWithParamsFile = 'lineSeparatedWithParamsFile',
}

/**
 * Указатель типа рассылки из файла:
 * `lineSeparatedFile` - Список из файла.
 * `lineSeparatedWithMessagesFile` - Файл со списком телефон-сообщение (через пробел), по одной паре на строку.
 * `lineSeparatedWithParamsFile` - Файл со списком параметров сообщений (первая строка заголовки, далее данные; колонки разделены через ";").
 */
export enum PhonesTypeFile {
  LineSeparatedFile = 'lineSeparatedFile',
  LineSeparatedWithMessagesFile = 'lineSeparatedWithMessagesFile',
  LineSeparatedWithParamsFile = 'lineSeparatedWithParamsFile',
}

/**
 * Указатель типа рассылки:
 * `commaSeparated` - Список телефонов, разделенных запятой.
 * `lineSeparated` - Список телефонов в столбик, один телефон на строку.
 * `lineSeparatedWithMessages` - Список телефон-сообщение (через пробел), по одной паре на строку.
 */
export enum PhonesTypeTextarea {
  CommaSeparated = 'commaSeparated',
  LineSeparated = 'lineSeparated',
  LineSeparatedWithMessages = 'lineSeparatedWithMessages',
}

/**
 * Список валют
 */
export enum Currencies {
  RUR = 'RUR',
  USD = 'USD',
  EUR = 'EUR',
  KZT = 'KZT',
  UAH = 'UAH',
  BYR = 'BYR',
  BYN = 'BYN',
}

export interface DepartmentShortInfo {
  /** Идентификатор департамента для использования в фильтрах журнала */
  departmentId: number

  /** Имя департамента для отображения в фильтрах журнала */
  departmentName: string

  /** Логин департамента */
  departmentLogin: string
}

/**
 * Список провайдеров электронного документооборота
 */
export enum EdoType {
  Sbis = 'sbis',
  Diadoc = 'diadoc',
}

import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  ResponseType,
} from 'axios'
import { AxiosAuthRefreshRequestConfig } from 'axios-auth-refresh/dist/model'

export type QueryParamsType = Record<string | number, any>

export interface FullRequestParams
  extends Omit<
    AxiosAuthRefreshRequestConfig,
    'data' | 'params' | 'url' | 'responseType'
  > {
  /** set parameter to `true` to include auth header into request */
  secure?: boolean
  /** request path */
  path: string
  /** content type of request body */
  type?: ContentType
  /** query params */
  query?: QueryParamsType
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType
  /** request body */
  body?: unknown
}

export type RequestParams = Omit<
  FullRequestParams,
  'body' | 'method' | 'query' | 'path'
>

export interface ApiConfig<SecurityDataType = unknown>
  extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void
  secure?: boolean
  format?: ResponseType
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance
  private securityData: SecurityDataType | null = null
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker']
  private secure?: boolean
  private format?: ResponseType

  constructor({
    securityWorker,
    secure,
    format,
    ...axiosConfig
  }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({
      ...axiosConfig,
      baseURL: axiosConfig.baseURL || 'https://api.smstraffic.ru/dev',
    })
    this.secure = secure
    this.format = format
    this.securityWorker = securityWorker
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data
  }

  public request = async <T = any, _E = any>({
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const responseFormat = format || this.format || undefined

    return this.instance.request({
      ...params,
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    })
  }
}

/**
 * @title API нового личного кабинета
 * @version 1.0-SNAPSHOT
 * @baseUrl https://api.smstraffic.ru/dev
 * @contact <tech.support@smstraffic.ru>
 */
export class Api<SecurityDataType extends unknown> {
  http: HttpClient<SecurityDataType>

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http
  }

  auth = {
    /**
     * @description Вызов данного метода валидирует логин/пароль клиента и в ответ возвращает JWT пару токенов. Либо, если у клиента включена двухфакторная авторизация, данные для последующей валидации 2fa кода
     *
     * @tags auth
     * @name login_POST
     * @summary Запрос на авторизацию в личном кабинете СМС Трафик
     * @request POST:/auth/login
     * @secure
     */
    login_POST: (data: Login, params: RequestParams = {}) =>
      this.http.request<LoginResult | TwoFactorResult, ApiResult>({
        path: `/auth/login`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Вызов данного метода валидирует логин/пароль клиента и в ответ возвращает JWT пару токенов. Либо, если у клиента включена двухфакторная авторизация, данные для последующей валидации 2fa кода
     *
     * @tags auth
     * @name loginPrivateArea_POST
     * @summary Запрос на авторизацию в личном кабинете СМС Трафик из Админки
     * @request POST:/auth/login-private-area
     * @secure
     */
    loginPrivateArea_POST: (data: LoginPrivate, params: RequestParams = {}) =>
      this.http.request<LoginResult, ApiResult>({
        path: `/auth/login-private-area`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags auth
     * @name validateSecreteCode_POST
     * @summary Запрос для валидации secreteCode который был выслан на телефон клиента
     * @request POST:/auth/validate-secrete-code
     * @secure
     */
    validateSecreteCode_POST: (data: SecreteCode, params: RequestParams = {}) =>
      this.http.request<LoginResult, ApiResult>({
        path: `/auth/validate-secrete-code`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags auth
     * @name generateSecreteCode_POST
     * @summary Запрос для повторной отправки secreteCode на абонента
     * @request POST:/auth/generate-secrete-code
     * @secure
     */
    generateSecreteCode_POST: (
      data: { sessionId: string },
      params: RequestParams = {}
    ) =>
      this.http.request<TwoFactorResult, ApiResult>({
        path: `/auth/generate-secrete-code`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  restorePassword = {
    /**
     * No description
     *
     * @tags restore-password
     * @name restore_POST
     * @summary Запрос для восстановления пароля
     * @request POST:/restore-password/restore
     * @secure
     */
    restore_POST: (
      data: { login: string; captchaCode: string },
      params: RequestParams = {}
    ) =>
      this.http.request<RestorePasswordResult, ApiResult>({
        path: `/restore-password/restore`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags restore-password
     * @name resetPassword_POST
     * @summary Запрос для изменения пароля с помощью кода восстановления
     * @request POST:/restore-password/reset-password/{secreteCode}
     * @secure
     */
    resetPassword_POST: (
      secreteCode: string,
      data: { password: string; passwordRepeated: string },
      params: RequestParams = {}
    ) =>
      this.http.request<ApiResult, ApiResult>({
        path: `/restore-password/reset-password/${secreteCode}`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  registration = {
    /**
     * No description
     *
     * @tags registration
     * @name register_POST
     * @summary Первичный запрос на регистрацию нового клиента
     * @request POST:/registration/register
     * @secure
     */
    register_POST: (
      data: { email: string; companyName: string; verifyCode: string },
      params: RequestParams = {}
    ) =>
      this.http.request<RegistrationResult, ApiResult>({
        path: `/registration/register`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags registration
     * @name confirmPhone_POST
     * @summary Привязка номера телефона к новому зарегестрированному клиенту
     * @request POST:/registration/confirm-phone/{confirmationCode}
     * @secure
     */
    confirmPhone_POST: (
      confirmationCode: string,
      data: { phone: string; verifyCode: string },
      params: RequestParams = {}
    ) =>
      this.http.request<ConfirmationCodeResult, ApiResult>({
        path: `/registration/confirm-phone/${confirmationCode}`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags registration
     * @name resetCode_POST
     * @summary Повторная кода для привязки номера телефона к зарегестрированной учетной записи
     * @request POST:/registration/reset-code/{confirmationCode}
     * @secure
     */
    resetCode_POST: (confirmationCode: string, params: RequestParams = {}) =>
      this.http.request<ConfirmationCodeResult, ApiResult>({
        path: `/registration/reset-code/${confirmationCode}`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags registration
     * @name validateCode_POST
     * @summary Валидация кода для привязки номера телефона к зарегестрированной учетной записи
     * @request POST:/registration/validate-code/{confirmationCode}
     * @secure
     */
    validateCode_POST: (
      confirmationCode: string,
      data: { phoneConfirmationCode: string },
      params: RequestParams = {}
    ) =>
      this.http.request<ApiResult, ApiResult>({
        path: `/registration/validate-code/${confirmationCode}`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
 * No description
 * 
 * @tags registration
 * @name changePassword_POST
 * @summary Запрос на изменение пароля у зарегестрированной учетной записи.
В ответе будет высланы JWT токены для доступа к личному кабинету

 * @request POST:/registration/change-password/{confirmationCode}
 * @secure
 */
    changePassword_POST: (
      confirmationCode: string,
      data: { password: string; passwordRepeated: string },
      params: RequestParams = {}
    ) =>
      this.http.request<LoginResult, ApiResult>({
        path: `/registration/change-password/${confirmationCode}`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  token = {
    /**
 * No description
 * 
 * @tags token
 * @name renew_POST
 * @summary Запрос для обновления access token. В ответ возвращается новая связка
accessToken, refreshToken, expiredIn. Старая связка больше не валидна

 * @request POST:/token/renew
 * @secure
 */
    renew_POST: (
      data: { accessToken: string; refreshToken: string },
      params: RequestParams = {}
    ) =>
      this.http.request<LoginResult, ApiResult>({
        path: `/token/renew`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags token
     * @name revoke_POST
     * @summary Запрос для отзыва токенов, используется для события logout
     * @request POST:/token/revoke
     * @secure
     */
    revoke_POST: (
      data: { accessToken: string; refreshToken: string },
      params: RequestParams = {}
    ) =>
      this.http.request<ApiResult, ApiResult>({
        path: `/token/revoke`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  profile = {
    /**
     * No description
     *
     * @tags profile
     * @name summary_GET
     * @summary Запрос на получение информации по текущей учетной записи.
     * @request GET:/profile/summary
     * @secure
     */
    summary_GET: (params: RequestParams = {}) =>
      this.http.request<ApiResult & { data?: ProfileResult }, any>({
        path: `/profile/summary`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags profile
     * @name privacy_GET
     * @summary Запрос на получение информации по настройками приватности текущей учетной записи.
     * @request GET:/profile/privacy
     * @secure
     */
    privacy_GET: (params: RequestParams = {}) =>
      this.http.request<
        ApiResult & { data?: AccountPrivacyResultNullable },
        any
      >({
        path: `/profile/privacy`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  settings = {
    /**
     * No description
     *
     * @tags settings
     * @name general_GET
     * @summary Запрос на получение информации по основным настройкам основной учетной записи.
     * @request GET:/settings/general
     * @secure
     */
    general_GET: (params: RequestParams = {}) =>
      this.http.request<ApiResult & { data?: GeneralSettingsResult }, any>({
        path: `/settings/general`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags settings
     * @name general_PUT
     * @summary Установка основных настроек основной учетной записи.
     * @request PUT:/settings/general
     * @secure
     */
    general_PUT: (data: GeneralSettings, params: RequestParams = {}) =>
      this.http.request<SuccessResult, any>({
        path: `/settings/general`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags settings
     * @name generalCorpusers_POST
     * @summary Изменение разрешенных номеров для отправки сообщений через email.
     * @request POST:/settings/general/corpusers
     * @secure
     */
    generalCorpusers_POST: (
      data: CorpusersSettings,
      params: RequestParams = {}
    ) =>
      this.http.request<SuccessResult, any>({
        path: `/settings/general/corpusers`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags settings
     * @name generalMo_PUT
     * @summary Изменение настроек входящего номера.
     * @request PUT:/settings/general/mo/{id}
     * @secure
     */
    generalMo_PUT: (
      id: number,
      data: MoNumberOptions,
      params: RequestParams = {}
    ) =>
      this.http.request<SuccessResult, any>({
        path: `/settings/general/mo/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags settings
     * @name security_GET
     * @summary Запрос на получение информации по настройкам безопасности основной учетной записи.
     * @request GET:/settings/security
     * @secure
     */
    security_GET: (params: RequestParams = {}) =>
      this.http.request<ApiResult & { data?: SecuritySettingsResult }, any>({
        path: `/settings/security`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags settings
     * @name security_PUT
     * @summary Запрос на изменение информации по настройкам безопасности основной учетной записи.
     * @request PUT:/settings/security
     * @secure
     */
    security_PUT: (data: SecuritySettings, params: RequestParams = {}) =>
      this.http.request<SuccessResult, any>({
        path: `/settings/security`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags settings
     * @name sessions_GET
     * @summary Запрос на получение информации по сессиям (пока что личного кабинета).
     * @request GET:/settings/sessions
     * @secure
     */
    sessions_GET: (
      query?: {
        page?: number
        size?: number
        field?: 'createdAt' | 'login'
        direction?: Direction
      },
      params: RequestParams = {}
    ) =>
      this.http.request<
        ApiResult & { list?: SessionResult[]; pageInfo?: Page },
        any
      >({
        path: `/settings/sessions`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags settings
     * @name sessionsDelete_POST
     * @summary Запрос на удаление сессий.
     * @request POST:/settings/sessions/delete
     * @secure
     */
    sessionsDelete_POST: (
      data: { all: boolean; include: string[]; exclude: string[] },
      params: RequestParams = {}
    ) =>
      this.http.request<AffectedRowsResult, any>({
        path: `/settings/sessions/delete`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags settings
     * @name changePassword_POST
     * @summary Изменение пароля
     * @request POST:/settings/change-password
     * @secure
     */
    changePassword_POST: (
      data: { oldPassword: string; password: string },
      params: RequestParams = {}
    ) =>
      this.http.request<SuccessResult, any>({
        path: `/settings/change-password`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  batches = {
    /**
     * No description
     *
     * @tags batches
     * @name list_GET
     * @summary Запрос на получение списка рассылок.
     * @request GET:/batches/list
     * @secure
     */
    list_GET: (
      query?: {
        dateFrom?: string
        dateTo?: string
        departmentId?: number
        batchId?: number[]
        status?: BatchStatusField[]
        name?: string
        parentBatchId?: number
        size?: number
        page?: number
        field?: 'startDate'
        direction?: Direction
      },
      params: RequestParams = {}
    ) =>
      this.http.request<
        ApiResult & { list?: BatchResult[]; pageInfo?: Page },
        any
      >({
        path: `/batches/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags batches
     * @name batches_GET_BY
     * @summary Запрос на получение информации по рассылке (батчу).
     * @request GET:/batches/{batchId}
     * @secure
     */
    batches_GET_BY: (batchId: number, params: RequestParams = {}) =>
      this.http.request<ApiResult & { data?: BatchExtResult }, ApiResult>({
        path: `/batches/${batchId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags batches
     * @name batches_PUT
     * @summary Редактирование рассылки (батча).
     * @request PUT:/batches/{batchId}
     * @secure
     */
    batches_PUT: (
      batchId: number,
      data: BatchEditForm,
      params: RequestParams = {}
    ) =>
      this.http.request<void, ApiResult>({
        path: `/batches/${batchId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags batches
     * @name numbers_GET_BY
     * @summary Запрос на получение списка успешно/неуспешно отправленных номеров у завершенного (архивного) батча в виде csv файла внутри zip.
     * @request GET:/batches/{batchId}/numbers
     * @secure
     */
    numbers_GET_BY: (
      batchId: number,
      query: { type: 'sent' | 'unsent' },
      params: RequestParams = {}
    ) =>
      this.http.request<File, void | ApiResult>({
        path: `/batches/${batchId}/numbers`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags batches
     * @name files_GET_BY
     * @summary Запрос на получение файла отложенной рассылки в виде base64.
     * @request GET:/batches/{batchId}/files/{fileId}
     * @secure
     */
    files_GET_BY: (
      batchId: number,
      fileId: number,
      params: RequestParams = {}
    ) =>
      this.http.request<ApiResult & { data?: BatchFileResponse }, any>({
        path: `/batches/${batchId}/files/${fileId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags batches
     * @name state_POST
     * @summary Изменение состояния рассылок (батчей).
     * @request POST:/batches/state
     * @secure
     */
    state_POST: (
      data: {
        state: 'start' | 'pause' | 'cancel' | 'approve'
        all?: boolean
        include?: number[]
        exclude?: number[]
        dateFrom?: string
        dateTo?: string
      },
      params: RequestParams = {}
    ) =>
      this.http.request<ApiResult & { list?: number[] }, ApiResult>({
        path: `/batches/state`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags batches
     * @name parentsList_GET
     * @summary Запрос на получение списка батч парентов, содержащих списки batch ID, которые входят в этот батч парент.
     * @request GET:/batches/parents/list
     * @secure
     */
    parentsList_GET: (
      query?: {
        departmentId?: number
        status?: BatchStatusField[]
        name?: string
        dateFrom?: string
        dateTo?: string
        size?: number
        page?: number
      },
      params: RequestParams = {}
    ) =>
      this.http.request<
        ApiResult & { list?: ParentBatchResult[]; pageInfo?: Page },
        any
      >({
        path: `/batches/parents/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags batches
     * @name parents_POST
     * @summary Изменение состояния батч парента (группы рассылок).
     * @request POST:/batches/parents/{parentBatchId}/{state}
     * @secure
     */
    parents_POST: (
      parentBatchId: number,
      state: 'start' | 'pause' | 'cancel' | 'approve',
      data: { dateFrom: string; dateTo: string },
      params: RequestParams = {}
    ) =>
      this.http.request<ApiResult & { list?: number[] }, ApiResult>({
        path: `/batches/parents/${parentBatchId}/${state}`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  statistics = {
    /**
     * No description
     *
     * @tags statistics
     * @name statistics_GET
     * @summary Запрос на получение статистики по сообщениям.
     * @request GET:/statistics
     * @secure
     */
    statistics_GET: (
      query: {
        dateFrom: string
        dateTo: string
        groupBy:
          | 'date'
          | 'originator'
          | 'channel'
          | 'messageType'
          | 'month'
          | 'countryId'
          | 'operatorId'
          | 'departmentId'
          | 'batchId'
          | 'trafficType'
        messageType?: 'MT' | 'MO'
        channel?: string
        originator?: string
        departmentId?: number
        batchId?: number[]
        countryId?: number
        operatorId?: number
        trafficType?: number
        date?: string
        month?: string
        language?: 'ru' | 'en'
      },
      params: RequestParams = {}
    ) =>
      this.http.request<
        ApiResult & { groupBy?: string; data?: StatisticsResult },
        any
      >({
        path: `/statistics`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags statistics
     * @name download_GET
     * @summary Запрос на получение статистики по сообщениям в виде файла.
     * @request GET:/statistics/download
     * @secure
     */
    download_GET: (
      query: {
        dateFrom: string
        dateTo: string
        groupBy: (
          | 'date'
          | 'originator'
          | 'channel'
          | 'messageType'
          | 'month'
          | 'countryId'
          | 'operatorId'
          | 'departmentId'
          | 'batchId'
        )[]
        messageType?: 'MT' | 'MO'
        channel?: string
        originator?: string
        departmentId?: number
        batchId?: number[]
        countryId?: number
        operatorId?: number
        date?: string
        month?: string
        language?: 'ru' | 'en'
      },
      params: RequestParams = {}
    ) =>
      this.http.request<File, ApiResult>({
        path: `/statistics/download`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags statistics
     * @name filters_GET
     * @summary Запрос на получение фильтров для страницы статистики.
     * @request GET:/statistics/filters
     * @secure
     */
    filters_GET: (params: RequestParams = {}) =>
      this.http.request<
        ApiResult & { data?: { dateFrom: string; dateTo: string } },
        any
      >({
        path: `/statistics/filters`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  templates = {
    /**
     * No description
     *
     * @tags templates
     * @name list_GET_BY
     * @summary Запрос на получение списка шаблонов по заданному фильтру
     * @request GET:/templates/{channel}/list
     * @secure
     */
    list_GET_BY: (
      channel: 'sms' | 'viber' | 'whatsapp' | 'vk',
      query?: {
        operatorId?: number
        originator?: string
        trafficType?: number
        serviceId?: string
        status?: string
        page?: number
        size?: number
      },
      params: RequestParams = {}
    ) =>
      this.http.request<TemplatesResult | MessengerTemplatesResult, ApiResult>({
        path: `/templates/${channel}/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags templates
     * @name file_GET_BY
     * @summary Запрос на получение файла с шаблонами клиента
     * @request GET:/templates/{channel}/file
     * @secure
     */
    file_GET_BY: (
      channel: 'sms' | 'viber' | 'whatsapp' | 'vk',
      query?: {
        operatorId?: number
        originator?: string
        trafficType?: number
        serviceId?: string
        status?: string
      },
      params: RequestParams = {}
    ) =>
      this.http.request<File, ApiResult>({
        path: `/templates/${channel}/file`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags templates
     * @name check_POST
     * @summary Запрос для поиска шаблонов по тексту сообщения
     * @request POST:/templates/check
     * @secure
     */
    check_POST: (
      data: {
        message: string
        operatorId?: number
        originator?: string
        trafficType?: number
        page?: number
        size?: number
      },
      params: RequestParams = {}
    ) =>
      this.http.request<TemplatesResult | MessengerTemplatesResult, ApiResult>({
        path: `/templates/check`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags templates
     * @name test_POST
     * @summary Запрос на проверку шаблона по тексту сообщения
     * @request POST:/templates/test
     * @secure
     */
    test_POST: (
      data: { template: string; message: string },
      params: RequestParams = {}
    ) =>
      this.http.request<SuccessResult, ApiResult>({
        path: `/templates/test`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
 * No description
 * 
 * @tags templates
 * @name filters_GET
 * @summary Запрос на получение всех фильтров для работы со страницами шаблонов

 * @request GET:/templates/filters
 * @secure
 */
    filters_GET: (params: RequestParams = {}) =>
      this.http.request<FiltersResult, ApiResult>({
        path: `/templates/filters`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  accounts = {
    /**
     * No description
     *
     * @tags accounts
     * @name list_GET
     * @summary Запрос на получение списка учетных записей.
     * @request GET:/accounts/list
     * @secure
     */
    list_GET: (params: RequestParams = {}) =>
      this.http.request<ApiResult & { list?: AccountShortResult[] }, any>({
        path: `/accounts/list`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags accounts
     * @name create_POST
     * @summary Создает новую учетную запись.
     * @request POST:/accounts/create
     * @secure
     */
    create_POST: (data: CreateAccount, params: RequestParams = {}) =>
      this.http.request<ApiResult & { id?: number }, any>({
        path: `/accounts/create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags accounts
     * @name accounts_GET_BY
     * @summary Запрос на получение информации по выбранной учетной записи.
     * @request GET:/accounts/{accountId}
     * @secure
     */
    accounts_GET_BY: (accountId: number, params: RequestParams = {}) =>
      this.http.request<ApiResult & { data?: AccountFullResult }, any>({
        path: `/accounts/${accountId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags accounts
     * @name accounts_PUT
     * @summary Изменение данных учетной записи.
     * @request PUT:/accounts/{accountId}
     * @secure
     */
    accounts_PUT: (
      accountId: number,
      data: EditAccount,
      params: RequestParams = {}
    ) =>
      this.http.request<SuccessResult, any>({
        path: `/accounts/${accountId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags accounts
     * @name accounts_DELETE
     * @summary Изменение статуса учетной записи на удаленная.
     * @request DELETE:/accounts/{accountId}
     * @secure
     */
    accounts_DELETE: (accountId: number, params: RequestParams = {}) =>
      this.http.request<SuccessResult, any>({
        path: `/accounts/${accountId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags accounts
     * @name changePassword_POST
     * @summary Изменить пароль учетной записи.
     * @request POST:/accounts/{accountId}/change-password
     * @secure
     */
    changePassword_POST: (
      accountId: number,
      data: { password: string },
      params: RequestParams = {}
    ) =>
      this.http.request<SuccessResult, any>({
        path: `/accounts/${accountId}/change-password`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags accounts
     * @name privacy_GET_BY
     * @summary Информация о настройках приватности учетной записи.
     * @request GET:/accounts/{accountId}/privacy
     * @secure
     */
    privacy_GET_BY: (accountId: number, params: RequestParams = {}) =>
      this.http.request<ApiResult & { data?: AccountPrivacyResult }, any>({
        path: `/accounts/${accountId}/privacy`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags accounts
     * @name privacy_PUT
     * @summary Изменение настроек приватности учетной записи.
     * @request PUT:/accounts/{accountId}/privacy
     * @secure
     */
    privacy_PUT: (
      accountId: number,
      data: AccountPrivacy,
      params: RequestParams = {}
    ) =>
      this.http.request<SuccessResult, any>({
        path: `/accounts/${accountId}/privacy`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags accounts
     * @name security_GET_BY
     * @summary Информация о настройках безопасности учетной записи.
     * @request GET:/accounts/{accountId}/security
     * @secure
     */
    security_GET_BY: (accountId: number, params: RequestParams = {}) =>
      this.http.request<ApiResult & { data?: AccountSecurityResult }, any>({
        path: `/accounts/${accountId}/security`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags accounts
     * @name security_PUT
     * @summary Изменение настроек безопасности учетной записи.
     * @request PUT:/accounts/{accountId}/security
     * @secure
     */
    security_PUT: (
      accountId: number,
      data: AccountSecurity,
      params: RequestParams = {}
    ) =>
      this.http.request<SuccessResult, any>({
        path: `/accounts/${accountId}/security`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  departments = {
    /**
     * No description
     *
     * @tags departments
     * @name list_GET
     * @summary Запрос на получение списка департаментов.
     * @request GET:/departments/list
     * @secure
     */
    list_GET: (params: RequestParams = {}) =>
      this.http.request<ApiResult & { list?: DepartmentResult[] }, any>({
        path: `/departments/list`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags departments
     * @name departments_GET_BY
     * @summary Запрос на получение информации по выбранному департаменту.
     * @request GET:/departments/{departmentId}
     * @secure
     */
    departments_GET_BY: (departmentId: number, params: RequestParams = {}) =>
      this.http.request<ApiResult & { data?: DepartmentResult }, any>({
        path: `/departments/${departmentId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags departments
     * @name departments_PUT
     * @summary Изменение данных департамента.
     * @request PUT:/departments/{departmentId}
     * @secure
     */
    departments_PUT: (
      departmentId: number,
      data: EditDepartment,
      params: RequestParams = {}
    ) =>
      this.http.request<SuccessResult, any>({
        path: `/departments/${departmentId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags departments
     * @name departments_DELETE
     * @summary Изменение статуса департамента на удаленный.
     * @request DELETE:/departments/{departmentId}
     * @secure
     */
    departments_DELETE: (departmentId: number, params: RequestParams = {}) =>
      this.http.request<SuccessResult, any>({
        path: `/departments/${departmentId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags departmentsUsers
     * @name users_GET_BY
     * @summary Запрос на получение информации по выбранному пользователю департамента.
     * @request GET:/departments/{departmentId}/users/{departmentUserId}
     * @secure
     */
    users_GET_BY: (
      departmentId: number,
      departmentUserId: number,
      params: RequestParams = {}
    ) =>
      this.http.request<ApiResult & { data?: DepUserFullResult }, any>({
        path: `/departments/${departmentId}/users/${departmentUserId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags departmentsUsers
     * @name users_PUT
     * @summary Изменение данных пользователя департамента.
     * @request PUT:/departments/{departmentId}/users/{departmentUserId}
     * @secure
     */
    users_PUT: (
      departmentId: number,
      departmentUserId: number,
      data: EditDepartmentUser,
      params: RequestParams = {}
    ) =>
      this.http.request<SuccessResult, any>({
        path: `/departments/${departmentId}/users/${departmentUserId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags departmentsUsers
     * @name users_DELETE
     * @summary Изменение статуса пользователя департамента на удаленный.
     * @request DELETE:/departments/{departmentId}/users/{departmentUserId}
     * @secure
     */
    users_DELETE: (
      departmentId: number,
      departmentUserId: number,
      params: RequestParams = {}
    ) =>
      this.http.request<SuccessResult, any>({
        path: `/departments/${departmentId}/users/${departmentUserId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags departmentsUsers
     * @name usersChangePassword_POST
     * @summary Изменить пароль пользователя департамента.
     * @request POST:/departments/{departmentId}/users/{departmentUserId}/change-password
     * @secure
     */
    usersChangePassword_POST: (
      departmentId: number,
      departmentUserId: number,
      data: { password: string },
      params: RequestParams = {}
    ) =>
      this.http.request<ApiResult, any>({
        path: `/departments/${departmentId}/users/${departmentUserId}/change-password`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags departmentsUsers
     * @name usersPrivacy_GET_BY
     * @summary Информация о настройках приватности пользователя департамента.
     * @request GET:/departments/{departmentId}/users/{departmentUserId}/privacy
     * @secure
     */
    usersPrivacy_GET_BY: (
      departmentId: number,
      departmentUserId: number,
      params: RequestParams = {}
    ) =>
      this.http.request<ApiResult & { data?: AccountPrivacyResult }, any>({
        path: `/departments/${departmentId}/users/${departmentUserId}/privacy`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags departmentsUsers
     * @name usersPrivacy_PUT
     * @summary Изменеие настроек приватности пользователя департамента.
     * @request PUT:/departments/{departmentId}/users/{departmentUserId}/privacy
     * @secure
     */
    usersPrivacy_PUT: (
      departmentId: number,
      departmentUserId: number,
      data: AccountPrivacy,
      params: RequestParams = {}
    ) =>
      this.http.request<SuccessResult, any>({
        path: `/departments/${departmentId}/users/${departmentUserId}/privacy`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags departmentsUsers
     * @name usersSecurity_GET_BY
     * @summary Информация о настройках безопасности пользователя департамента.
     * @request GET:/departments/{departmentId}/users/{departmentUserId}/security
     * @secure
     */
    usersSecurity_GET_BY: (
      departmentId: number,
      departmentUserId: number,
      params: RequestParams = {}
    ) =>
      this.http.request<ApiResult & { data?: AccountSecurityResult }, any>({
        path: `/departments/${departmentId}/users/${departmentUserId}/security`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags departmentsUsers
     * @name usersSecurity_PUT
     * @summary Изменеие настроек безопасности пользователя департамента.
     * @request PUT:/departments/{departmentId}/users/{departmentUserId}/security
     * @secure
     */
    usersSecurity_PUT: (
      departmentId: number,
      departmentUserId: number,
      data: AccountSecurity,
      params: RequestParams = {}
    ) =>
      this.http.request<SuccessResult, any>({
        path: `/departments/${departmentId}/users/${departmentUserId}/security`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  roles = {
    /**
     * No description
     *
     * @tags roles
     * @name list_GET
     * @summary Запрос на получение списка ролей.
     * @request GET:/roles/list
     * @secure
     */
    list_GET: (params: RequestParams = {}) =>
      this.http.request<ApiResult & { list?: RoleResult[] }, any>({
        path: `/roles/list`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  drafts = {
    /**
     * No description
     *
     * @tags drafts
     * @name list_GET_BY
     * @summary Получить список черновиков
     * @request GET:/drafts/{channel}/list
     * @secure
     */
    list_GET_BY: (
      channel: 'sms' | 'viber' | 'whatsapp' | 'vk' | 'all',
      query?: {
        filter?: string
        page?: number
        size?: number
        field?: 'addDate'
        direction?: Direction
      },
      params: RequestParams = {}
    ) =>
      this.http.request<DraftsResult, ApiResult>({
        path: `/drafts/${channel}/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags drafts
     * @name delete_POST
     * @summary Удалить список черновиков
     * @request POST:/drafts/{channel}/delete
     * @secure
     */
    delete_POST: (
      channel: 'sms' | 'viber' | 'whatsapp' | 'vk' | 'all',
      data: { selectedElements: SelectedElements },
      params: RequestParams = {}
    ) =>
      this.http.request<AffectedRowsResult, ApiResult>({
        path: `/drafts/${channel}/delete`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  draft = {
    /**
     * No description
     *
     * @tags drafts
     * @name create_POST
     * @summary Создать черновик
     * @request POST:/draft/create
     * @secure
     */
    create_POST: (
      data: {
        name: string
        channel: 'sms' | 'viber' | 'whatsapp' | 'vk'
        text: string
      },
      params: RequestParams = {}
    ) =>
      this.http.request<DraftResult, ApiResult>({
        path: `/draft/create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags drafts
     * @name draft_GET_BY
     * @summary Получить черновик по id
     * @request GET:/draft/{draftId}
     * @secure
     */
    draft_GET_BY: (draftId: number, params: RequestParams = {}) =>
      this.http.request<DraftResult, ApiResult>({
        path: `/draft/${draftId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags drafts
     * @name draft_PUT
     * @summary Редактировать черновик по id
     * @request PUT:/draft/{draftId}
     * @secure
     */
    draft_PUT: (
      draftId: number,
      data: {
        name: string
        channel: 'sms' | 'viber' | 'whatsapp' | 'vk'
        text: string
      },
      params: RequestParams = {}
    ) =>
      this.http.request<DraftResult, ApiResult>({
        path: `/draft/${draftId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags drafts
     * @name draft_DELETE
     * @summary Удалить черновики по id
     * @request DELETE:/draft/{draftId}
     * @secure
     */
    draft_DELETE: (draftId: number, params: RequestParams = {}) =>
      this.http.request<SuccessResult, ApiResult>({
        path: `/draft/${draftId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  groups = {
    /**
     * No description
     *
     * @tags groups
     * @name list_GET
     * @summary Получить список списков для рассылок
     * @request GET:/groups/list
     * @secure
     */
    list_GET: (
      query?: {
        filter?: string
        page?: number
        size?: number
        field?: 'creationDate' | 'groupId' | 'groupName'
        direction?: Direction
      },
      params: RequestParams = {}
    ) =>
      this.http.request<GroupsResult, ApiResult>({
        path: `/groups/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags groups
     * @name delete_POST
     * @summary Удаление списка списков для рассылок
     * @request POST:/groups/delete
     * @secure
     */
    delete_POST: (
      data: { selectedElements: SelectedElements },
      params: RequestParams = {}
    ) =>
      this.http.request<SuccessResult, ApiResult>({
        path: `/groups/delete`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags groups
     * @name getOperatorByPhone_GET
     * @summary Поиск оператора по номеру телефона
     * @request GET:/groups/get-operator-by-phone
     * @secure
     */
    getOperatorByPhone_GET: (
      query: { phone: number },
      params: RequestParams = {}
    ) =>
      this.http.request<OperatorResult, ApiResult>({
        path: `/groups/get-operator-by-phone`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  group = {
    /**
     * No description
     *
     * @tags group
     * @name create_POST
     * @summary Создать список для рассылок
     * @request POST:/group/create
     * @secure
     */
    create_POST: (
      data: {
        groupName: string
        viewForAll: boolean
        viewOptional: boolean
        congratulate?: CongratulateData
      },
      params: RequestParams = {}
    ) =>
      this.http.request<GroupResult, ApiResult>({
        path: `/group/create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags group
     * @name group_GET_BY
     * @summary Получить список для рассылок по id
     * @request GET:/group/{id}
     * @secure
     */
    group_GET_BY: (id: number, params: RequestParams = {}) =>
      this.http.request<GroupResultExt, ApiResult>({
        path: `/group/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags group
     * @name group_PUT
     * @summary Редактировать список для рассылок по id
     * @request PUT:/group/{id}
     * @secure
     */
    group_PUT: (
      id: number,
      data: {
        groupName: string
        congratulate?: CongratulateData
        viewForAll: boolean
        viewOptional: boolean
      },
      params: RequestParams = {}
    ) =>
      this.http.request<GroupResult, ApiResult>({
        path: `/group/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags group
     * @name group_DELETE
     * @summary Удалить список для рассылок по id
     * @request DELETE:/group/{id}
     * @secure
     */
    group_DELETE: (id: number, params: RequestParams = {}) =>
      this.http.request<SuccessResult, ApiResult>({
        path: `/group/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags group-members
     * @name members_GET_BY
     * @summary Получить абонентов из списка для рассылок по его id
     * @request GET:/group/{id}/members
     * @secure
     */
    members_GET_BY: (
      id: number,
      query?: {
        phone?: number
        memberName?: string
        gender?: Gender
        birthday?: string
        page?: number
        size?: number
        field?: 'phone' | 'memberName' | 'birthday' | 'addDate'
        direction?: Direction
      },
      params: RequestParams = {}
    ) =>
      this.http.request<GroupMembersResult, ApiResult>({
        path: `/group/${id}/members`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags group-members
     * @name members_DELETE
     * @summary Удалить всех абонентов из списка по его id
     * @request DELETE:/group/{id}/members
     * @secure
     */
    members_DELETE: (id: number, params: RequestParams = {}) =>
      this.http.request<AffectedRowsResult, ApiResult>({
        path: `/group/${id}/members`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags group-members
     * @name membersAddList_POST
     * @summary Добавить абонентов в список
     * @request POST:/group/{id}/members/addList
     * @secure
     */
    membersAddList_POST: (
      id: number,
      data: {
        members: {
          phone: number
          name?: string
          birthday?: string
          gender?: Gender
          comment?: boolean
        }[]
      },
      params: RequestParams = {}
    ) =>
      this.http.request<AffectedRowsResult, ApiResult>({
        path: `/group/${id}/members/addList`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags group-members
     * @name membersLoadFromFile_POST
     * @summary Добавить абонентов в список с помощью файла
     * @request POST:/group/{id}/members/loadFromFile
     * @secure
     */
    membersLoadFromFile_POST: (
      id: number,
      data: {
        file?: File
        charset?: 'windows-1251' | 'utf-8'
        delimiter?: string
      },
      params: RequestParams = {}
    ) =>
      this.http.request<SuccessResult, ApiResult>({
        path: `/group/${id}/members/loadFromFile`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags group-members
     * @name membersDeleteList_POST
     * @summary Удалить список абонентов из списка для рассылок по его id
     * @request POST:/group/{id}/members/deleteList
     * @secure
     */
    membersDeleteList_POST: (
      id: number,
      data: { selectedElements: SelectedElements },
      params: RequestParams = {}
    ) =>
      this.http.request<AffectedRowsResult, ApiResult>({
        path: `/group/${id}/members/deleteList`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags group-members
     * @name membersMoveMembers_POST
     * @summary Перенести абонентов из одного списка в другой
     * @request POST:/group/{id}/members/moveMembers
     * @secure
     */
    membersMoveMembers_POST: (
      id: number,
      data: { groupIds: number[]; selectedElements: SelectedElements },
      params: RequestParams = {}
    ) =>
      this.http.request<AffectedRowsResult, ApiResult>({
        path: `/group/${id}/members/moveMembers`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags group-members
     * @name membersCopyMembers_POST
     * @summary Копировать абонентов из одного списка в другой
     * @request POST:/group/{id}/members/copyMembers
     * @secure
     */
    membersCopyMembers_POST: (
      id: number,
      data: { groupIds: number[]; selectedElements: SelectedElements },
      params: RequestParams = {}
    ) =>
      this.http.request<AffectedRowsResult, ApiResult>({
        path: `/group/${id}/members/copyMembers`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags group-members
     * @name membersDeleteListFromList_POST
     * @summary Удалить список из списка
     * @request POST:/group/{id}/members/deleteListFromList
     * @secure
     */
    membersDeleteListFromList_POST: (
      id: number,
      data: { groupIds: number[] },
      params: RequestParams = {}
    ) =>
      this.http.request<AffectedRowsResult, ApiResult>({
        path: `/group/${id}/members/deleteListFromList`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags group-member
     * @name member_POST
     * @summary Создать абонента в списке для рассылок
     * @request POST:/group/{id}/member
     * @secure
     */
    member_POST: (
      id: number,
      data: {
        phone: number
        name?: string
        birthday?: string
        gender?: Gender
        comment?: boolean
      },
      params: RequestParams = {}
    ) =>
      this.http.request<GroupMemberResult, ApiResult>({
        path: `/group/${id}/member`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags group-member
     * @name member_GET_BY
     * @summary Получить абонента из списка для рассылок по номеру телефона
     * @request GET:/group/{id}/member/{phone}
     * @secure
     */
    member_GET_BY: (id: number, phone: number, params: RequestParams = {}) =>
      this.http.request<GroupMemberResult, ApiResult>({
        path: `/group/${id}/member/${phone}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags group-member
     * @name member_DELETE
     * @summary Удалить абонента из списка для рассылок по номеру телефона
     * @request DELETE:/group/{id}/member/{phone}
     * @secure
     */
    member_DELETE: (id: number, phone: number, params: RequestParams = {}) =>
      this.http.request<SuccessResult, ApiResult>({
        path: `/group/${id}/member/${phone}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags group-member
     * @name member_PUT
     * @summary Редактировать абонента из списка для рассылок по номеру телефона
     * @request PUT:/group/{id}/member/{phone}
     * @secure
     */
    member_PUT: (
      id: number,
      phone: number,
      data: {
        phone: number
        name?: string
        birthday?: string
        gender?: Gender
        comment?: string
      },
      params: RequestParams = {}
    ) =>
      this.http.request<GroupMemberResult, ApiResult>({
        path: `/group/${id}/member/${phone}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  countries = {
    /**
     * No description
     *
     * @tags countries
     * @name list_GET
     * @summary Запрос на получение списка стран.
     * @request GET:/countries/list
     * @secure
     */
    list_GET: (params: RequestParams = {}) =>
      this.http.request<ApiResult & { list?: CountryResult[] }, any>({
        path: `/countries/list`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  operators = {
    /**
     * No description
     *
     * @tags operators
     * @name list_GET
     * @summary Запрос на получение списка операторов.
     * @request GET:/operators/list
     * @secure
     */
    list_GET: (query?: { countryId?: number }, params: RequestParams = {}) =>
      this.http.request<ApiResult & { list?: OperatorShortResult[] }, any>({
        path: `/operators/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  timezones = {
    /**
     * No description
     *
     * @tags timezones
     * @name list_GET
     * @summary Запрос на получение списка таймзон.
     * @request GET:/timezones/list
     * @secure
     */
    list_GET: (
      query?: { countryId?: number; name?: string },
      params: RequestParams = {}
    ) =>
      this.http.request<ApiResult & { list?: TimezoneResult[] }, any>({
        path: `/timezones/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags timezones
     * @name countries_GET
     * @summary Запрос на получение списка стран для заполнения селекта выбора часового пояса.
     * @request GET:/timezones/countries
     * @secure
     */
    countries_GET: (params: RequestParams = {}) =>
      this.http.request<ApiResult & { list?: CountryResult[] }, any>({
        path: `/timezones/countries`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  messages = {
    /**
     * @description Создание задания для формирования отчета по сообщениям клиента
     *
     * @tags messages
     * @name reportCreate_POST
     * @request POST:/messages/report/create
     * @secure
     */
    reportCreate_POST: (
      data: {
        from: string
        to: string
        originator?: string
        type?: MessageType
        status?: 'delivered' | 'not_delivered' | 'read' | 'clicked' | 'checked'
        phones?: string[]
        channels?: Channel[]
        channel?: Channel
        departmentIds?: number[]
        batchIds?: number[]
        fields: (
          | 'id'
          | 'phone'
          | 'message'
          | 'submissionDate'
          | 'sendDate'
          | 'deliveryDate'
          | 'readDate'
          | 'channel'
          | 'status'
          | 'cost'
          | 'originator'
          | 'ip'
          | 'error'
          | 'operatorName'
          | 'countryName'
          | 'batchName'
          | 'departmentName'
        )[]
        format: 'csv'
        fileEncoding?: 'CP-1251' | 'UTF-8'
        email: string
      },
      params: RequestParams = {}
    ) =>
      this.http.request<ReportResult, ApiResult>({
        path: `/messages/report/create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Возвращает список заказанных клиентом отчетов
     *
     * @tags messages
     * @name reports_GET
     * @request GET:/messages/reports
     * @secure
     */
    reports_GET: (
      query?: { page?: number; size?: number },
      params: RequestParams = {}
    ) =>
      this.http.request<ReportsResult, ApiResult>({
        path: `/messages/reports`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Возвращает файл с отчетом по его идентификатору
     *
     * @tags messages
     * @name report_GET_BY
     * @request GET:/messages/{reportId}/report
     * @secure
     */
    report_GET_BY: (reportId: number, params: RequestParams = {}) =>
      this.http.request<File, ApiResult>({
        path: `/messages/${reportId}/report`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * @description Возвращает список событий для выбранного отчета
     *
     * @tags messages
     * @name reportLog_GET_BY
     * @request GET:/messages/{reportId}/report/log
     * @secure
     */
    reportLog_GET_BY: (
      reportId: number,
      query?: { page?: number; size?: number },
      params: RequestParams = {}
    ) =>
      this.http.request<LogsReportsEventsResult, ApiResult>({
        path: `/messages/${reportId}/report/log`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags messages
     * @name getMessages
     * @request GET:/messages/log
     * @secure
     */
    getMessages: (
      query?: {
        from?: string
        to?: string
        originator?: string
        type?: MessageType
        status?: 'delivered' | 'not_delivered' | 'read' | 'clicked' | 'checked'
        phones?: string[]
        channels?: Channel
        departmentIds?: number[]
        batchIds?: number[]
        fields?: (
          | 'id'
          | 'phone'
          | 'message'
          | 'submissionDate'
          | 'sendDate'
          | 'deliveryDate'
          | 'readDate'
          | 'channel'
          | 'status'
          | 'cost'
          | 'originator'
          | 'ip'
          | 'error'
          | 'operatorName'
          | 'countryName'
          | 'batchName'
          | 'departmentName'
          | 'trafficType'
        )[]
        page?: number
        size?: number
        direction?: Direction
      },
      params: RequestParams = {}
    ) =>
      this.http.request<MessagesResult, ApiResult>({
        path: `/messages/log`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Возвращает список батчей для отображения в фильтрах журнала/статистики
     *
     * @tags messages
     * @name filtersBatches_GET
     * @request GET:/messages/filters/batches
     * @secure
     */
    filtersBatches_GET: (
      query: { from: string; to: string; name?: string },
      params: RequestParams = {}
    ) =>
      this.http.request<BatchesFilterResult, ApiResult>({
        path: `/messages/filters/batches`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Возвращает список доступных фильтров для отображения в формах журнала/статистики
     *
     * @tags messages
     * @name filters_GET
     * @request GET:/messages/filters
     * @secure
     */
    filters_GET: (params: RequestParams = {}) =>
      this.http.request<MessagesFiltersResult, ApiResult>({
        path: `/messages/filters`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  originators = {
    /**
     * No description
     *
     * @tags originators
     * @name originators_GET
     * @summary Получить список имен отправителя
     * @request GET:/originators
     * @secure
     */
    originators_GET: (
      query?: {
        originator?: string
        operatorId?: number
        status?: OriginatorStatus
        page?: number
        size?: number
        field?: 'operatorId' | 'status' | 'addDate'
        direction?: Direction
      },
      params: RequestParams = {}
    ) =>
      this.http.request<OriginatorsResult, ApiResult>({
        path: `/originators`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags originators
     * @name originators_POST
     * @summary Добавление списка имен отправителей из файла.
     * @request POST:/originators
     * @secure
     */
    originators_POST: (
      data: { file: File; operatorId: number },
      params: RequestParams = {}
    ) =>
      this.http.request<OriginatorsResult, ApiResult>({
        path: `/originators`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags originators
     * @name filters_GET
     * @summary Запрос на получение доступных фильтров на странице с именами отправителя.
     * @request GET:/originators/filters
     * @secure
     */
    filters_GET: (params: RequestParams = {}) =>
      this.http.request<OriginatorsFiltersResult, ApiResult>({
        path: `/originators/filters`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  originator = {
    /**
     * No description
     *
     * @tags originators
     * @name create_POST
     * @summary Добавление одного имени отправителя.
     * @request POST:/originator/create
     * @secure
     */
    create_POST: (
      data: {
        originator: string
        companyName: string
        inn: string
        operatorId: number
      },
      params: RequestParams = {}
    ) =>
      this.http.request<OriginatorResult, ApiResult>({
        path: `/originator/create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags originators
     * @name originator_GET_BY
     * @summary Получить имея отправителя по его идентификатору.
     * @request GET:/originator/{originatorId}
     * @secure
     */
    originator_GET_BY: (originatorId: number, params: RequestParams = {}) =>
      this.http.request<OriginatorResult, ApiResult>({
        path: `/originator/${originatorId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags originators
     * @name originator_DELETE
     * @summary Удаление имени отправителя.
     * @request DELETE:/originator/{originatorId}
     * @secure
     */
    originator_DELETE: (originatorId: number, params: RequestParams = {}) =>
      this.http.request<SuccessResult, ApiResult>({
        path: `/originator/${originatorId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  eventLogs = {
    /**
     * No description
     *
     * @tags event-logs
     * @name eventLogs_GET
     * @summary Получить список событий.
     * @request GET:/event-logs
     * @secure
     */
    eventLogs_GET: (
      query?: {
        from?: string
        to?: string
        departmentId?: number
        departmentUserId?: number
        page?: number
        size?: number
        field?: 'recordDate' | 'ip' | 'eventDesc' | 'login'
        direction?: Direction
      },
      params: RequestParams = {}
    ) =>
      this.http.request<EventLogsResult, ApiResult>({
        path: `/event-logs`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags event-logs
     * @name file_GET
     * @summary Запрос на получение файла c запрошенными событиями
     * @request GET:/event-logs/file
     * @secure
     */
    file_GET: (
      query?: {
        from?: string
        to?: string
        departmentId?: number
        departmentUserId?: number
      },
      params: RequestParams = {}
    ) =>
      this.http.request<File, ApiResult>({
        path: `/event-logs/file`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),
  }
  accounting = {
    /**
     * No description
     *
     * @tags accounting
     * @name info_GET
     * @summary Метод для получения реквизитов учетной записи
     * @request GET:/accounting/info
     * @secure
     */
    info_GET: (params: RequestParams = {}) =>
      this.http.request<AccountingInfoResult, ApiResult>({
        path: `/accounting/info`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags accounting
     * @name transactions_GET
     * @summary Метод для получения списка начислений клиента
     * @request GET:/accounting/transactions
     * @secure
     */
    transactions_GET: (
      query?: {
        from?: string
        to?: string
        transactionType?: TransactionType
        page?: number
        size?: number
        field?:
          | 'transactionDate'
          | 'transactionType'
          | 'deltaRur'
          | 'deltaCredits'
        direction?: Direction
      },
      params: RequestParams = {}
    ) =>
      this.http.request<TransactionsResult, ApiResult>({
        path: `/accounting/transactions`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags accounting
     * @name transaction_GET_BY
     * @summary Метод для получения одного начисления по его идентификатору
     * @request GET:/accounting/transaction/{transactionId}
     * @secure
     */
    transaction_GET_BY: (transactionId: number, params: RequestParams = {}) =>
      this.http.request<TransactionResult, ApiResult>({
        path: `/accounting/transaction/${transactionId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags accounting
     * @name documents_GET
     * @summary Метод для получения списка первичных документов клиента
     * @request GET:/accounting/documents
     * @secure
     */
    documents_GET: (
      query?: {
        from?: string
        to?: string
        documentStatus?: DocumentStatus
        page?: number
        size?: number
        field?: 'enterDate' | 'recordId' | 'sumSNds'
        direction?: Direction
      },
      params: RequestParams = {}
    ) =>
      this.http.request<DocumentsResult, ApiResult>({
        path: `/accounting/documents`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags accounting
     * @name documents_POST
     * @summary Метод для выставления себе счетов в личном кабинете
     * @request POST:/accounting/documents
     * @secure
     */
    documents_POST: (data: { amount: number }, params: RequestParams = {}) =>
      this.http.request<DocumentResult, ApiResult>({
        path: `/accounting/documents`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags accounting
     * @name document_GET_BY
     * @summary Метод вернет уже готовый html или pdf который можно сразу показывать пользователю
     * @request GET:/accounting/document/{documentId}
     * @secure
     */
    document_GET_BY: (
      documentId: number,
      query: { documentType: DocumentType; documentFormat: 'pdf' | 'html' },
      params: RequestParams = {}
    ) =>
      this.http.request<File, ApiResult>({
        path: `/accounting/document/${documentId}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags accounting
     * @name documentsPrintAll_POST
     * @summary Вывод на печать списка запрошенный документов
     * @request POST:/accounting/documents/print-all
     * @secure
     */
    documentsPrintAll_POST: (
      data: {
        from?: string
        to?: string
        documentStatus?: DocumentStatus
        format?: 'pdf' | 'html'
        selectedElements: SelectedElements
      },
      params: RequestParams = {}
    ) =>
      this.http.request<File, ApiResult>({
        path: `/accounting/documents/print-all`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags accounting
     * @name documentEdo_GET_BY
     * @summary Метод вернет всю необходимую информацию для отправки документа в edo
     * @request GET:/accounting/document/{documentId}/edo
     * @secure
     */
    documentEdo_GET_BY: (documentId: number, params: RequestParams = {}) =>
      this.http.request<InitEdoResult, ApiResult>({
        path: `/accounting/document/${documentId}/edo`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags accounting
     * @name documentEdo_POST
     * @summary Метод для отправки документа в edo
     * @request POST:/accounting/document/{documentId}/edo
     * @secure
     */
    documentEdo_POST: (
      documentId: number,
      data: { edoDepartment: string; edoOrganisation: string },
      params: RequestParams = {}
    ) =>
      this.http.request<CreateEdoResult, ApiResult>({
        path: `/accounting/document/${documentId}/edo`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  documentation = {
    /**
     * No description
     *
     * @tags documentation
     * @name documentation_GET_BY
     * @summary Метод вернет файл с технической документацией клиента
     * @request GET:/documentation/{documentType}
     * @secure
     */
    documentation_GET_BY: (
      documentType: TechDocumentType,
      query: { language: Language },
      params: RequestParams = {}
    ) =>
      this.http.request<File, ApiResult>({
        path: `/documentation/${documentType}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags documentation
     * @name list_GET_BY
     * @summary Список доступных документов для скачивания
     * @request GET:/documentation/list/{domain}
     * @secure
     */
    list_GET_BY: (domain: Providers, params: RequestParams = {}) =>
      this.http.request<ApiResult & { list?: TechDocument[] }, ApiResult>({
        path: `/documentation/list/${domain}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags documentation
     * @name list_GET
     * @summary Список доступных документов для скачивания
     * @request GET:/documentation/list
     * @secure
     */
    list_GET: (params: RequestParams = {}) =>
      this.http.request<ApiResult & { list?: TechDocument[] }, ApiResult>({
        path: `/documentation/list`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  tasks = {
    /**
     * No description
     *
     * @tags tasks
     * @name tasks_GET
     * @summary Получить список текущих задач отображаемых в фоне
     * @request GET:/tasks
     * @secure
     */
    tasks_GET: (
      query?: {
        field?: ''
        direction?: Direction
        page?: number
        size?: number
      },
      params: RequestParams = {}
    ) =>
      this.http.request<TasksResult, ApiResult>({
        path: `/tasks`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags tasks
     * @name tasks_DELETE
     * @summary Метод для удаления задачи
     * @request DELETE:/tasks/{taskId}
     * @secure
     */
    tasks_DELETE: (taskId: number, params: RequestParams = {}) =>
      this.http.request<SuccessResult, ApiResult>({
        path: `/tasks/${taskId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  files = {
    /**
     * No description
     *
     * @tags files
     * @name subscribersUpload_POST
     * @summary Загрузка файла с абонентами для рассылки
     * @request POST:/files/subscribers/upload
     * @secure
     */
    subscribersUpload_POST: (
      data: {
        file: File
        phonesType: PhonesTypeFile
        charset: 'windows-1251' | 'utf-8'
      },
      params: RequestParams = {}
    ) =>
      this.http.request<
        ApiResult & { data?: FileSubscribersUploadResult },
        any
      >({
        path: `/files/subscribers/upload`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags files
     * @name subscribersDownload_GET
     * @summary Скачивание загруженного файла.
     * @request GET:/files/subscribers/download
     * @secure
     */
    subscribersDownload_GET: (
      query: { fileName: string },
      params: RequestParams = {}
    ) =>
      this.http.request<File, void>({
        path: `/files/subscribers/download`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags files
     * @name imagesUpload_POST
     * @summary Загрузка файла-картинки для рассылки
     * @request POST:/files/images/upload
     * @secure
     */
    imagesUpload_POST: (
      data: { file: File },
      query?: { useUrl?: boolean },
      params: RequestParams = {}
    ) =>
      this.http.request<ApiResult & { data?: FileUploadResult }, any>({
        path: `/files/images/upload`,
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),
  }
  send = {
    /**
     * No description
     *
     * @tags send
     * @name postSend
     * @summary Отправка смс сообщений из формы или файла.
     * @request POST:/send/sms
     * @secure
     */
    postSend: (data: SimpleSendSmsForm, params: RequestParams = {}) =>
      this.http.request<
        ApiResult & { data?: SendInfoResult | EstimatedCostResult },
        ApiResult
      >({
        path: `/send/sms`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags send
     * @name smart_POST
     * @summary Отправка smart сообщений из формы или файла.
     * @request POST:/send/smart
     * @secure
     */
    smart_POST: (data: SimpleSendSmartForm, params: RequestParams = {}) =>
      this.http.request<ApiResult & { data?: SendInfoResult }, ApiResult>({
        path: `/send/smart`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags send
     * @name groupsSms_POST
     * @summary Отправка смс сообщений для выбранных списков для рассылок.
     * @request POST:/send/groups/sms
     * @secure
     */
    groupsSms_POST: (data: GroupsSendSmsForm, params: RequestParams = {}) =>
      this.http.request<
        ApiResult & { data?: SendInfoResult | EstimatedCostResult },
        ApiResult
      >({
        path: `/send/groups/sms`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags send
     * @name groupsSmart_POST
     * @summary Отправка smart сообщений для выбранных списков для рассылок.
     * @request POST:/send/groups/smart
     * @secure
     */
    groupsSmart_POST: (data: GroupsSendSmartForm, params: RequestParams = {}) =>
      this.http.request<ApiResult & { data?: SendInfoResult }, ApiResult>({
        path: `/send/groups/smart`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags send
     * @name groupSms_POST
     * @summary Отправка смс сообщений для выбранных номеров из конкретного списка для рассылок.
     * @request POST:/send/group/{groupId}/sms
     * @secure
     */
    groupSms_POST: (
      groupId: number,
      data: GroupsSendSmsForm,
      params: RequestParams = {}
    ) =>
      this.http.request<
        ApiResult & { data?: SendInfoResult | EstimatedCostResult },
        ApiResult
      >({
        path: `/send/group/${groupId}/sms`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags send
     * @name groupSmart_POST
     * @summary Отправка smart сообщений для выбранных номеров из конкретного списка для рассылок.
     * @request POST:/send/group/{groupId}/smart
     * @secure
     */
    groupSmart_POST: (
      groupId: number,
      data: GroupsSendSmartForm,
      params: RequestParams = {}
    ) =>
      this.http.request<ApiResult & { data?: SendInfoResult }, ApiResult>({
        path: `/send/group/${groupId}/smart`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags send
     * @name filters_GET
     * @summary Фильтры для отправки сообщений
     * @request GET:/send/filters
     * @secure
     */
    filters_GET: (params: RequestParams = {}) =>
      this.http.request<ApiResult & { data?: SendFiltersForm }, ApiResult>({
        path: `/send/filters`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags send
     * @name subscribersCount_POST
     * @summary Получить список номеров из текстового
     * @request POST:/send/subscribers-count
     * @secure
     */
    subscribersCount_POST: (
      data: { phonesType: PhonesTypeTextarea; phonesInput: any },
      params: RequestParams = {}
    ) =>
      this.http.request<ApiResult & { phones?: number }, ApiResult>({
        path: `/send/subscribers-count`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags send
     * @name subscribersCountGroups_POST
     * @summary Получить список номеров из выборки списков для рассылок
     * @request POST:/send/subscribers-count/groups
     * @secure
     */
    subscribersCountGroups_POST: (
      data: { selectedElements: SelectedElements },
      params: RequestParams = {}
    ) =>
      this.http.request<ApiResult & { phones?: number }, ApiResult>({
        path: `/send/subscribers-count/groups`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags send
     * @name subscribersCountMembers_POST
     * @summary Получить список номеров выборки номеров из списка для рассылок
     * @request POST:/send/subscribers-count/{groupId}/members
     * @secure
     */
    subscribersCountMembers_POST: (
      groupId: number,
      data: { selectedElements: SelectedElements },
      params: RequestParams = {}
    ) =>
      this.http.request<ApiResult & { phones?: number }, ApiResult>({
        path: `/send/subscribers-count/${groupId}/members`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  home = {
    /**
     * No description
     *
     * @tags home
     * @name numbers_GET
     * @summary Получить данные для виджета "История в цифрах"
     * @request GET:/home/numbers
     * @secure
     */
    numbers_GET: (params: RequestParams = {}) =>
      this.http.request<HomeNumbersResult, ApiResult>({
        path: `/home/numbers`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  deliveryAnalysis = {
    /**
     * No description
     *
     * @tags delivery-analysis
     * @name reports_GET
     * @summary Получить список готовых отчетов.
     * @request GET:/delivery-analysis/reports
     * @secure
     */
    reports_GET: (
      query?: {
        departmentId?: number
        failureInterval?: number
        isComplete?: number
        field?: 'startDate' | 'endDate' | 'failureInterval' | 'departmentId'
        direction?: Direction
        page?: number
        size?: number
      },
      params: RequestParams = {}
    ) =>
      this.http.request<AnalysisReportsResult, ApiResult>({
        path: `/delivery-analysis/reports`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags delivery-analysis
     * @name reportCreate_POST
     * @summary Создать отчет для выгрузки.
     * @request POST:/delivery-analysis/report/create
     * @secure
     */
    reportCreate_POST: (
      data: {
        startDate?: string
        endDate?: string
        failureInterval?: number
        departmentId?: number
        email: string
      },
      params: RequestParams = {}
    ) =>
      this.http.request<SuccessResult, ApiResult>({
        path: `/delivery-analysis/report/create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags delivery-analysis
     * @name report_GET_BY
     * @summary Получить список телефонов из готового отчета
     * @request GET:/delivery-analysis/report/{reportId}
     * @secure
     */
    report_GET_BY: (
      reportId: number,
      query?: {
        field?: 'phone' | 'failureDays' | 'failureMessages'
        direction?: Direction
        page?: number
        size?: number
      },
      params: RequestParams = {}
    ) =>
      this.http.request<AnalysisReportDataResult, ApiResult>({
        path: `/delivery-analysis/report/${reportId}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags delivery-analysis
     * @name reportDownload_GET_BY
     * @summary Получить файл со списоком телефонов из готового отчета
     * @request GET:/delivery-analysis/report/{reportId}/download
     * @secure
     */
    reportDownload_GET_BY: (
      reportId: number,
      query?: {
        field?: 'phone' | 'failureDays' | 'failureMessages'
        direction?: Direction
      },
      params: RequestParams = {}
    ) =>
      this.http.request<File, ApiResult>({
        path: `/delivery-analysis/report/${reportId}/download`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),
  }
  reportsOnRequest = {
    /**
     * No description
     *
     * @tags reports-on-request
     * @name list_GET
     * @summary Запрос на получение списка клиентских отчетов по запросу
     * @request GET:/reports-on-request/list
     * @secure
     */
    list_GET: (
      query?: { page?: number; size?: number },
      params: RequestParams = {}
    ) =>
      this.http.request<JsonReportOnRequestResult, ApiResult>({
        path: `/reports-on-request/list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags reports-on-request
     * @name reportsOnRequest_GET_BY
     * @summary Запрос на получение файла из отчета для клиента
     * @request GET:/reports-on-request/{reportId}/{fileId}
     * @secure
     */
    reportsOnRequest_GET_BY: (
      reportId: number,
      fileId: number,
      params: RequestParams = {}
    ) =>
      this.http.request<File, ApiResult>({
        path: `/reports-on-request/${reportId}/${fileId}`,
        method: 'GET',
        secure: true,
        ...params,
      }),
  }
}
