<template>
  <Table
    v-model:modelSelection="selectedElements"
    :data="data"
    :columns="columns"
    :rowKey="rowKey"
  >
    <template #column:startDate="{ item }">
      <TableDate :date="[item.startDate, item.estimatedEndDate]" />
    </template>
    <template #column:sentPhones="{ item }">
      <ProgressBar
        :sentPhones="item.sentPhones"
        :totalPhones="item.totalPhones"
      />
    </template>
    <template #column:originator="{ item }">
      <Text :data-testid="`${item.name}`">
        {{ item.originator.originator ?? defaultOriginator }}
      </Text>
    </template>
    <template #column:status="{ item }">
      <Status :status="item.status" />
    </template>
  </Table>
</template>

<script setup lang="ts">
import type { TableTypes } from '@smst/ui'
import { Table, Text } from '@smst/ui'
import type { PropType } from 'vue'
import { computed } from 'vue'

import type { BatchResult } from '@/api/__generated__/api.schema'
import TableDate from '@/components/TableDate/TableDate.vue'
import { useComponentI18n } from '@/hooks/useComponentI18n'

import Status from '../Status/Status.vue'

import ProgressBar from './components/ProgressBar/ProgressBar.vue'

const props = defineProps({
  modelSelection: {
    type: Object as PropType<TableTypes.ModelSelection>,
  },
  data: {
    type: Array as PropType<BatchResult[]>,
    required: true,
  },
  defaultOriginator: String,
})

const tTable = useComponentI18n('batches.table.columns')

const rowKey: keyof BatchResult = 'batchId'

const columns: Array<TableTypes.Column<BatchResult>> = [
  {
    name: tTable('interval'),
    prop: 'startDate',
  },
  {
    name: tTable('progress'),
    prop: 'sentPhones',
  },
  {
    name: tTable('name'),
    prop: 'name',
    wrap: true,
  },
  {
    name: tTable('originator'),
    prop: 'originator',
    wrap: true,
  },
  {
    name: tTable('message'),
    prop: 'message',
    wrap: true,
  },
  {
    name: tTable('status'),
    prop: 'status',
  },
]

const emit = defineEmits(['update:modelSelection'])

const selectedElements = computed({
  get: () => props.modelSelection,
  set: (value) => {
    emit('update:modelSelection', value)
  },
})
</script>
